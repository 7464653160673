<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-description footer-accent">
        <a
          style="display:block; margin-bottom: 41px"
          href="/"
        >
          <img
            v-if="$i18n.local === 'uk'" 
            src="@/assets/images/logo-white.svg"
            alt=""
          >
          <img
            v-else
            src="@/assets/images/logo-white-eng.svg"
            alt=""
          >
        </a>
        <p>
          {{ $t(`In accordance with the main objectives ITCS participates in ensuring formation of personnel resources
          from specialists in maritime transport in line with demands of STCW Convention, ratified by Ukraine and national
          regulations in the field of training and certification of seafarers`) }}
        </p>
      </div>
      <div
        class="footer-nav"
        style="display: none"
      >
        <div class="footer-nav__title">
          {{ $t('About Us') }}
        </div>
        <ul>
          <li class="selected">
            <a href="/"><nobr>{{ $t('Home') }}</nobr></a>
          </li>
          <li>
            <a href="/pro-inspektsiyu/"><nobr>{{ $t('About inspection') }}</nobr></a>
          </li>
          <li>
            <a href="/dlya-moryakiv/"><nobr>{{ $t('For seafarers') }}</nobr></a>
          </li>
          <li>
            <a href="/dlya-gromady/"><nobr>{{ $t('For the public') }}</nobr></a>
          </li>
          <li>
            <a href="/dkk/"><nobr>{{ $t('SQC') }}</nobr></a>
          </li>
          <li>
            <a href="/verification"><nobr>{{ $t('Verification') }}</nobr></a>
          </li>
          <li>
            <a href="/news/"><nobr>{{ $t('News') }}</nobr></a>
          </li>
          <li>
            <a href="/contacts/"><nobr>{{ $t('Contacts') }}</nobr></a>
          </li>
        </ul>
      </div>
      <div class="footer-nav">
        <div class="footer-nav__title">
          {{ $t('Read more') }}<br><br>
        </div>
        <ul>
          <li>
            <a
              href="/pro-inspektsiyu/kerivnitstvo"
              target="_blank"
            >{{ $t('Leadership') }}</a>
          </li>
          <li>
            <a
              href="/dkk/perelik-dokumentiv-na-dkk"
              target="_blank"
            >{{ $t('List of documents for SQC') }}</a>
          </li>
          <li>
            <a
              href="/dlya-moryakiv/rekviziti-dlya-splati-poslug-inspektsii"
              target="_blank"
            >{{ $t('Details for payment of inspectorate services') }}</a>
          </li>
          <li>
            <a
              href="/dlya-moryakiv/skhvaleni-tsentri-pidgotovki"
              target="_blank"
            >{{ $t('Approved training centers') }}</a>
          </li>
          <li>
            <a
              href="/pro-inspektsiyu/shlyakh-diplomuvannya-v-ukraini"
              target="_blank"
            >{{ $t('The path of certification') }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-nav">
        <div class="footer-nav__title">
          {{ $t('Inspection hotline') }}
        </div>
        <ul>
          <li>
            <a
              href="mailto:itcs@itcs.gov.ua"
              target="_blank"
            >itcs@itcs.gov.ua</a>
          </li>
        </ul>
        <ul class="footer-workingTime">
          <li>{{ $t('Schedule') }}</li>
          <li><b>{{ $t('Mon-Thu') }}: 8.30 - 17.30</b></li>
          <li>{{ $t('Break') }}: 13.00 - 13.45 </li>
          <li><b>Fri: 8.30 - 16.15</b></li>
          <li>{{ $t('Break') }}:&nbsp;13.00 - 13.45</li>
          <li><b> {{ $t('Sat, Sun and holidays - output') }} </b></li>
        </ul>
      </div>
      <div class="footer-nav">
        <div class="footer-nav__title">
          {{ $t('Support') }}
        </div>
        <ul>
          <li>
            <a
              href="tel:380443334253"
              target="_blank"
            >+38 044 333 4253</a>
          </li>
          <li>
            <a
              href="mailto:info@itcsua.com"
              target="_blank"
            >info@itcsua.com</a>
          </li>
          <p style="color: #fff">
            {{ $t('Address for correspondence: 04116 Ukraine,') }}<br> {{ $t('m. Kyiv, street Proviantska, 3') }}
          </p>
          <p style="color: #fff">
            {{ $t('Actual address:') }}<br> {{ $t('Kyiv, st. Proviantska, 3') }}
          </p>
          <br>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
