export default {
  state: () => ({
    bviTheme: null,
    isLoad: true
  }),
  mutations: {
    SET_DATA_STATE_APP (state, data) {
      Object.entries(data).forEach(([key, value]) => state[key] = value)
    }
  },
  actions: {

  },
  getters: {

  }
}
