var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-logo"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.$i18n.locale === 'uk')?_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"logo.svg","width":"96","height":"72"}}):_c('img',{attrs:{"src":require("@/assets/images/logo-eng.svg"),"alt":"logo.svg","width":"96","height":"72"}})])],1),_c('div',{staticClass:"header-nav"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'main' },"active-class":_vm.$route.name === 'main' ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('Home'))+" ")])],1),_c('li',{staticClass:"submenu"},[_c('router-link',{staticClass:"submenu-link",attrs:{"to":{ name: 'about-inspection'},"active-class":[
              'about-inspection',
              'about-inspection-leadership',
              'about-inspection-missions',
              'about-inspection-diploma-path-in-ukraine',
              'about-inspection-regulatory-base'].includes(_vm.$route.name) ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('About inspection'))+" ")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-inspection' }}},[_vm._v(" "+_vm._s(_vm.$t('About inspection'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-inspection-leadership' }}},[_vm._v(" "+_vm._s(_vm.$t('Leadership'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-inspection-missions' }}},[_vm._v(" "+_vm._s(_vm.$t('Mission'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-inspection-diploma-path-in-ukraine' }}},[_vm._v(" "+_vm._s(_vm.$t('The path of certification'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-inspection-regulatory-base' }}},[_vm._v(" "+_vm._s(_vm.$t('Normative documents'))+" ")])],1)])],1),_c('li',{staticClass:"submenu"},[_c('router-link',{staticClass:"submenu-link",attrs:{"to":{ name: 'for-sailor-information' },"active-class":[
              'for-sailor-information',
              'for-sailor-services',
              'for-sailor-compleate-branch-offices',
              'for-sailor-compleate-medical-institution',
              'for-sailor-compleate-institution',
              'for-sailor-payment-details-services'
            ].includes(_vm.$route.name) ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('For seafarers'))+" ")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-information' }}},[_vm._v(" "+_vm._s(_vm.$t('Information'))+" ")])],1),_c('li',[_c('a',{attrs:{"href":require('@/assets/pdf/algoritm-doc.pdf').default,"download":"algoritm-doc.pdf"}},[_vm._v(_vm._s(_vm.$t("Algorithm for obtaining seafarers' working documents")))])]),_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-services' }}},[_vm._v(" "+_vm._s(_vm.$t('Services'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-compleate-branch-offices' }}},[_vm._v(" "+_vm._s(_vm.$t('Approved training centers'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-compleate-medical-institution' }}},[_vm._v(" "+_vm._s(_vm.$t('Approved health care facilities'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-payment-details-services' }}},[_vm._v(" "+_vm._s(_vm.$t('Details for payment of inspectorate services'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-sailor-compleate-institution' }}},[_vm._v(" "+_vm._s(_vm.$t("Approved Institutions of ATC"))+" ")])],1)])],1),_c('li',{staticClass:"submenu"},[_c('router-link',{staticClass:"submenu-link",attrs:{"to":{ name: 'for-the-public' },"active-class":_vm.$route.name === 'for-the-public' ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('For the public'))+" ")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'for-the-public' }}},[_vm._v(" "+_vm._s(_vm.$t('Citizens appointment schedule'))+" ")])],1)])],1),_c('li',{staticClass:"submenu"},[_c('router-link',{staticClass:"submenu-link",attrs:{"to":{ name: 'sqc-cabinet' },"active-class":[
              'sqc-cabinet',
              'sqc-list-of-documents',
              'sqc-address-branch-offices',
              'sqc-ces-statistycs',
              'sqc-expirience-statistycs',
              'sqc-shedule'
            ].includes(_vm.$route.name) ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('SQC'))+" ")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-cabinet' }}},[_vm._v(" "+_vm._s(_vm.$t("SQC member's cabinet"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-list-of-documents' }}},[_vm._v(" "+_vm._s(_vm.$t('List of documents for SQC'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-address-branch-offices' }}},[_vm._v(" "+_vm._s(_vm.$t('SQC address'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-ces-statistycs' }}},[_vm._v(" "+_vm._s(_vm.$t('CES 6.0 statistics'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-expirience-statistycs' }}},[_vm._v(" "+_vm._s(_vm.$t('Statistics of getting endorsement by experience confirmation'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'sqc-shedule' }}},[_vm._v(" "+_vm._s(_vm.$t('SQC work schedule'))+" ")])],1)])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'verification' },"active-class":_vm.$route.name === 'verification' ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('Verification'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'news', query: { page: 1 } },"active-class":[
              'news',
              'news-details'
            ].includes(_vm.$route.name) ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('News'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'contacts' },"active-class":_vm.$route.name === 'contacts' ? 'item-selected': ''}},[_vm._v(" "+_vm._s(_vm.$t('Contacts'))+" ")])],1)])]),_c('div',{staticClass:"header-action"},[_vm._m(0),_c('div',{staticClass:"header-lang"},[_c('div',{staticClass:"header-lang__active"},[(_vm.$i18n.locale === 'uk')?_c('div',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"icon-flagUA"}),_vm._v(" Укр "),_c('i',{staticClass:"icon-path"})]):_c('div',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"icon-flagEN"}),_vm._v(" Eng "),_c('i',{staticClass:"icon-path"})])]),_c('ul',[_c('li',{on:{"click":function($event){return _vm.changedLang('uk')}}},[_vm._m(1)]),_c('li',{on:{"click":function($event){return _vm.changedLang('en')}}},[_vm._m(2)])])]),_c('div',{staticClass:"header-burger",on:{"click":_vm.handleClickOpenMenu}},[_c('span'),_c('span'),_c('span')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bvi-open bvi-hide bvi-setup cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/images/glasses.svg"),"alt":"glasses"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"icon-flagUA"}),_vm._v(" Українська")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"icon-flagEN"}),_vm._v(" English")])
}]

export { render, staticRenderFns }