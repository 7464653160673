import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'

import api from "./plugins/api"

import i18n from '@/plugins/vue-i18n'
import VueSwal from 'vue-swal'

Vue.use(VueSwal)


import '@/assets/sass/index.sass'
import '@/assets/css/app.min.css'
import '@/assets/css/404.page.css'
// import '@/assets/css/about-us.page.css'
// import '@/assets/css/sailor.page.css'
// import '@/assets/css/verification.page.css'
// import '@/assets/css/sqc.page.css'

Vue.config.productionTip = false

Vue.prototype.$api = api

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
