<template lang="pug">
  v-progress-circular(
    :size="50"
    color="success"
    indeterminate).loader
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style scoped lang="sass">
  .loader
    color: #1876d2
    margin-top: 50vh
    margin-left: 50vw
</style>
