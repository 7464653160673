/* eslint-disable vue/max-len */
export default {
  Home: 'Home',
  'About inspection': 'About inspection',
  Leadership: 'Leadership',
  Mission: 'Mission',
  'The path of certification': 'The path of certification',
  'Normative documents': 'Normative documents',
  'For seafarers': 'For seafarers',
  Information: 'Information',
  "Algorithm for obtaining seafarers' working documents": "Algorithm for obtaining seafarers' working documents",
  Services: 'Services',
  'Approved training centers': 'Approved training centers',
  'Approved health care facilities': 'Approved health care facilities',
  'Details for payment of inspectorate services': 'Details for payment of inspectorate services',
  'Approved Institutions of ATC': 'Approved Institutions of ATC',
  'For the public': 'For the public',
  'Citizens appointment schedule': 'Citizens appointment schedule',
  SQC: 'SQC',
  "SQC member's cabinet": "SQC member's cabinet",
  'List of documents for SQC': 'List of documents for SQC',
  'SQC address': 'SQC address',
  'CES 6.0 statistics': 'CES 6.0 statistics',
  'Statistics of getting endorsement by experience confirmation':
    'Statistics of getting endorsement by experience confirmation',
  'SQC work schedule': 'SQC work schedule',
  Verification: 'Verification',
  News: 'News',
  Contacts: 'Contacts',
  'In accordance with the main objectives ITCS participates in ensuring formation of personnel resources from specialists in maritime transport in line with demands of STCW Convention, ratified by Ukraine and national regulations in the field of training and certification of seafarers':
    'In accordance with the main objectives ITCS participates in ensuring formation of personnel resources from specialists in maritime transport in line with demands of STCW Convention, ratified by Ukraine and national regulations in the field of training and certification of seafarers.',
  'About Us': 'About Us',
  'Inspection hotline': 'Inspection hotline',
  Schedule: 'Schedule',
  'Mon-Thu': 'Mon-Thu',
  Break: 'Break',
  Fri: 'Fri',
  'Sat, Sun and holidays - output': 'Sat, Sun and holidays - output',
  Support: 'Support',
  'Address for correspondence: 04116 Ukraine,': 'Address for correspondence: 04116 Ukraine,',
  'm. Kyiv, street Proviantska, 3': 'm. Kyiv, street Proviantska, 3',
  'Actual address:': 'Actual address:',
  'Kyiv, st. Proviantska, 3': 'Kyiv, st. Proviantska, 3',
  'Read more': 'Read more',
  'THE INSPECTORATE FOR TRAINING AND CERTIFICATION OF SEAFARERS':
    'THE INSPECTORATE FOR TRAINING AND CERTIFICATION OF SEAFARERS',
  'The Inspectorate for training and sertification of seafarers has introduced electronic filing of seafarers documents in order to confirm their qualification with the State Qualification Commission using software':
    'The Inspectorate for training and sertification of seafarers has introduced electronic filing of seafarers documents in order to confirm their qualification with the State Qualification Commission using software',
  'Prohibition of AU on the exercise of powers': 'Prohibition of AU on the exercise of powers',
  'Grounds for stopping the liquidation of the Inspectorate':
    'Grounds for stopping the liquidation of the Inspectorate',
  'The basis of the work of the Register and CM': 'The basis of the work of the Register and CM',
  'DOCUMENTS OF SEAMEN WITHOUT BRIBES': 'DOCUMENTS OF SEAMEN WITHOUT BRIBES',
  'Meet the State Mobile Application': 'Meet the State Mobile Application.',
  'EFFICIENT PERSONAL ACCOUNT': 'EFFICIENT PERSONAL ACCOUNT',
  'The Electronic Seafarer’s Cabinet is a modern personal account designed to provide assistance to seafarers in the document processing. Take advantage of self-service:':
    'The Electronic Seafarer’s Cabinet is a modern personal account designed to provide assistance to seafarers in the document processing. Take advantage of self-service:',
  'Use digital document flow;': 'Use digital document flow;',
  'View information about all available documents;': 'View information about all available documents;',
  'Submit applications for government services in just a few clicks;':
    'Submit applications for government services in just a few clicks;',
  'Manage your work documents remotely': 'Manage your work documents remotely.',
  'No more standing in queues, now all services are available online':
    'No more standing in queues, now all services are available online.',
  'Sign in': 'Sign in',

  'OFFICIAL MOBILE APPLICATION Easy. Imperceptibly. Humanly':
    'OFFICIAL MOBILE APPLICATION Easy. Imperceptibly. Humanly.',
  'Another step towards the implementation of the program "State in smartphones": Mobile Cabinet sailor. Having established the Seaman\'s Cabinet, you will be able to:':
    'Another step towards the implementation of the program "State in smartphones": Mobile Cabinet sailor. Having established the Seaman\'s Cabinet, you will be able to:',
  'use digital documents;': 'use digital documents;',
  'apply online for government': 'services in few clicks;',
  'upload copies of digital documents': 'upload copies of digital documents.',
  'Paper and plastic original documents now can be left at home':
    'Paper and plastic original documents now can be left at home',

  'RE-CONFIRMATION OF THE DIPLOMA WITHOUT PASSING EXAMS': 'RE-CONFIRMATION OF THE DIPLOMA WITHOUT PASSING EXAMS',
  'Re-confirmation of the diploma without passing the exam in the state qualification commission, in the presence of confirmed length of service on ships with the performance of functions corresponding to the diploma being confirmed. As well as compliance with other requirements set out in Article 51 of the Code of Merchant Shipping of Ukraine':
    'Re-confirmation of the diploma without passing the exam in the state qualification commission, in the presence of confirmed length of service on ships with the performance of functions corresponding to the diploma being confirmed. As well as compliance with other requirements set out in Article 51 of the Code of Merchant Shipping of Ukraine.',
  'View statistics': 'View statistics',

  'QUALIFICATION TESTING AND CONFIRMATION IN CREW EVALUATION SYSTEM 6.0 (CES)':
    'QUALIFICATION TESTING AND CONFIRMATION IN CREW EVALUATION SYSTEM 6.0 (CES)',
  'Testing takes place in special automated workplaces - to avoid external interference in the process and the lack of access of graduates / sailors to information resources on the Internet':
    'Testing takes place in special automated workplaces - to avoid external interference in the process and the lack of access of graduates / sailors to information resources on the Internet',

  "ALGORITHM FOR OBTAINING SEAFARERS' WORKING DOCUMENTS": "ALGORITHM FOR OBTAINING SEAFARERS' WORKING DOCUMENTS",
  'Step-by-step instructions on how to submit documents to the Unified State Register of Seafarers through the mobile application or SE "Morrichservice" for passing the exam and further obtaining working documents for experience':
    'Step-by-step instructions on how to submit documents to the Unified State Register of Seafarers through the mobile application or SE "Morrichservice" for passing the exam and further obtaining working documents for experience',
  'Learn more': 'Learn more',

  'Over the past years, from the day of its establishment, the Inspectorate for Training and Certification of Seafarers (ITCS) has performed a large amount of work to improve the national system of training and certification of seafarers, and to develop regulatory framework complying with the international requirements':
    'Over the past years, from the day of its establishment, the Inspectorate for Training and Certification of Seafarers (ITCS) has performed a large amount of work to improve the national system of training and certification of seafarers, and to develop regulatory framework complying with the international requirements',

  'REGISTRATION IN THE ETI SYSTEM': 'REGISTRATION IN THE ETI SYSTEM',
  'A system for educational institutions that allows you to use the following features:':
    'A system for educational institutions that allows you to use the following features:',
  'issuing online certificates;': 'issuing online certificates;',
  'electronic signature signing;': 'electronic signature signing;',
  'electronic verification of documents': 'electronic verification of documents.',
  'Register your ETI': 'Register your ETI',

  'CREWING COMPANY REGISTRATION': 'CREWING COMPANY REGISTRATION',
  'A system for crewing companies that allows go entirely to online work':
    'A system for crewing companies that allows go entirely to online work.',
  'Register crewing': 'Register crewing',

  'Last news': 'Last news',
  'Seafarer Cabinet now available in your browser': "Seafarer's Cabinet now available in your browser",
  'Dear sailors!': 'Dear sailors!',
  'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority':
    'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority.',

  'THE MAIN TASKS OF THE ITCS ARE': 'THE MAIN TASKS OF THE ITCS ARE',
  'Analysis of national and international experience in the field of training, assessment of competence and certification of crew members on seagoing merchant vessels':
    'Analysis of national and international experience in the field of training, assessment of competence and certification of crew members on seagoing merchant vessels',
  'Representation and protection, on behalf of the Ministry of Infrastructure of Ukraine, of Ukrainian interests in the field of training, assessment of competence and certification of seafarers at IMO':
    'Representation and protection, on behalf of the Ministry of Infrastructure of Ukraine, of Ukrainian interests in the field of training, assessment of competence and certification of seafarers at IMO',
  'Proficiency endorsement for seafarers at permanent state qualification commissions (SQC) and also endorsements for seafarers’ documents that attest their qualification':
    'Proficiency endorsement for seafarers at permanent state qualification commissions (SQC) and also endorsements for seafarers’ documents that attest their qualification',

  'State Register of Seafarers Documents of Ukraine': 'State Register of Seafarers Documents of Ukraine',
  'Computer testing of seafarers': 'Computer testing of seafarers',
  "Seaman's seagoing service record book": "Seaman's seagoing service record book",
  'Certification of employees of the subjects of dangerous goods transportation':
    'Certification of employees of the subjects of dangerous goods transportation',

  'Main information': 'Main information',
  'Normative framework': 'Normative framework',
  'Public procurement': 'Public procurement',
  'Anti-corruption activity': 'Anti-corruption activity',

  'BASIC RESOURCES': 'BASIC RESOURCES',
  'Verkhovna Rada of Ukraine': 'Verkhovna Rada of Ukraine',
  'Ministry of Infrastructure of Ukraine': 'Ministry of Infrastructure of Ukraine',
  'National Agency for the Prevention of Corruption': 'National Agency for the Prevention of Corruption',
  'President of Ukraine': 'President of Ukraine',
  'State Service of Maritime and River Transport of Ukraine':
    'State Service of Maritime and River Transport of Ukraine',
  'Government portal': 'Government portal',

  "The inspectorate for the training and certification of seafarers implemented electronic submission of seafarers' documents for the purpose of confirming qualifications at the State Qualification Commission using software":
    "The inspectorate for the training and certification of seafarers implemented electronic submission of seafarers' documents for the purpose of confirming qualifications at the State Qualification Commission using software.",

  'About Inspectorate for the Training and Certification of Seafarers':
    'About Inspectorate for the Training and Certification of Seafarers',
  'Over the past years, since its inception, the Inspectorate for the Training and Certification of Seafarers (ITCS) has done a lot of work to improve the national system of training and certification of seafarers, to create a regulatory framework consistent with international requirements':
    'Over the past years, since its inception, the Inspectorate for the Training and Certification of Seafarers (ITCS) has done a lot of work to improve the national system of training and certification of seafarers, to create a regulatory framework consistent with international requirements.',

  'Leadership ITCS': 'Leadership ITCS',
  'Director of the State Register of Seafarers’ Documents of Ukraine, Deputy Chief of Inspectorate, Regional Branch Director':
    'Director of the State Register of Seafarers’ Documents of Ukraine, Deputy Chief of Inspectorate, Regional Branch Director',

  'Inspection mission for seafarers training and certification':
    'Inspection mission for seafarers training and certification',
  'The mission of the Inspectorate for the Preparation and Certification of Seafarers is to accomplish the tasks assigned to represent and protect the interests of Ukraine in the issues of training, assessment and certification of seafarers':
    'The mission of the Inspectorate for the Preparation and Certification of Seafarers is to accomplish the tasks assigned to represent and protect the interests of Ukraine in the issues of training, assessment and certification of seafarers',
  'The path of diploma in Ukraine': 'The path of diploma in Ukraine',
  'Ukraine became a Member State of the International Maritime Organization (IMO) and a country that signed the main international regulations on safety of maritime navigation adopted within the framework of the IMO':
    'Ukraine became a Member State of the International Maritime Organization (IMO) and a country that signed the main international regulations on safety of maritime navigation adopted within the framework of the IMO',

  'Activities Inspectorate for the Training and Certification of Seafarers':
    'Activities Inspectorate for the Training and Certification of Seafarers',
  'Legislation acts, public procurement, anti-corruption activities Inspectorate for the Training and Certification of Seafarers (ITCS)':
    'Legislation acts, public procurement, anti-corruption activities Inspectorate for the Training and Certification of Seafarers (ITCS)',

  'Composition of state qualification commissions': 'Composition of state qualification commissions',
  'Inform us about all known the facts of bribery, corruption and other unlawful actions of the employees of the Inspection':
    'Inform us about all known the facts of bribery, corruption and other unlawful actions of the employees of the Inspection',

  'Unified State Register of Documents of Seafarers of Ukraine':
    'Unified State Register of Documents of Seafarers of Ukraine',
  'The state register is constantly developing and qualitatively improving. In 2002, an automated system of a single state register of seafarers of Ukraine was established and is now functioning':
    'The state register is constantly developing and qualitatively improving. In 2002, an automated system of a single state register of seafarers of Ukraine was established and is now functioning.',

  'Approved training centers': 'Approved training centers',
  'The list of approved education and training institutions, which are registered as economic entities in Ukraine and have a quality management system in the field of seafarers training in accordance with section A-I / 8 of the Annex to the Convention on STCWS':
    'The list of approved education and training institutions, which are registered as economic entities in Ukraine and have a quality management system in the field of seafarers training in accordance with section A-I / 8 of the Annex to the Convention on STCWS',

  'Approved health care facilities': 'Approved health care facilities',
  'On approval of the List of health care institutions, chairmen of commissions and deputy chairmen commissions entitled to conduct medical examinations of seafarers':
    'On approval of the List of health care institutions, chairmen of commissions and deputy chairmen commissions entitled to conduct medical examinations of seafarers',

  'Bank details and payment methods for services - ITCS': 'Bank details and payment methods for services - ITCS',
  "Details of payment for the services of the Inspectorate for confirmation of qualification and registration of the seafarer's record book":
    "Details of payment for the services of the Inspectorate for confirmation of qualification and registration of the seafarer's record book",

  'Approved Institutions of ATC': 'Approved Institutions of ATC',
  'Approved Institutions of Advanced Training Courses': 'Approved Institutions of Advanced Training Courses',

  Schedule: 'Schedule',
  'The Inspectorate for the Training and Certification of Seafarers has postponed citizens appointment for the quarantine period which established by the Cabinet of Ministers of Ukraine in order to prevent the spread of acute respiratory disease COVID-19 caused by the SARS-CoV-2 coronavirus in Ukraine':
    'The Inspectorate for the Training and Certification of Seafarers has postponed citizens appointment for the quarantine period which established by the Cabinet of Ministers of Ukraine in order to prevent the spread of acute respiratory disease COVID-19 caused by the SARS-CoV-2 coronavirus in Ukraine.',

  "SQC member's office": "SQC member's office",

  'List of documents on the SQC': 'List of documents on the SQC',
  'List of all necessary documents for consideration by the State Qualification Commission (SCC) for pilots, radio specialists, mechanics and engineers, specialists of the port, service and support and technical fleet':
    'List of all necessary documents for consideration by the State Qualification Commission (SCC) for pilots, radio specialists, mechanics and engineers, specialists of the port, service and support and technical fleet',

  'SQC address': 'SQC address',

  'CES 6.0': 'CES 6.0',

  'Proof of experience': 'Proof of experience',
  "Statistics 'Proof of experience'": "Statistics 'Proof of experience'",

  'Work schedule SQC': 'Work schedule SQC',

  'Contacts and phone number of hot information Inspections':
    'Contacts and phone number of hot information Inspections',
  'Contacts and telephone number of the hotline of regional branches of Inspections on training and certification of seafarers of Ukraine in Odessa, Kiev, Kherson, Izmail, Kharkiv':
    'Contacts and telephone number of the hotline of regional branches of Inspections on training and certification of seafarers of Ukraine in Odessa, Kiev, Kherson, Izmail, Kharkiv.',

  Tasks: 'Tasks',
  'About the register': 'About the register',
  'About SQC': 'About SQC',
  'Registration of institutions': 'Registration of institutions',
  "Over the past years, since its inception, the Inspectorate for the Training and Certification of Seafarers (ITCS) has done a lot of work to improve the national system of training and certification of seafarers, to create a regulatory framework consistent with international requirements. The achieved results allow Ukrainian sailors to work on ships under the flags of almost all countries of the world, in all basins of the World Ocean, contribute to maintaining the high authority of our maritime personnel, increase the positive image of Ukraine. A single State Register of Seafarers' Documents has been created, which provides reliable control over documents issued to seafarers in our country to confirm their professional competence. With the participation of ITCS, the Maritime Administration has signed agreements on the recognition of diplomas of our seafarers with 53 countries. ITCS staff develops and publishes numerous informational, training and reference materials on training, competence assessment and certification of seafarers, prepares reports in the IMO and much more. The large and multifaceted activity of the ITCS is bearing fruit: in recent years the level of professionalism of Ukrainian seafarers has significantly increased, their demand in the world maritime labor market has increased":
    "Over the past years, since its inception, the Inspectorate for the Training and Certification of Seafarers (ITCS) has done a lot of work to improve the national system of training and certification of seafarers, to create a regulatory framework consistent with international requirements. The achieved results allow Ukrainian sailors to work on ships under the flags of almost all countries of the world, in all basins of the World Ocean, contribute to maintaining the high authority of our maritime personnel, increase the positive image of Ukraine. A single State Register of Seafarers' Documents has been created, which provides reliable control over documents issued to seafarers in our country to confirm their professional competence. With the participation of ITCS, the Maritime Administration has signed agreements on the recognition of diplomas of our seafarers with 53 countries. ITCS staff develops and publishes numerous informational, training and reference materials on training, competence assessment and certification of seafarers, prepares reports in the IMO and much more. The large and multifaceted activity of the ITCS is bearing fruit: in recent years the level of professionalism of Ukrainian seafarers has significantly increased, their demand in the world maritime labor market has increased.",
  'Main tasks of ITCS are:': 'Main tasks of ITCS are:',
  'analysis of international and national experience in the field of training, assessment of competence and certification of crew members of sea-going merchant vessels;':
    'analysis of international and national experience in the field of training, assessment of competence and certification of crew members of sea-going merchant vessels;',
  "confirmation of seafarers' qualifications in permanent state qualification commissions (SQC), as well as confirmation of seafarers' documents certifying their qualifications;":
    "confirmation of seafarers' qualifications in permanent state qualification commissions (SQC), as well as confirmation of seafarers' documents certifying their qualifications;",
  "representation and protection on behalf of the Ministry of Infrastructure in the IMO of Ukraine's interests in training, assessment of competence and certification of seafarers":
    "representation and protection on behalf of the Ministry of Infrastructure in the IMO of Ukraine's interests in training, assessment of competence and certification of seafarers.",

  'ITCS in accordance with its tasks:': 'ITCS in accordance with its tasks:',
  'creates permanent SQCs to confirm the qualifications of seafarers;':
    'creates permanent SQCs to confirm the qualifications of seafarers;',
  'maintains a register of all documents issued on the territory of Ukraine, the validity of which has expired or been extended, or which have been suspended, or which have been annulled or declared lost or destroyed, as well as maintains a register of preferential permits issued;':
    'maintains a register of all documents issued on the territory of Ukraine, the validity of which has expired or been extended, or which have been suspended, or which have been annulled or declared lost or destroyed, as well as maintains a register of preferential permits issued;',
  'confirms the validity of documents;': 'confirms the validity of documents;',
  'on behalf of the Ministry of Infrastructure considers draft regulations, standards and other documents on training, assessment of competence and certification of seafarers, provides its comments and suggestions to them;':
    'on behalf of the Ministry of Infrastructure considers draft regulations, standards and other documents on training, assessment of competence and certification of seafarers, provides its comments and suggestions to them;',
  'prepares and publishes analytical, reference, informational, educational, methodical, popular science and other materials on training, assessment of competence and certification of seafarers;':
    'prepares and publishes analytical, reference, informational, educational, methodical, popular science and other materials on training, assessment of competence and certification of seafarers;',
  'on behalf of the Ministry of Infrastructure organizes research and scientific-methodical work on improving the national system of training, assessment of competence and certification of seafarers; participates in their implementation;':
    'on behalf of the Ministry of Infrastructure organizes research and scientific-methodical work on improving the national system of training, assessment of competence and certification of seafarers; participates in their implementation;',
  'on behalf of the Ministry of Infrastructure represents and protects in IMO the interests of Ukraine on training, assessment of competence and certification of seafarers, analyzes and coordinates proposals of ministries, other central executive bodies on consideration of these issues in IMO and submits generalized proposals to the Ministry of Infrastructure, prepares reports and other information in the IMO;':
    'on behalf of the Ministry of Infrastructure represents and protects in IMO the interests of Ukraine on training, assessment of competence and certification of seafarers, analyzes and coordinates proposals of ministries, other central executive bodies on consideration of these issues in IMO and submits generalized proposals to the Ministry of Infrastructure, prepares reports and other information in the IMO;',
  'performs other functions to perform the tasks assigned to the IPDM in accordance with current legislation of Ukraine':
    'performs other functions to perform the tasks assigned to the IPDM in accordance with current legislation of Ukraine.',

  'In carrying out its main tasks, ITCS provides services in the field of shipping safety, including:':
    'In carrying out its main tasks, ITCS provides services in the field of shipping safety, including:',
  "examination of knowledge and confirmation of seafarers' qualifications in permanent state qualification commissions, as well as confirmation of diplomas, qualification certificates and other documents of seafarers specified by the legislation of Ukraine;":
    "examination of knowledge and confirmation of seafarers' qualifications in permanent state qualification commissions, as well as confirmation of diplomas, qualification certificates and other documents of seafarers specified by the legislation of Ukraine;",
  'testing the knowledge of employees of dangerous goods by sea and river transport;':
    'testing the knowledge of employees of dangerous goods by sea and river transport;',
  'issuance of certificates certifying the recognition of diplomas in accordance with the requirements of Rule 1/10 of the STCW Convention;':
    'issuance of certificates certifying the recognition of diplomas in accordance with the requirements of Rule 1/10 of the STCW Convention;',
  'maintaining a register of all documents of seafarers issued on the territory of Ukraine and confirmation of their validity':
    'maintaining a register of all documents of seafarers issued on the territory of Ukraine and confirmation of their validity.',
  'The process of providing these ITCS services is regulated within the ITCS quality management system. The quality management system of ITCS is certified in the prescribed manner by the certification body - the Register of Shipping of Ukraine. Certificate for quality management system № UA 2.036.08150-15, issued on 11/18/2013 and valid until 11/17/2018. The procedure for validation of the Certificate for the quality management system provides for annual inspection of the system. During the validity of the certificate of conformity, the central office and all branches of ITCS were covered by the inspection audit':
    'The process of providing these ITCS services is regulated within the ITCS quality management system. The quality management system of ITCS is certified in the prescribed manner by the certification body - the Register of Shipping of Ukraine. Certificate for quality management system № UA 2.036.08150-15, issued on 11/18/2013 and valid until 11/17/2018. The procedure for validation of the Certificate for the quality management system provides for annual inspection of the system. During the validity of the certificate of conformity, the central office and all branches of ITCS were covered by the inspection audit.',
  'ITCS includes a single State Register of documents of seafarers and branches located in the cities of Izmail, Mariupol, Mykolaiv, Odessa':
    'ITCS includes a single State Register of documents of seafarers and branches located in the cities of Izmail, Mariupol, Mykolaiv, Odessa.',
  "The Unified State Register of Seafarers' Documents is a structural subdivision of the ITCS and operates in compliance with the requirements of paragraphs 14-16 of Regulation I / 2 of the STCW Convention and the ITCS Regulations":
    "The Unified State Register of Seafarers' Documents is a structural subdivision of the ITCS and operates in compliance with the requirements of paragraphs 14-16 of Regulation I / 2 of the STCW Convention and the ITCS Regulations.",

  "The responsibility for the functioning of the unified State Register of Seafarers' Documents rests with the ITCS. According to the Regulations on maintaining the unified State Register of Seafarers' Documents, its main tasks are:":
    "The responsibility for the functioning of the unified State Register of Seafarers' Documents rests with the ITCS. According to the Regulations on maintaining the unified State Register of Seafarers' Documents, its main tasks are:",
  'obtaining, accounting and accumulation of information on qualification documents of seafarers of Ukraine (certificates of competence and their confirmations);':
    'obtaining, accounting and accumulation of information on qualification documents of seafarers of Ukraine (certificates of competence and their confirmations);',
  'receiving and accounting information from maritime educational institutions regarding educational documents;':
    'receiving and accounting information from maritime educational institutions regarding educational documents;',
  'receiving, accounting and verification of information from the NTZ regarding certificates at the end of training courses in certain areas;':
    'receiving, accounting and verification of information from the NTZ regarding certificates at the end of training courses in certain areas;',
  'verification of the validity of documents of Ukrainian seafarers certifying their qualification and providing the results of such verification to interested parties;':
    'verification of the validity of documents of Ukrainian seafarers certifying their qualification and providing the results of such verification to interested parties;',
  'maintaining the necessary databases, technical protection of information in accordance with current requirements':
    'maintaining the necessary databases, technical protection of information in accordance with current requirements.',
  "The list of information entered into the unified State Register of Seafarers 'Documents in accordance with paragraph 12 of Section VI / 6 of the STCW Code, format, data transfer regulations, responsibility for verification of provided information are defined by the above-mentioned Regulations on the Unified State Register of Seafarers' Documents":
    "The list of information entered into the unified State Register of Seafarers 'Documents in accordance with paragraph 12 of Section VI / 6 of the STCW Code, format, data transfer regulations, responsibility for verification of provided information are defined by the above-mentioned Regulations on the Unified State Register of Seafarers' Documents.",
  "Today, the unified State Register of Seafarers 'Documents records more than 700,000 seafarers' documents issued by captains of seaports of Ukraine, more than 1.9 million NTC certificates (accounting has been carried out since 1998). The information is entered in Ukrainian and English":
    "Today, the unified State Register of Seafarers 'Documents records more than 700,000 seafarers' documents issued by captains of seaports of Ukraine, more than 1.9 million NTC certificates (accounting has been carried out since 1998). The information is entered in Ukrainian and English.",
  "The documents entered in the unified State Register of Seafarers' Documents are distributed depending on the status of the document at the moment, namely:":
    "The documents entered in the unified State Register of Seafarers' Documents are distributed depending on the status of the document at the moment, namely:",
  'valid;': 'valid;',
  'canceled;': 'canceled;',
  'expired;': 'expired;',
  'action suspended;': 'action suspended;',
  'lost (declared lost);': 'lost (declared lost);',
  destroyed: 'destroyed.',
  'The information is updated daily and updated during the inspection. The system provides backup of databases and takes effective measures to protect information and more':
    'The information is updated daily and updated during the inspection. The system provides backup of databases and takes effective measures to protect information and more.',

  "When assessing the competence and confirming the qualifications of seafarers, the validity of documents on education, qualifications and training provided by seafarers to the SQC must be checked in the State Register of Higher Education Documents, the only State Register of Seafarers' Documents. It is obligatory to establish that the provided medical certificates are valid and issued by the medical institutions approved by the Ministry of Health":
    "When assessing the competence and confirming the qualifications of seafarers, the validity of documents on education, qualifications and training provided by seafarers to the SQC must be checked in the State Register of Higher Education Documents, the only State Register of Seafarers' Documents. It is obligatory to establish that the provided medical certificates are valid and issued by the medical institutions approved by the Ministry of Health.",
  'The SQC confirms the qualification of seafarers in the amount of approved ITCS programs in the areas using modern methods of assessment of knowledge, skills and abilities using computer tests, simulators, ship and laboratory equipment, oral and / or written interview':
    'The SQC confirms the qualification of seafarers in the amount of approved ITCS programs in the areas using modern methods of assessment of knowledge, skills and abilities using computer tests, simulators, ship and laboratory equipment, oral and / or written interview.',
  "From 01.07.2013, the SQC's competence assessment is carried out according to programs that fully cover the requirements of the Manila Amendments to the STCW Convention. The candidate's competence is assessed by demonstrating to SQC members:":
    "From 01.07.2013, the SQC's competence assessment is carried out according to programs that fully cover the requirements of the Manila Amendments to the STCW Convention. The candidate's competence is assessed by demonstrating to SQC members:",
  "The candidate's competence is assessed by demonstrating to SQC members:":
    "The candidate's competence is assessed by demonstrating to SQC members:",
  'personal qualities required for the award of a rank or confirmation of qualification, entitling him to hold a position corresponding to that rank / qualification on a seagoing vessel. The assessment is carried out by interview;':
    'personal qualities required for the award of a rank or confirmation of qualification, entitling him to hold a position corresponding to that rank / qualification on a seagoing vessel. The assessment is carried out by interview;',
  'opportunities to apply knowledge and skills to perform their duties on board. It is determined by: preliminary computer testing; written examination and oral examination':
    'opportunities to apply knowledge and skills to perform their duties on board. It is determined by: preliminary computer testing; written examination and oral examination.',
  "SQC decisions are recorded in the minutes of the SQC meeting, which certifies the compliance of the seafarer's competence with the requirements of the STCW Convention and national requirements. On the basis of this protocol, seafarers have the right to obtain from the captain of a seaport the appropriate diploma or certificate of a specialist and confirmation to him to hold the relevant position on a seagoing vessel. The protocol itself does not give a seafarer the right to hold certain positions or perform certain functions. Other documents required to obtain qualification documents include educational documents, documentary evidence of mandatory training, documentary evidence of seagoing service on seagoing vessels, a certificate of fitness for work due to health status, identification documents":
    "SQC decisions are recorded in the minutes of the SQC meeting, which certifies the compliance of the seafarer's competence with the requirements of the STCW Convention and national requirements. On the basis of this protocol, seafarers have the right to obtain from the captain of a seaport the appropriate diploma or certificate of a specialist and confirmation to him to hold the relevant position on a seagoing vessel. The protocol itself does not give a seafarer the right to hold certain positions or perform certain functions. Other documents required to obtain qualification documents include educational documents, documentary evidence of mandatory training, documentary evidence of seagoing service on seagoing vessels, a certificate of fitness for work due to health status, identification documents.",
  'After successful confirmation of qualification in the SQC, a seafarer has the right within 12 months from the date of the SQC meeting to receive a diploma or a certificate of a specialist from the captain of the seaport on awarding the title / confirmation of qualification and confirmation of this document':
    'After successful confirmation of qualification in the SQC, a seafarer has the right within 12 months from the date of the SQC meeting to receive a diploma or a certificate of a specialist from the captain of the seaport on awarding the title / confirmation of qualification and confirmation of this document.',
  'Seafarers can pass the confirmation of qualification in the SQC in the cities: Kyiv, Odessa, Izmail, Mariupol and Kherson':
    'Seafarers can pass the confirmation of qualification in the SQC in the cities: Kyiv, Odessa, Izmail, Mariupol and Kherson',
  'REGISTRATION OF INSTITUTIONS': 'REGISTRATION OF INSTITUTIONS',
  'Medical institution registration': 'Medical institution registration',
  'Higher Education Institution': 'Higher Education Institution',

  'Chairman of the termination commission': 'Chairman of the termination commission',
  'Acting Head of the Training Inspection and certification of seafarers':
    'Acting Head of the Training Inspection\n and certification of seafarers\n',
  'Volodymyr Lavrynenko': 'Volodymyr Lavrynenko',

  'The mission of the Inspectorate for the Preparation and Certification of Seafarers is to accomplish the tasks assigned to represent and protect the interests of Ukraine in the issues of training, assessment and certification of seafarers; analysis of international and national experience in the field of training, assessment and competency and diploma of crew members; confirmation of the seafarers ‘qualification in the permanent state qualification commissions, as well as confirmation of the validity of the seafarers’ certificates certifying their qualification, as defined by the legislation':
    'The mission of the Inspectorate for the Preparation and Certification of Seafarers is to accomplish the tasks assigned to represent and protect the interests of Ukraine in the issues of training, assessment and certification of seafarers; analysis of international and national experience in the field of training, assessment and competency and diploma of crew members; confirmation of the seafarers ‘qualification in the permanent state qualification commissions, as well as confirmation of the validity of the seafarers’ certificates certifying their qualification, as defined by the legislation.',
  'In accordance with the tasks assigned:': 'In accordance with the tasks assigned:',
  'establishes permanent state qualification commissions to confirm the qualifications of seafarers;':
    'establishes permanent state qualification commissions to confirm the qualifications of seafarers;',
  'maintains a register of all documents issued in the territory of Ukraine which have expired or been extended or whose action has been suspended, or which have been annulled or declared lost or destroyed, and maintains a register of issued permits;':
    'maintains a register of all documents issued in the territory of Ukraine which have expired or been extended or whose action has been suspended, or which have been annulled or declared lost or destroyed, and maintains a register of issued permits;',
  'confirms the validity of the documents;': 'confirms the validity of the documents',
  'at the request of the Ministry of Infrastructure of Ukraine, reviews draft normative acts, standards, training programs and other documents on the preparation, assessment of competence and certification of seafarers, provides its comments and proposals to them;':
    'at the request of the Ministry of Infrastructure of Ukraine, reviews draft normative acts, standards, training programs and other documents on the preparation, assessment of competence and certification of seafarers, provides its comments and proposals to them;',
  'prepares and publishes analytical, reference, information, training, methodological, popular science and other materials on the training, assessment of competence and diploma of seafarers;':
    'prepares and publishes analytical, reference, information, training, methodological, popular science and other materials on the training, assessment of competence and diploma of seafarers;',
  'at the request of the Ministry of Infrastructure of Ukraine organizes research and methodological work on improving the national system of training, assessment of competence and diploma of seafarers. Participates in their implementation':
    'at the request of the Ministry of Infrastructure of Ukraine organizes research and methodological work on improving the national system of training, assessment of competence and diploma of seafarers. Participates in their implementation.',

  'Also:': 'Also:',
  'carries out on behalf of the Ministry of Infrastructure of Ukraine representation and protection in IMO of interests of Ukraine on questions of training, assessment of competence and diploma of seafarers, analyzes and agrees proposals of the ministries, other central executive bodies on consideration of these issues in IMO and submits generalized proposals to the Ministry of Infrastructure of Ukraine;':
    'carries out on behalf of the Ministry of Infrastructure of Ukraine representation and protection in IMO of interests of Ukraine on questions of training, assessment of competence and diploma of seafarers, analyzes and agrees proposals of the ministries, other central executive bodies on consideration of these issues in IMO and submits generalized proposals to the Ministry of Infrastructure of Ukraine;',
  'on behalf of the Ministry of Infrastructure prepares reports and other information in IMO, makes inquiries and answers about the validity of the documents of the seafarers certifying their qualification, as well as privileged permits to the authorized bodies and institutions of foreign countries;':
    'on behalf of the Ministry of Infrastructure prepares reports and other information in IMO, makes inquiries and answers about the validity of the documents of the seafarers certifying their qualification, as well as privileged permits to the authorized bodies and institutions of foreign countries;',
  'provides, on a contractual basis, to shipping companies and brokerage organizations for seafarers and other organizations, information on the validity of seafarers’ certificates attesting to their qualifications, and providing them with advisory services;':
    'provides, on a contractual basis, to shipping companies and brokerage organizations for seafarers and other organizations, information on the validity of seafarers’ certificates attesting to their qualifications, and providing them with advisory services;',
  'performs certification of instructors of training facilities for training seafarers;':
    'performs certification of instructors of training facilities for training seafarers;',
  'issues seaman’s record books, certificates attesting diplomas':
    'issues seaman’s record books, certificates attesting diplomas',
  'performs other functions to perform the tasks assigned to the Inspectorate in accordance with the legislation in force':
    'performs other functions to perform the tasks assigned to the Inspectorate in accordance with the legislation in force',
  'carries out in accordance with the legislation the knowledge of employees of the subjects of transportation of dangerous goods in the field of sea and river transport':
    'carries out in accordance with the legislation the knowledge of employees of the subjects of transportation of dangerous goods in the field of sea and river transport.',

  'The path of diploma in Ukraine': 'The path of diploma in Ukraine',
  'Ukraine has acceded to the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978, by means of adoption of the Law of Ukraine № 464/96-ВР of 1.11.1996':
    'Ukraine has acceded to the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978, by means of adoption of the Law of Ukraine № 464/96-ВР of 1.11.1996.',
  'Ukraine became a Member State of the International Maritime Organization (IMO) and a country that signed the main international regulations on safety of maritime navigation adopted within the framework of the IMO, including the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978, as amended (the STCW Convention)':
    'Ukraine became a Member State of the International Maritime Organization (IMO) and a country that signed the main international regulations on safety of maritime navigation adopted within the framework of the IMO, including the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978, as amended (the STCW Convention).',
  'Under the Order of the Ministry of Transport of Ukraine, in February 1998 in Odessa there was established the Seafarers’ Training and Certification Control Agency, headed by the professor of the Odessa State Maritime Academy – V. Golubiev, to fulfil the requirements of the STCW Convention':
    'Under the Order of the Ministry of Transport of Ukraine, in February 1998 in Odessa there was established the Seafarers’ Training and Certification Control Agency, headed by the professor of the Odessa State Maritime Academy – V. Golubiev, to fulfil the requirements of the STCW Convention.',
  'In December 1999, the Agency was reorganized into the state enterprise for attestation and registration of shipboard personnel that was headed by the Candidate of Technical Sciences V. Yerofieiev':
    'In December 1999, the Agency was reorganized into the state enterprise for attestation and registration of shipboard personnel that was headed by the Candidate of Technical Sciences V. Yerofieiev.',
  'Time has shown that the body for management and organization of activities of such a complex mechanism must be located in the capital, where there are all bodies of the executive branch of government':
    'Time has shown that the body for management and organization of activities of such a complex mechanism must be located in the capital, where there are all bodies of the executive branch of government.',
  'In pursuance of the Resolution of the Cabinet of Ministers of Ukraine № 83 of 31.01.2001, in July 2001 the Inspectorate for Training and Certification of Seafarers was established and located in Kyiv':
    'In pursuance of the Resolution of the Cabinet of Ministers of Ukraine № 83 of 31.01.2001, in July 2001 the Inspectorate for Training and Certification of Seafarers was established and located in Kyiv.',

  'The Inspectorate for Training and Certification of Seafarers was headed by:':
    'The Inspectorate for Training and Certification of Seafarers was headed by:',
  'Rear Admiral O. Shchyptsov (2001–2002, 2010–2014);': 'Rear Admiral O. Shchyptsov (2001–2002, 2010–2014);',
  'Captain I. Tykhonov (2002–2010);': 'Captain I. Tykhonov (2002–2010);',
  'Captain V. Pikulyk (2014-2016)': 'Captain V. Pikulyk (2014-2016).',
  'As a result of location in the capital, under the direct supervision of the Ministry of Infrastructure of Ukraine and under constant collaboration with subdivisions of the Ministry of Infrastructure and other ministries, the Inspectorate for Training and Certification of Seafarers has done a lot of work, during its existence, to improve the national system of training and certification':
    'As a result of location in the capital, under the direct supervision of the Ministry of Infrastructure of Ukraine and under constant collaboration with subdivisions of the Ministry of Infrastructure and other ministries, the Inspectorate for Training and Certification of Seafarers has done a lot of work, during its existence, to improve the national system of training and certification.',

  'Current organizational structure of maritime education, training, competency assessment and certification system, including the issue of medical certificates of seafarers':
    'Current organizational structure of maritime education, training, competency assessment and certification system, including the issue of medical certificates of seafarers',
  'The formation of state policy concerning the quality of education, training, certification and medical examinations of seafarers is related to three bodies of state authority: the Ministry of Infrastructure of Ukraine, the Ministry of Education and Science of Ukraine and the Ministry of Health of Ukraine':
    'The formation of state policy concerning the quality of education, training, certification and medical examinations of seafarers is related to three bodies of state authority: the Ministry of Infrastructure of Ukraine, the Ministry of Education and Science of Ukraine and the Ministry of Health of Ukraine.',
  'Functions on state and general management regarding training and certification of seafarers, specified by Articles 10 and 51 of the Merchant Shipping Code of Ukraine, have been assigned to the Ministry of Infrastructure of Ukraine':
    'Functions on state and general management regarding training and certification of seafarers, specified by Articles 10 and 51 of the Merchant Shipping Code of Ukraine, have been assigned to the Ministry of Infrastructure of Ukraine.',
  'The Ministry of Infrastructure of Ukraine (Ministry of Infrastructure) is a central executive body, whose activities are governed by the Cabinet of Ministers of Ukraine':
    'The Ministry of Infrastructure of Ukraine (Ministry of Infrastructure) is a central executive body, whose activities are governed by the Cabinet of Ministers of Ukraine.',
  'The Ministry of Infrastructure is the main body in the system of central executive authorities regarding development and implementation of state policy in the field of maritime and inland water transport, and according to assigned tasks, also:':
    'The Ministry of Infrastructure is the main body in the system of central executive authorities regarding development and implementation of state policy in the field of maritime and inland water transport, and according to assigned tasks, also:',
  'takes part in activities of international organizations, develops and concludes international agreements, and performs functions of the Maritime Administration of Ukraine;':
    'takes part in activities of international organizations, develops and concludes international agreements, and performs functions of the Maritime Administration of Ukraine;',
  'generalizes the practice of application of legislation on matters within the competence of the Ministry, develops proposals to improve the laws of Ukraine and acts of the President of Ukraine, the Cabinet of Ministers of Ukraine and submits them to the President of Ukraine and the Cabinet of Ministers of Ukraine in accordance with the established procedure':
    'generalizes the practice of application of legislation on matters within the competence of the Ministry, develops proposals to improve the laws of Ukraine and acts of the President of Ukraine, the Cabinet of Ministers of Ukraine and submits them to the President of Ukraine and the Cabinet of Ministers of Ukraine in accordance with the established procedure.',
  'Under the legislation, the Ministry of Infrastructure, in order to organize its activities, facilitates the fulfillment of international agreements of Ukraine, takes measures, within its authority, on the implementation of the provisions of international agreements, to which Ukraine is a Party, in the national legislation':
    'Under the legislation, the Ministry of Infrastructure, in order to organize its activities, facilitates the fulfillment of international agreements of Ukraine, takes measures, within its authority, on the implementation of the provisions of international agreements, to which Ukraine is a Party, in the national legislation.',

  'The Ministry of Education and Science of Ukraine (MESU) is liable for the compliance with the requirements of the STCW Convention in respect of seafarers’ education at higher and vocational maritime education institutions':
    'The Ministry of Education and Science of Ukraine (MESU) is liable for the compliance with the requirements of the STCW Convention in respect of seafarers’ education at higher and vocational maritime education institutions.',
  'The Ministry of Education and Science of Ukraine, pursuant to the Regulation on the Ministry, shapes and ensures the implementation of state policy, regulates, specifies prospects and priority development areas in the field of education and state supervision (control) of the activities of education institutions, regardless of their subordination and types of ownership; develops and approves standards for specific professions; provides regulatory and academic basis for functioning of education institutions; performs licensing and accreditation of higher education and post-graduate education institutions':
    'The Ministry of Education and Science of Ukraine, pursuant to the Regulation on the Ministry, shapes and ensures the implementation of state policy, regulates, specifies prospects and priority development areas in the field of education and state supervision (control) of the activities of education institutions, regardless of their subordination and types of ownership; develops and approves standards for specific professions; provides regulatory and academic basis for functioning of education institutions; performs licensing and accreditation of higher education and post-graduate education institutions.',
  'The MESU is liable for the development of state educational standards, for the curricula of education programmes, qualification level of tutors, as well as qualification level that can be obtained by students who study regardless of the level of a maritime education institution – an academy, university, college or vocational school':
    'The MESU is liable for the development of state educational standards, for the curricula of education programmes, qualification level of tutors, as well as qualification level that can be obtained by students who study regardless of the level of a maritime education institution – an academy, university, college or vocational school.',
  'The requirements of the STCW Convention, as amended by the 2010 Manila Amendments are implemented into the curricula of all maritime education institutions':
    'The requirements of the STCW Convention, as amended by the 2010 Manila Amendments are implemented into the curricula of all maritime education institutions.',
  'State educational standards set requirements for the content, scope and level of educational and professional training in Ukraine. They are the basis for the evaluation of education and qualification level of citizens regardless of forms to get education. State standards of education are developed separately regarding each education and qualification level and approved by the Cabinet of Ministers of Ukraine':
    'State educational standards set requirements for the content, scope and level of educational and professional training in Ukraine. They are the basis for the evaluation of education and qualification level of citizens regardless of forms to get education. State standards of education are developed separately regarding each education and qualification level and approved by the Cabinet of Ministers of Ukraine.',
  'Industry standards are developed for each education and qualification level of specialist training for each area and specialty, for each qualification':
    'Industry standards are developed for each education and qualification level of specialist training for each area and specialty, for each qualification.',
  'Industry standards are approved by the MESU': 'Industry standards are approved by the MESU.',

  'The Ministry of Health of Ukraine (MHU) provides development and implementation of state policy on health, development of state policy on medical service quality control':
    'The Ministry of Health of Ukraine (MHU) provides development and implementation of state policy on health, development of state policy on medical service quality control.',
  'The Ministry of Health of Ukraine pursuant to the Regulation on the Ministry develops and provides implementation of state policy on health, specifies medical fitness of a seafarer to serve on board ship. Article 52 of the Merchant Shipping Code of Ukraine stipulates that “Persons, who were qualified as fit according to their state of health, are allowed to work on a vessel. The conclusion on fitness for work on a vessel according to the state of health is given by the Ministry of Health of Ukraine in compliance with the rules established by the Ministry”':
    'The Ministry of Health of Ukraine pursuant to the Regulation on the Ministry develops and provides implementation of state policy on health, specifies medical fitness of a seafarer to serve on board ship. Article 52 of the Merchant Shipping Code of Ukraine stipulates that “Persons, who were qualified as fit according to their state of health, are allowed to work on a vessel. The conclusion on fitness for work on a vessel according to the state of health is given by the Ministry of Health of Ukraine in compliance with the rules established by the Ministry”.',
  'The Ministry of Health of Ukraine in accordance with the tasks assigned to it, among other things:':
    'The Ministry of Health of Ukraine in accordance with the tasks assigned to it, among other things:',
  'specifies medical fitness of the seafarer to work on board ship;':
    'specifies medical fitness of the seafarer to work on board ship;',
  'approves the annual list of health institutions that are entitled to conduct a medical examination of seafarers and issue medical certificates;':
    'approves the annual list of health institutions that are entitled to conduct a medical examination of seafarers and issue medical certificates;',
  'performs control and supervision over the compliance of legislation on health care':
    'performs control and supervision over the compliance of legislation on health care.',

  'The State Inspectorate of Ukraine for Safety of Maritime and Inland Water Transport (SISMIT) , which is formed by the Decree of the President of Ukraine № 447/2011 of April 8, 2011, is the central executive body, whose activities are governed and coordinated by the Cabinet of Ministers of Ukraine through the Ministry of Infrastructure. According to the List of central authorities of the executive branch of government, other state bodies responsible for fulfilling the obligations arising from the membership of Ukraine in international organizations, the SISMIT together with the Ministry of Infrastructure is liable for obligations arising from the membership of Ukraine in IMO':
    'The State Inspectorate of Ukraine for Safety of Maritime and Inland Water Transport (SISMIT) , which is formed by the Decree of the President of Ukraine № 447/2011 of April 8, 2011, is the central executive body, whose activities are governed and coordinated by the Cabinet of Ministers of Ukraine through the Ministry of Infrastructure. According to the List of central authorities of the executive branch of government, other state bodies responsible for fulfilling the obligations arising from the membership of Ukraine in international organizations, the SISMIT together with the Ministry of Infrastructure is liable for obligations arising from the membership of Ukraine in IMO.',
  'The SISMIT under the Law of Ukraine “On Transport” and Regulations on the SISMIT provides overall management and monitoring of certification of maritime fleet specialists, and supervises the conduct of training, refresher and updating training, and certification of shipboard personnel of seagoing vessels, operating onshore enterprises of maritime and inland water transport, whose work is related to safety of navigation':
    'The SISMIT under the Law of Ukraine “On Transport” and Regulations on the SISMIT provides overall management and monitoring of certification of maritime fleet specialists, and supervises the conduct of training, refresher and updating training, and certification of shipboard personnel of seagoing vessels, operating onshore enterprises of maritime and inland water transport, whose work is related to safety of navigation.',

  'The Inspectorate for Training and Certification of Seafarers (ITCS) is a state organization and operates under the supervision of the Ministry of Infrastructure of Ukraine':
    'The Inspectorate for Training and Certification of Seafarers (ITCS) is a state organization and operates under the supervision of the Ministry of Infrastructure of Ukraine.',
  'The main tasks of the ITCS are:': 'The main tasks of the ITCS are:',
  'analysis of national and international experience in the field of training, assessment of competence and certification of crew members on seagoing merchant vessels;':
    'analysis of national and international experience in the field of training, assessment of competence and certification of crew members on seagoing merchant vessels;',
  'proficiency endorsement for seafarers at permanent state qualification commissions (SQC) and also endorsements for seafarers’ documents that attest their qualification;':
    'proficiency endorsement for seafarers at permanent state qualification commissions (SQC) and also endorsements for seafarers’ documents that attest their qualification;',
  'representation and protection, on behalf of the Ministry of Infrastructure of Ukraine, of Ukrainian interests in the field of training, assessment of competence and certification of seafarers at IMO':
    'representation and protection, on behalf of the Ministry of Infrastructure of Ukraine, of Ukrainian interests in the field of training, assessment of competence and certification of seafarers at IMO.',
  'Regulation I/10 of the STCW Convention obliges all seafarers serving on board ships entitled to fly the flag of other countries to have evidence attesting the recognition of a certificate by this country. Such endorsements shall be issued only if there is a Memorandum on the recognition of certificates between a country that issued the certificate to a seafarer and a country that recognizes the seafarer’s certificate. The ITCS issues such endorsements on behalf of the Maritime Administration of Ukraine':
    'Regulation I/10 of the STCW Convention obliges all seafarers serving on board ships entitled to fly the flag of other countries to have evidence attesting the recognition of a certificate by this country. Such endorsements shall be issued only if there is a Memorandum on the recognition of certificates between a country that issued the certificate to a seafarer and a country that recognizes the seafarer’s certificate. The ITCS issues such endorsements on behalf of the Maritime Administration of Ukraine.',
  'The implementation of policy on education, training, assessment of competence and certification of seafarers is carried out on the basis of and for the compliance of the Constitution and laws of Ukraine, acts and decrees of the President of Ukraine, acts of the Cabinet of Ministers of Ukraine and relevant orders of the central authorities of the executive branch of government and aims to maintain a high level of standards and their continuous review and improvement':
    'The implementation of policy on education, training, assessment of competence and certification of seafarers is carried out on the basis of and for the compliance of the Constitution and laws of Ukraine, acts and decrees of the President of Ukraine, acts of the Cabinet of Ministers of Ukraine and relevant orders of the central authorities of the executive branch of government and aims to maintain a high level of standards and their continuous review and improvement.',
  'All actions of the Maritime Administration of Ukraine are aimed at improving the quality of the training system and maintain it on a level no lower than international standards, raising the prestige and competitiveness of Ukrainian seafarers on the international maritime market and improving the image of Ukraine as a maritime nation in the world':
    'All actions of the Maritime Administration of Ukraine are aimed at improving the quality of the training system and maintain it on a level no lower than international standards, raising the prestige and competitiveness of Ukrainian seafarers on the international maritime market and improving the image of Ukraine as a maritime nation in the world.',
  'At present Memoranda on recognition of Ukrainian certificates of seafarers have been signed by 53 countries':
    'At present Memoranda on recognition of Ukrainian certificates of seafarers have been signed by 53 countries',
  'Memoranda on recognition of certificates of seafarers from other countries have been signed by Ukraine with 19 Member States of the International Maritime Organization':
    'Memoranda on recognition of certificates of seafarers from other countries have been signed by Ukraine with 19 Member States of the International Maritime Organization.',

  'Ukraine also confirmed its status of a country that fully meets the requirements of the STCW Convention at the European level: the European Union on the consideration of the results of an inspection carried out by the European Maritime Safety Agency in 2010, extended the recognition of Ukraine in relation to its education, training and certification of seafarers, as such that meets the requirements of the Convention. The relevant official confirmation letter on extension of the EU recognition of Ukraine in May 2013 has been sent to the Ministry of Infrastructure (Department of State Policy in Maritime and River Transport) by Fotis Karamitsos, the Director of the Directorate D –Logistics, maritime & land transport and passenger rights of the Directorate-General for Mobility and Transport of the European Commission':
    'Ukraine also confirmed its status of a country that fully meets the requirements of the STCW Convention at the European level: the European Union on the consideration of the results of an inspection carried out by the European Maritime Safety Agency in 2010, extended the recognition of Ukraine in relation to its education, training and certification of seafarers, as such that meets the requirements of the Convention. The relevant official confirmation letter on extension of the EU recognition of Ukraine in May 2013 has been sent to the Ministry of Infrastructure (Department of State Policy in Maritime and River Transport) by Fotis Karamitsos, the Director of the Directorate D –Logistics, maritime & land transport and passenger rights of the Directorate-General for Mobility and Transport of the European Commission.',
  'Coordination arrangements of ministries, departments, institutions and organizations to ensure compliance with the requirements of the STCW Convention':
    'Coordination arrangements of ministries, departments, institutions and organizations to ensure compliance with the requirements of the STCW Convention',
  'General supervision of the management system is conducted by the Cabinet of Ministers of Ukraine through the Ministry of Infrastructure':
    'General supervision of the management system is conducted by the Cabinet of Ministers of Ukraine through the Ministry of Infrastructure.',
  'The Ministry of Infrastructure, the Ministry of Education and Science, the Ministry of Health, in compliance with the relevant laws and regulations develop common policy and coordinated position regarding the standards of maritime education, training, assessment of competence, certification and medical examinations of seafarers':
    'The Ministry of Infrastructure, the Ministry of Education and Science, the Ministry of Health, in compliance with the relevant laws and regulations develop common policy and coordinated position regarding the standards of maritime education, training, assessment of competence, certification and medical examinations of seafarers.',
  'Seafarers obtain education and training at maritime education institutions and training centres':
    'Seafarers obtain education and training at maritime education institutions and training centres.',
  'Prior to entering a maritime education institution, a medical examination of candidates is conducted to define compliance with medical standards set by the Ministry of Health of Ukraine, regulations for determining medical fitness of persons to serve on board ships in accordance with the requirements of regulation I/9 of the STCW Convention':
    'Prior to entering a maritime education institution, a medical examination of candidates is conducted to define compliance with medical standards set by the Ministry of Health of Ukraine, regulations for determining medical fitness of persons to serve on board ships in accordance with the requirements of regulation I/9 of the STCW Convention.',
  'The attestation of graduates of maritime education institutions is conducted at meetings of State Examination Commissions (SEC) of a maritime education institution. The establishment procedures, organization and SEC activities are specified by the MESU. Graduates who have passed state exams and also defended a thesis project (work), SEC decides to confer qualification according to the obtained specialty and issues a certificate of higher education or a standard certificate or a certificate of vocational education':
    'The attestation of graduates of maritime education institutions is conducted at meetings of State Examination Commissions (SEC) of a maritime education institution. The establishment procedures, organization and SEC activities are specified by the MESU. Graduates who have passed state exams and also defended a thesis project (work), SEC decides to confer qualification according to the obtained specialty and issues a certificate of higher education or a standard certificate or a certificate of vocational education.',
  'The assessment of competence and confirmation of qualification of seafarers are conducted at State Qualification Commissions established by the Inspectorate for Training and Certification of Seafarers. Thus, the validity of documents on education, qualification and training issued to seafarers at SQC is always verified at the State Register of Documents on Higher Education and the common State Register of Seafarers’ Documents. It is necessarily specified that provided medical certificates are valid and issued by medical institutions approved by the MHU':
    'The assessment of competence and confirmation of qualification of seafarers are conducted at State Qualification Commissions established by the Inspectorate for Training and Certification of Seafarers. Thus, the validity of documents on education, qualification and training issued to seafarers at SQC is always verified at the State Register of Documents on Higher Education and the common State Register of Seafarers’ Documents. It is necessarily specified that provided medical certificates are valid and issued by medical institutions approved by the MHU.',
  'The list of mandatory training courses required for awarding a certain rank to serve in the respective capacity of an officer or ship’s crew on seagoing vessels is specified by the Ministry of Infrastructure':
    'The list of mandatory training courses required for awarding a certain rank to serve in the respective capacity of an officer or ship’s crew on seagoing vessels is specified by the Ministry of Infrastructure.',
  'From July 1, 2013 training centres conduct training under programmes approved by the SISMIT that comply with the applicable requirements of the STCW Convention, taking into consideration the Manila Amendments':
    'From July 1, 2013 training centres conduct training under programmes approved by the SISMIT that comply with the applicable requirements of the STCW Convention, taking into consideration the Manila Amendments.',
  'All training centres carry out their activities in the system of training standards and are subject to state supervision by the SISMIT and approval of the Ministry of Infrastructure':
    'All training centres carry out their activities in the system of training standards and are subject to state supervision by the SISMIT and approval of the Ministry of Infrastructure.',
  'The list of approved training centers, approved areas and scope of training is set by the Order of the Ministry of Infrastructure':
    'The list of approved training centers, approved areas and scope of training is set by the Order of the Ministry of Infrastructure.',
  'The list of health care institutions for medical examination of seafarers and issue of standard medical certificates for seafarers is specified by the MHU':
    'The list of health care institutions for medical examination of seafarers and issue of standard medical certificates for seafarers is specified by the MHU.',
  'Pursuant to the requirements of regulation I/11 of the STCW Convention, officers, in accordance with the Provisions for awarding ranks of officers of seagoing vessels, shall undertake refresher training at higher maritime education institutions, defined by the MESU and the Ministry of Infrastructure, for being awarded or confirmed the next rank':
    'Pursuant to the requirements of regulation I/11 of the STCW Convention, officers, in accordance with the Provisions for awarding ranks of officers of seagoing vessels, shall undertake refresher training at higher maritime education institutions, defined by the MESU and the Ministry of Infrastructure, for being awarded or confirmed the next rank.',
  'Refresher training programmes have been developed for a certain level (management and operation) for navigators, ship’s engineer officers, ship’s electro-technical officers and radio specialists approved by the MESU and SISMIT, and include recent amendments to the national and international regulations as well as relevant technologies and recommendations for safety of life at sea and marine environment protection':
    'Refresher training programmes have been developed for a certain level (management and operation) for navigators, ship’s engineer officers, ship’s electro-technical officers and radio specialists approved by the MESU and SISMIT, and include recent amendments to the national and international regulations as well as relevant technologies and recommendations for safety of life at sea and marine environment protection.',
  'After the successful confirmation of qualification at the SQC, a seafarer is entitled, within 12 months from the date of the SQC meeting, to obtain a certificate of competency or certificate of proficiency on awarding a rank/confirmation of qualification and an endorsement to this document from a harbour master':
    'After the successful confirmation of qualification at the SQC, a seafarer is entitled, within 12 months from the date of the SQC meeting, to obtain a certificate of competency or certificate of proficiency on awarding a rank/confirmation of qualification and an endorsement to this document from a harbour master.',
  'According to Article 51 of the Merchant Shipping Code of Ukraine, deprivation of a rank and withdrawal of the certificate or qualification certificate is allowed only at a court resolution':
    'According to Article 51 of the Merchant Shipping Code of Ukraine, deprivation of a rank and withdrawal of the certificate or qualification certificate is allowed only at a court resolution.',
  'Suspension of the endorsement to the certificate and extraordinary assessment of competence of officers of seagoing vessels is envisaged in the event of:':
    'Suspension of the endorsement to the certificate and extraordinary assessment of competence of officers of seagoing vessels is envisaged in the event of:',
  'identification of the involvement of an officer in a marine casualty;':
    'identification of the involvement of an officer in a marine casualty;',
  'identification of an incompetent officer, whose actions or inaction which constituted a direct threat to the safety of people, property at sea or marine environment;':
    'identification of an incompetent officer, whose actions or inaction which constituted a direct threat to the safety of people, property at sea or marine environment;',
  'identification of evident non-compliance of the officer’s knowledge or skills with the capacity held;':
    'identification of evident non-compliance of the officer’s knowledge or skills with the capacity held;',
  'non-fulfilment of lawful demands of authorized officials by the officer and eventually there was a threat to the safety of people, property at sea or marine environment':
    'non-fulfilment of lawful demands of authorized officials by the officer and eventually there was a threat to the safety of people, property at sea or marine environment.',
  'The SISMIT, the Ministry of Infrastructure and other central executive authority submit written notice of seafarers’ violation of the requirements of the STCW Convention and national requirements while serving on board ships. The ITCS prepares an officer’s personal data file. The endorsement, the effect of which is suspended, together with the relevant documents (materials) contained in the file, are stored at the ITCS in the personal data file of the officer. Information on suspension of the endorsement to the officer’s certificate is entered in the common State Register of Seafarers’ Documents. Based on the acquired information, the ITCS conducts extraordinary competency assessment of the person, whose endorsement to the certificate is suspended':
    'The SISMIT, the Ministry of Infrastructure and other central executive authority submit written notice of seafarers’ violation of the requirements of the STCW Convention and national requirements while serving on board ships. The ITCS prepares an officer’s personal data file. The endorsement, the effect of which is suspended, together with the relevant documents (materials) contained in the file, are stored at the ITCS in the personal data file of the officer. Information on suspension of the endorsement to the officer’s certificate is entered in the common State Register of Seafarers’ Documents. Based on the acquired information, the ITCS conducts extraordinary competency assessment of the person, whose endorsement to the certificate is suspended.',
  'Pursuant to paragraph 2 of regulation I/5 of the STCW Convention, there are taken appropriate measures to prevent fraud and other unlawful practices involving certificates and endorsements issued, as follows:':
    'Pursuant to paragraph 2 of regulation I/5 of the STCW Convention, there are taken appropriate measures to prevent fraud and other unlawful practices involving certificates and endorsements issued, as follows:',
  'Article 358 of the Criminal Code of Ukraine envisages criminal responsibility for forgery of documents and the use of a knowingly forged document. The provisions of this article fully concern certificates and endorsements for officers and ratings of seagoing vessels;':
    'Article 358 of the Criminal Code of Ukraine envisages criminal responsibility for forgery of documents and the use of a knowingly forged document. The provisions of this article fully concern certificates and endorsements for officers and ratings of seagoing vessels;',
  'according to the Regulations for Vessel Control for Safety of Navigation, approved by the Order of the Ministry of Transport of Ukraine № 545 of 17.07.2003, registered at the Ministry of Justice of Ukraine on 23.03.2004 under № 353/8952, certificates of competency and certificates of proficiency of all crew members, issued under the STCW Convention, are verified on ships flying all flags;':
    'according to the Regulations for Vessel Control for Safety of Navigation, approved by the Order of the Ministry of Transport of Ukraine № 545 of 17.07.2003, registered at the Ministry of Justice of Ukraine on 23.03.2004 under № 353/8952, certificates of competency and certificates of proficiency of all crew members, issued under the STCW Convention, are verified on ships flying all flags;',
  'in order to prevent forgery of seafarers’ certificates and endorsements, one uses printing protection means of the specified types of documents as well as the procedure for mandatory verification of each of these documents for validity at the common State Register of Seafarers’ Documents of Ukraine during each application of the seafarer to obtain, exchange or extend confirmation of such documents':
    'in order to prevent forgery of seafarers’ certificates and endorsements, one uses printing protection means of the specified types of documents as well as the procedure for mandatory verification of each of these documents for validity at the common State Register of Seafarers’ Documents of Ukraine during each application of the seafarer to obtain, exchange or extend confirmation of such documents.',

  'Inrernational Law': 'Inrernational Law',
  'Manila Amendments to the annex to the International Convention on Standarts of Training, Certification and Watchkeeping of Seafarers (STCW), 1978. Download':
    'Manila Amendments to the annex to the International Convention on Standarts of Training, Certification and Watchkeeping of Seafarers (STCW), 1978. Download.',
  'Final Act of the Conference of Parties to the International Convention on Standarts of Training, Certification and Watchkeeping of Seafarers (STCW), 1978':
    'Final Act of the Conference of Parties to the International Convention on Standarts of Training, Certification and Watchkeeping of Seafarers (STCW), 1978.',
  'Amendments to the international convention on standards of training, certification and watchkeeping for seafarers (STCW), 1978, as amended':
    'Amendments to the international convention on standards of training, certification and watchkeeping for seafarers (STCW), 1978, as amended',
  'Resolution MSC.396(95) (adopted on June 11, 2015)': 'Resolution MSC.396(95) (adopted on June 11, 2015)',
  'Resolution MSC.416(97) (adopted on November 25, 2016)': 'Resolution MSC.416(97) (adopted on November 25, 2016)',
  'Amendments to part A of the seafarers’ training, certification and watchkeeping (STCW) code':
    'Amendments to part A of the seafarers’ training, certification and watchkeeping (STCW) code',
  'Resolution MSC.397(95) (adopted on June 11, 2015)': 'Resolution MSC.397(95) (adopted on June 11, 2015)',
  'Resolution MSC.417(97) (adopted on November 25, 2016)': 'Resolution MSC.417(97) (adopted on November 25, 2016)',
  'Amendments to the international convention on standards of training, certification and watchkeeping for seafarers (STCW), 1978, as amended':
    'Amendments to the international convention on standards of training, certification and watchkeeping for seafarers (STCW), 1978, as amended',
  'Resolution MSC.373 (93) (adopted on May 22, 2014)': 'Resolution MSC.373 (93) (adopted on May 22, 2014)',
  'Amendments to the seafarers’ training, certification and watchkeeping (STCW) code':
    'Amendments to the seafarers’ training, certification and watchkeeping (STCW) code',
  'Resolution MSC.374(93) (adopted on May 22, 2014)': 'Resolution MSC.374(93) (adopted on May 22, 2014)',

  'Laws of Ukraine': 'Laws of Ukraine',
  'Law No. 464/96-ВР of November 1, 1996': 'Law No. 464/96-ВР of November 1, 1996',
  '“On Accession of Ukraine to the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978”':
    '“On Accession of Ukraine to the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers, 1978”.',
  'Law No. 176/95-BP of May 23, 1995': 'Law No. 176/95-BP of May 23, 1995',
  '“Code of Merchant Shipping of Ukraine”': '“Code of Merchant Shipping of Ukraine”',

  'Resolutions of the Cabinet of Ministers of Ukraine': 'Resolutions of the Cabinet of Ministers of Ukraine',
  'Resolution No. 734 of June 30, 2021': 'Resolution No. 734 of June 30, 2021',
  '"On the implementation of a pilot project to conduct an exam in the form of computer testing to confirm the qualifications of seafarers."':
    '"On the implementation of a pilot project to conduct an exam in the form of computer testing to confirm the qualifications of seafarers."',
  'Resolution No. 528 of June 13, 2012': 'Resolution No. 528 of June 13, 2012',
  '“On Amendments to certain resolutions of the Cabinet of Ministers of Ukraine”':
    '“On Amendments to certain resolutions of the Cabinet of Ministers of Ukraine”.',
  'Resolution No. 1371 of September 13, 2002': 'Resolution No. 1371 of September 13, 2002',
  '“On Procedures for participation of central executive authorities in the activities of international organizations, of which Ukraine is a member”':
    '“On Procedures for participation of central executive authorities in the activities of international organizations, of which Ukraine is a member”.',
  'Resolution No. 83 of January 31, 2001': 'Resolution No. 83 of January 31, 2001',
  '“On improvement of state supervision over training and certification of seafarers”':
    '“On improvement of state supervision over training and certification of seafarers”.',

  'Orders of the Ministry of Health of Ukraine': 'Orders of the Ministry of Health of Ukraine',
  'Order The Ministry of Health of Ukraine No 795 of April 5, 2019':
    'Order The Ministry of Health of Ukraine No 795 of April 5, 2019',
  '“On Amendments to the list of Health Care Institutions, Heads of Commissions and Vice-Chairs of commissions authorized to conduct medical examinations of seafarers”':
    '“On Amendments to the list of Health Care Institutions, Heads of Commissions and Vice-Chairs of commissions authorized to conduct medical examinations of seafarers”',
  'Order The Ministry of Health of Ukraine No 627 of April 5, 2018':
    'Order The Ministry of Health of Ukraine No 627 of April 5, 2018',
  '“On approval of the List of health Care institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”':
    '“On approval of the List of health Care institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”.',
  'Order The Ministry of Health of Ukraine No 1181 of June 21, 2018':
    'Order The Ministry of Health of Ukraine No 1181 of June 21, 2018',
  '“On Amendments to the list of Health Institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”':
    '“On Amendments to the list of Health Institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”.',
  'Order The Ministry of Health of Ukraine No 2115 of November 19, 2018':
    'Order The Ministry of Health of Ukraine No 2115 of November 19, 2018',
  '“On Amendments to the list of Health Institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”':
    '“On Amendments to the list of Health Institutions, Heads of Commissions and Deputy Heads of Commissions Authorized to Perform Medical Examinations of Seafarers”',

  'Orders of the Ministry of Education and Science of Ukraine':
    'Orders of the Ministry of Education and Science of Ukraine',
  'Order No 1151 of November 6, 2015': 'Order No 1151 of November 6, 2015',
  '“About peculiarities of introduction of the list of branches of knowledge and specialties by which preparation of applicants for higher education is approved, approved by the Resolution of the Cabinet of Ministers of Ukraine of April 29, 2015 No 266”':
    '“About peculiarities of introduction of the list of branches of knowledge and specialties by which preparation of applicants for higher education is approved, approved by the Resolution of the Cabinet of Ministers of Ukraine of April 29, 2015 No 266”.',

  'Orders of the Ministry of Infrastructure of Ukraine': 'Orders of the Ministry of Infrastructure of Ukraine',
  'Order No 317 of May 19, 2020': 'Order No 317 of May 19, 2020',
  '“On Amendments to the List of Approved Training Institutions, the Training of Which Meets the Requirements of the International Convention on Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and National Requirements”':
    '“On Amendments to the List of Approved Training Institutions, the Training of Which Meets the Requirements of the International Convention on Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and National Requirements”.',
  'Order No 591 of August 06, 2019': 'Order No 591 of August 06, 2019',
  '“On the approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on Seafarers Training and Certification and Watchkeeping, 1978, as amended, and the national requirements”':
    '“On the approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on Seafarers Training and Certification and Watchkeeping, 1978, as amended, and the national requirements”.',
  'Order No 188 April 20, 2018': 'Order No 188 April 20, 2018',
  '“On the approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on Seafarers Training and Certification and Watchkeeping, 1978, as amended, and the national requirements”':
    '“On the approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on Seafarers Training and Certification and Watchkeeping, 1978, as amended, and the national requirements”.',
  'Order No 112 of March 22, 2017': 'Order No 112 of March 22, 2017',
  '“Amendments to the List of approved training establishments in accordance with the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and national requirements”':
    '“Amendments to the List of approved training establishments in accordance with the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and national requirements”.',
  'Order No 69 of February 17, 2016': 'Order No 69 of February 17, 2016',
  '“On approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and the national requirements”':
    '“On approval of the List of approved educational establishments, the preparation of which meets the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping, 1978, as amended, and the national requirements”.',
  'Order No 39 of February 01, 2016': 'Order No 39 of February 01, 2016',
  '“On amendments to some orders of the Ministry of Infrastructure of Ukraine”':
    '“On amendments to some orders of the Ministry of Infrastructure of Ukraine”.',
  'Order No 491 of October 07, 2014': 'Order No 491 of October 07, 2014',
  '“On approval of the requirements to simulator and other equipment for training and knowledge verification of officers and shipboard crew”':
    '“On approval of the requirements to simulator and other equipment for training and knowledge verification of officers and shipboard crew”.',
  'Joint Order of the Ministry of Infrastructure and the Ministry of Economic Development and Trade of Ukraine No 479/1182 of October 02, 2014':
    'Joint Order of the Ministry of Infrastructure and the Ministry of Economic Development and Trade of Ukraine No 479/1182 of October 02, 2014',
  '“On amendments to the fee for confirmation of seafarers’ qualification”':
    '“On amendments to the fee for confirmation of seafarers’ qualification”.',
  'Order No 813 of October 18, 2013': 'Order No 813 of October 18, 2013',
  '“On approval of the Procedure for confirmation of qualification and certification of ship officers and ship’s crew of seagoing vessels”':
    '“On approval of the Procedure for confirmation of qualification and certification of ship officers and ship’s crew of seagoing vessels”.',
  'Order No 812 of October 18, 2013': 'Order No 812 of October 18, 2013',
  '“On approval of the Procedure for the issue of qualification documents for ship officers and ship’s crew of seagoing vessels”':
    '“On approval of the Procedure for the issue of qualification documents for ship officers and ship’s crew of seagoing vessels”.',
  'Order No 811 of October 18, 2013': 'Order No 811 of October 18, 2013',
  '“On approval of specimen of documents of officers and crew members of seagoing vessels that certify proper qualification for a respective capacity on board ship and identify their holders”':
    '“On approval of specimen of documents of officers and crew members of seagoing vessels that certify proper qualification for a respective capacity on board ship and identify their holders”.',
  'Order No 567 of August 07, 2013': 'Order No 567 of August 07, 2013',
  '“On approval of the Provisions for awarding ranks of officers of seagoing vessels and procedures for their awarding”':
    '“On approval of the Provisions for awarding ranks of officers of seagoing vessels and procedures for their awarding”.',
  'Order No 487 of November 01, 2011': 'Order No 487 of November 01, 2011',
  '“On amendments to some orders of the Ministry of Transport of Ukraine and the Ministry of Comunications of Ukraine”':
    '“On amendments to some orders of the Ministry of Transport of Ukraine and the Ministry of Comunications of Ukraine”.',
  'Order No 132 of June 03, 2011': 'Order No 132 of June 03, 2011',
  '“On the issue of the Certificate of specification of the authority entitled to verify knowledge of workers of dangerous goods carriage entities”':
    '“On the issue of the Certificate of specification of the authority entitled to verify knowledge of workers of dangerous goods carriage entities”.',
  'Order No 631 of November 22, 2021': 'Order No 631 of November 22, 2021',
  '“On cancellation of the order of the Ministry of Infrastructure of Ukraine of October 12, 2020 № 614”':
    '“On cancellation of the order of the Ministry of Infrastructure of Ukraine of October 12, 2020 № 614”.',
  'Order No. 690 dated 08.09.2022': 'Order No. 690 dated 08.09.2022',
  'On making changes to ': 'On making changes to',
  'List of approved educational and training institutions, the training in which meets the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping of 1978, as amended, and national requirements':
    'List of approved educational and training institutions, the training in which meets the requirements of the International Convention on the Training and Certification of Seafarers and Watchkeeping of 1978, as amended, and national requirements',

  'Orders of the Ministry of Transport and Communication of Ukraine':
    'Orders of the Ministry of Transport and Communication of Ukraine',
  'Order No 377 of May 07, 2007': 'Order No 377 of May 07, 2007',
  '“On approval of the Procedures for State Qualification Commissions” (as revised)':
    '“On approval of the Procedures for State Qualification Commissions” (as revised).',
  'Order No 271 of April 11, 2007': 'Order No 271 of April 11, 2007',
  '“On approval of the Procedures for issue and wearing of badges “Deep-sea voyage captain” and “First-grade ship engineer officer”':
    '“On approval of the Procedures for issue and wearing of badges “Deep-sea voyage captain” and “First-grade ship engineer officer”.',
  'Order No 861 of December 07, 2005': 'Order No 861 of December 07, 2005',
  '“On amendments and additions to the issue No. 67 “Water Transport” (the section “Maritime Transport”). “Reference book for qualification characteristics of workers’ professions” (as revised);':
    '“On amendments and additions to the issue No. 67 “Water Transport” (the section “Maritime Transport”). “Reference book for qualification characteristics of workers’ professions” (as revised);',
  'Order No 411 of August 03, 2005': 'Order No 411 of August 03, 2005',
  '“On approval of the Procedure on issuing of dispensation” (as revised)':
    '“On approval of the Procedure on issuing of dispensation” (as revised).',
  'Order No 1046 of November 26, 2004': 'Order No 1046 of November 26, 2004',
  '“On approval of the Technical description of seafarers’ documents blanks”(as revised)':
    '“On approval of the Technical description of seafarers’ documents blanks”(as revised).',
  'Order No 1042 of November 25, 2004': 'Order No 1042 of November 25, 2004',
  '“On approval of the Provision on examination of enterprises, organizations and institutions which train seafarers” (as revised)':
    '“On approval of the Provision on examination of enterprises, organizations and institutions which train seafarers” (as revised).',
  'Order No 1065/337 of November 08, 2006': 'Order No 1065/337 of November 08, 2006',
  '“On approval of fee rates for seafarers’ qualification attesting”':
    '“On approval of fee rates for seafarers’ qualification attesting”.',

  'Orders of the Ministry of Transport of Ukraine': 'Orders of the Ministry of Transport of Ukraine',
  'Order No 672 of August 28, 2003': 'Order No 672 of August 28, 2003',
  '“On approval of the Procedure of suspension of endorsements to certificates and unscheduled competency verification of officers serving on seagoing ships”':
    '“On approval of the Procedure of suspension of endorsements to certificates and unscheduled competency verification of officers serving on seagoing ships”.',
  'Order No 3 of January 08, 2003': 'Order No 3 of January 08, 2003',
  '“On approval of the Regulations for keeping the сommon State Register of Seafarers’ Documents”':
    '“On approval of the Regulations for keeping the сommon State Register of Seafarers’ Documents”.',
  'Order No 913 of December 24, 2002': 'Order No 913 of December 24, 2002',
  '“On approval of the software on filling up, printing and keeping of information concerning seafarers’ documents, attesting their qualification in the database”':
    '“On approval of the software on filling up, printing and keeping of information concerning seafarers’ documents, attesting their qualification in the database”.',
  'Order No 766 of Oсtober 30, 2002': 'Order No 766 of Oсtober 30, 2002',
  '“On approval of the Procedure of filling up and issue of Endorsements attesting recognition of certificates”':
    '“On approval of the Procedure of filling up and issue of Endorsements attesting recognition of certificates”.',
  'Order No 646 of September 11, 2002': 'Order No 646 of September 11, 2002',
  '“On approval of the specimen of Endorsements attesting recognition of certificates”':
    '“On approval of the specimen of Endorsements attesting recognition of certificates”.',
  'Order No 693 of November 17, 2001': 'Order No 693 of November 17, 2001',
  '“On approval of the Provision on the Inspectorate for Training and Certification of Seafarers ” (as revised)':
    '“On approval of the Provision on the Inspectorate for Training and Certification of Seafarers ” (as revised).',

  'List of IMO Member States that have concluded the Memorandums on recognition of Ukrainian seafarers’ certificates and recognition of their certificates by Ukrainе':
    'List of IMO Member States that have concluded the Memorandums on recognition of Ukrainian seafarers’ certificates and recognition of their certificates by Ukrainе',

  'National standards of Ukraine quality management system (DSTU ISO)':
    'National standards of Ukraine quality management system (DSTU ISO)',
  'DSTU ISO 9001: 2015': 'DSTU ISO 9001: 2015',
  '«National standard of Ukraine. Quality management systems. Requirements.»':
    '«National standard of Ukraine. Quality management systems. Requirements.».',
  'DSTU ISO 9000:2015': 'DSTU ISO 9000:2015',
  '«National standard of Ukraine. Quality management systems. Basic terms and glossary.»':
    '«National standard of Ukraine. Quality management systems. Basic terms and glossary.».',
  'DSTU ISO 10003:2013': 'DSTU ISO 10003:2013',
  '«National standard of Ukraine. Quality management. Customer satisfaction. Guidelines for dispute resolution outside organizations»':
    '«National standard of Ukraine. Quality management. Customer satisfaction. Guidelines for dispute resolution outside organizations».',
  'DSTU ISO 10004:2013': 'DSTU ISO 10004:2013',
  '«National standard of Ukraine. Quality management. Customer satisfaction. Monitoring and evaluation guidelines»':
    '«National standard of Ukraine. Quality management. Customer satisfaction. Monitoring and evaluation guidelines».',
  'DSTU ISO/TR 10013:2003': 'DSTU ISO/TR 10013:2003',
  '«National standard of Ukraine. Guidelines for the development of guality management system documentation»':
    '«National standard of Ukraine. Guidelines for the development of guality management system documentation».',
  'DSTU ISO 10005:2007': 'DSTU ISO 10005:2007',
  '«National standard of Ukraine. Quality management systems. Guidelines for quality programs»':
    '«National standard of Ukraine. Quality management systems. Guidelines for quality programs».',
  'DSTU ISO 19011:2012': 'DSTU ISO 19011:2012',
  '«National standard of Ukraine. Guidelines for augiting management system»':
    '«National standard of Ukraine. Guidelines for augiting management system»',
  'DSTU ІЕС ISO 31010:2013': 'DSTU ІЕС ISO 31010:2013',
  '«National standard of Ukraine. Risk management. Methods of general risk assessment»':
    '«National standard of Ukraine. Risk management. Methods of general risk assessment».',
  'DSTU 4163-2003': 'DSTU 4163-2003',
  '«National standard of Ukraine. Unified system of organizational and administrative documentation. Requirements for paperwork»':
    '«National standard of Ukraine. Unified system of organizational and administrative documentation. Requirements for paperwork».',
  'DSTU 2732:2004': 'DSTU 2732:2004',
  '«National standard of Ukraine. Registry and archives. Terms and definitions»':
    '«National standard of Ukraine. Registry and archives. Terms and definitions»',

  'Public procurement': 'Public procurement',
  'Regulation of the tender committee': 'Regulation of the tender committee',
  'I. Terms': 'I. Terms',
  '1.1. The Regulations on the Tender Committee of the Inspectorate for the Training and Certification of Seafarers (hereinafter – the Inspection) are developed in accordance with the Law of Ukraine “On Public Procurement” (hereinafter – the Law) and determine the legal status, general organizational and procedural principles of the Tender Committee, as well as their rights, duties and responsibilities':
    '1.1. The Regulations on the Tender Committee of the Inspectorate for the Training and Certification of Seafarers (hereinafter – the Inspection) are developed in accordance with the Law of Ukraine “On Public Procurement” (hereinafter – the Law) and determine the legal status, general organizational and procedural principles of the Tender Committee, as well as their rights, duties and responsibilities.',
  '1.2. Tender Committee (hereinafter referred to as the Committee) shall mean the officials (officials) and other persons of the Inspectorate who are responsible for organizing and carrying out procurement procedures in accordance with the Law':
    '1.2. Tender Committee (hereinafter referred to as the Committee) shall mean the officials (officials) and other persons of the Inspectorate who are responsible for organizing and carrying out procurement procedures in accordance with the Law.',
  '1.3. The purpose of the committee is to organize and conduct procurement procedures on the basis of collegiality and impartiality':
    '1.3. The purpose of the committee is to organize and conduct procurement procedures on the basis of collegiality and impartiality.',
  '1.4. The Committee shall be governed by the Law, other regulations on public procurement and this Regulation':
    '1.4. Комітет1.4. The Committee shall be governed by the Law, other regulations on public procurement and this Regulation',

  'II. Principles of activity of the tender committee': 'II. Principles of activity of the tender committee',
  '2.1. The composition of the committee, changes in its composition and its provisions shall be approved by the order of the Inspection. The committee shall consist of at least five Inspection staff':
    '2.1. The composition of the committee, changes in its composition and its provisions shall be approved by the order of the Inspection. The committee shall consist of at least five Inspection staff.',
  '2.2. The committee may not include officials and representatives of participants, members of their families, as well as people’s deputies of Ukraine, deputies of the Verkhovna Rada of the Autonomous Republic of Crimea, and deputies of city, rayon in the city, rayon, and regional council. Committee membership should not create a conflict between the interests of the Inspectorate and the tenderer or between the interests of the participants in the procurement procedure, the presence of which may affect the objectivity and impartiality of decision-making regarding the selection of the winner of the procurement procedure':
    '2.2. The committee may not include officials and representatives of participants, members of their families, as well as people’s deputies of Ukraine, deputies of the Verkhovna Rada of the Autonomous Republic of Crimea, and deputies of city, rayon in the city, rayon, and regional council. Committee membership should not create a conflict between the interests of the Inspectorate and the tenderer or between the interests of the participants in the procurement procedure, the presence of which may affect the objectivity and impartiality of decision-making regarding the selection of the winner of the procurement procedure.',
  '2.3. The committee is managed by its chairman, who is appointed by the head of the Inspectorate and may have the right to sign procurement contracts in the event of such authority being granted by the customer, which is issued in accordance with the legislation':
    '2.3. The committee is managed by its chairman, who is appointed by the head of the Inspectorate and may have the right to sign procurement contracts in the event of such authority being granted by the customer, which is issued in accordance with the legislation.',
  '2.4. The chairman of the committee appoints the deputy chair (s), the secretary from among the members of the committee and determines the functions of each member of the committee. In the absence of the chairman of the committee, his / her duties are performed by the deputy chairman of the committee (if several deputy chairmen of the committee are appointed, the chairman determines among them the acting chairman of the committee for the period of his / her absence). In the absence of the secretary of the committee, another member of the committee designated by his chairman shall perform his duties. The decisions of the chairman of the committee on the appointment of the deputy chairman (s) and the secretary of the committee, determination of the functions of each member of the committee and resolving other issues shall be recorded in the minutes of the committee meeting':
    '2.4. The chairman of the committee appoints the deputy chair (s), the secretary from among the members of the committee and determines the functions of each member of the committee. In the absence of the chairman of the committee, his / her duties are performed by the deputy chairman of the committee (if several deputy chairmen of the committee are appointed, the chairman determines among them the acting chairman of the committee for the period of his / her absence). In the absence of the secretary of the committee, another member of the committee designated by his chairman shall perform his duties. The decisions of the chairman of the committee on the appointment of the deputy chairman (s) and the secretary of the committee, determination of the functions of each member of the committee and resolving other issues shall be recorded in the minutes of the committee meeting.',
  '2.5. The form of the committee’s work is a meeting, which is authorized in the presence of at least two-thirds of the committee members. Committee meetings shall be convened by the chairman of the committee and shall be held as necessary. The list of issues to be considered at a committee meeting and the agenda shall be communicated to the committee members before the meeting begins':
    '2.5. The form of the committee’s work is a meeting, which is authorized in the presence of at least two-thirds of the committee members. Committee meetings shall be convened by the chairman of the committee and shall be held as necessary. The list of issues to be considered at a committee meeting and the agenda shall be communicated to the committee members before the meeting begins.',
  '2.6. Decisions on issues discussed at committee meetings shall be taken by a simple majority. In the event of an even distribution of votes, the vote of the chairman of the committee shall be decisive. The decision of the committee is formalized by a protocol, which is signed by all the members of the committee present at the meeting. The decision reflects the results of the roll-call vote of the members of the committee present at its meeting on each issue. In case of refusal of the member of the committee to sign the protocol about it it is stated in the protocol with justification of reasons for refusal':
    '2.6. Decisions on issues discussed at committee meetings shall be taken by a simple majority. In the event of an even distribution of votes, the vote of the chairman of the committee shall be decisive. The decision of the committee is formalized by a protocol, which is signed by all the members of the committee present at the meeting. The decision reflects the results of the roll-call vote of the members of the committee present at its meeting on each issue. In case of refusal of the member of the committee to sign the protocol about it it is stated in the protocol with justification of reasons for refusal.',
  '2.7. The committee is responsible for organizing and conducting procurement procedures. In the process, it provides the following functions:':
    '2.7. The committee is responsible for organizing and conducting procurement procedures. In the process, it provides the following functions:',
  '– procurement planning, preparation and approval of the annual procurement plan;':
    '– procurement planning, preparation and approval of the annual procurement plan;',
  '– selection of the procurement procedure and its conduct;':
    '– selection of the procurement procedure and its conduct;',
  '– ensuring a level playing field for all participants, an objective and fair choice of the winner;':
    '– ensuring a level playing field for all participants, an objective and fair choice of the winner;',
  '– ensuring the preparation, approval and storage of relevant public procurement documents as defined by the Law;':
    '– ensuring the preparation, approval and storage of relevant public procurement documents as defined by the Law;',
  '– ensuring the publication of information and public procurement reports in accordance with the Law;':
    '– ensuring the publication of information and public procurement reports in accordance with the Law;',
  '– providing clarification to the persons who have indicated their intention to participate in the procurement procedure regarding the content of the tender documentation in case of receipt of relevant requests;':
    '– providing clarification to the persons who have indicated their intention to participate in the procurement procedure regarding the content of the tender documentation in case of receipt of relevant requests;',
  '– implementation of other actions provided by the Law': '– implementation of other actions provided by the Law.',
  '2.8. The chairman, secretary and other members of the committee may be trained in the organization and implementation of procurement':
    '2.8. The chairman, secretary and other members of the committee may be trained in the organization and implementation of procurement.',
  '2.9. Committee members are entitled:': '2.9. Committee members are entitled:',
  '– to participate in the planning of expenditures and determining the need for the goods, works and services to be purchased;':
    '– to participate in the planning of expenditures and determining the need for the goods, works and services to be purchased;',
  '– analyze and / or receive information regarding the implementation of contracts concluded in accordance with the Law;':
    '– analyze and / or receive information regarding the implementation of contracts concluded in accordance with the Law;',
  '– to submit the matter to the committee;': '– to submit the matter to the committee;',
  '– to make a decision with the drawing up of the relevant protocol of the committee regarding the need to correct the technical (mechanical, formal) mistakes made when entering the procurement information, which is published on the web-portal of the Authorized body;':
    '– to make a decision with the drawing up of the relevant protocol of the committee regarding the need to correct the technical (mechanical, formal) mistakes made when entering the procurement information, which is published on the web-portal of the Authorized body;',
  '– to obtain from the structural units of the Inspectorate the information necessary for carrying out procurement procedures;':
    '– to obtain from the structural units of the Inspectorate the information necessary for carrying out procurement procedures;',
  '– submit a separate opinion to the minutes of committee meetings;':
    '– submit a separate opinion to the minutes of committee meetings;',
  '– to initiate the creation of working groups of officials (officials) and other persons of the structural units of the Inspectorate in order to draw up technical requirements for the subject of procurement, project preparation contracts, etc .; carry out other actions provided for by the Law':
    '– to initiate the creation of working groups of officials (officials) and other persons of the structural units of the Inspectorate in order to draw up technical requirements for the subject of procurement, project preparation contracts, etc .; carry out other actions provided for by the Law.',
  '2.10. The members of the committee are obliged:': '2.10. The members of the committee are obliged:',
  '– participate in all its meetings in person; organize and carry out procurement procedures; to ensure a level playing field for all participants, an objective and fair choice of the winner;':
    '– participate in all its meetings in person; organize and carry out procurement procedures; to ensure a level playing field for all participants, an objective and fair choice of the winner;',
  '– comply with public procurement law and this Regulation;':
    '– comply with public procurement law and this Regulation;',
  '– carry out other actions provided for by the Law': '– carry out other actions provided for by the Law.',
  '2.11. Chairman of the Committee:': '2.11. Chairman of the Committee:',
  '– organizes the work of the committee;': '– organizes the work of the committee;',
  '– make decisions on holding committee meetings; determine the date and place of the committee meetings; proposes an agenda for committee meetings;':
    '– make decisions on holding committee meetings; determine the date and place of the committee meetings; proposes an agenda for committee meetings;',
  '– leads a committee meeting;': '– leads a committee meeting;',
  '– to submit to the head of the Inspectorate proposals for changes in the committee;':
    '– to submit to the head of the Inspectorate proposals for changes in the committee;',
  '– exercise other powers in accordance with the law': '– exercise other powers in accordance with the law.',
  '2.12. The Secretary of the Committee shall ensure:': '2.12. The Secretary of the Committee shall ensure:',
  '– keeping and drawing up minutes of committee meetings; prompt informing of committee members on organizational issues of its activity;':
    '– keeping and drawing up minutes of committee meetings; prompt informing of committee members on organizational issues of its activity;',
  '– on the instructions of the chairman of the committee to perform other organizational work; storage of public procurement documents; compliance with the requirements of the legislation on recordkeeping while working with documents;':
    '– on the instructions of the chairman of the committee to perform other organizational work; storage of public procurement documents; compliance with the requirements of the legislation on recordkeeping while working with documents;',
  '– placing public procurement information on the web site of the Authorized Body through authorized electronic sites; fulfillment of other powers in accordance with the legislation':
    '– placing public procurement information on the web site of the Authorized Body through authorized electronic sites; fulfillment of other powers in accordance with the legislation.',
  '2.13. The Chairman of the Committee is personally responsible for the performance of the functions assigned to the Committee':
    '2.13. The Chairman of the Committee is personally responsible for the performance of the functions assigned to the Committee.',
  '2.14. The Committee members are responsible for violation of the requirements established by the Law and normative legal acts developed in accordance with the Law in accordance with the laws of Ukraine':
    '2.14. The Committee members are responsible for violation of the requirements established by the Law and normative legal acts developed in accordance with the Law in accordance with the laws of Ukraine.',
  '2.15. The chairman and the secretary of the committee are responsible for the completeness and accuracy of the information published on the web portal of the Authorized Procurement Body for public access':
    '2.15. The chairman and the secretary of the committee are responsible for the completeness and accuracy of the information published on the web portal of the Authorized Procurement Body for public access.',

  'ІІІ. Requirements for preparation of tender documentation projects':
    'ІІІ. Requirements for preparation of tender documentation projects',
  '3.1. The Inspectorate’s structural unit, which initiates the relevant procurement, ensures the preparation of the draft tender documentation and submits it for consideration and approval to the committee':
    '3.1. The Inspectorate’s structural unit, which initiates the relevant procurement, ensures the preparation of the draft tender documentation and submits it for consideration and approval to the committee.',
  '3.2. In case of discrepancies in the draft tender documentation, the Committee makes a decision to return the project for appropriate refinement to the structural subdivision of the Inspectorate, which initiates the corresponding procurement':
    '3.2. In case of discrepancies in the draft tender documentation, the Committee makes a decision to return the project for appropriate refinement to the structural subdivision of the Inspectorate, which initiates the corresponding procurement.',
  '3.3. The head of the Inspectorate’s structural unit or the person who replaces it is directly responsible for the preparation of the draft tender documentation':
    '3.3. The head of the Inspectorate’s structural unit or the person who replaces it is directly responsible for the preparation of the draft tender documentation.',

  'Authorized person of the Inspectorate for the training and certification of seafarers':
    'Authorized person of the Inspectorate for the training and certification of seafarers',
  'POCHTAR Liubov': 'POCHTAR Liubov',
  'Tsybko Sergiy': 'Tsybko Sergiy',
  'Chief Specialist of Quality Management, Certification and Audit Department, Head of Tender committee;':
    'Chief Specialist of Quality Management, Certification and Audit Department, Head of Tender committee;',
  'Christina Dashkevich': 'Christina Dashkevich',
  'chief specialist of the legal department, member of the tender committee;':
    'chief specialist of the legal department, member of the tender committee;',
  'Stepanyuk Olga': 'Stepanyuk Olga',
  'head of testing department, member of tender committee;': 'head of testing department, member of tender committee;',
  'Orekhova Inna': 'Orekhova Inna,',
  'chief specialist, member of the tender committee;': 'chief specialist, member of the tender committee;',
  'Pikulik Vladimir': 'Pikulik Vladimir',
  'Head of Diploma and Training Department, Member of Tender Committee;':
    'Head of Diploma and Training Department, Member of Tender Committee;',
  'Tatiana Demukh': 'Tatiana Demukh',
  'accountant of category I of accounting and reporting department, member of tender committee;':
    'accountant of category I of accounting and reporting department, member of tender committee;',
  'Bondarenko Bogdan': 'Bondarenko Bogdan',
  'Branch Manager, Member of the Tender Committee': 'Branch Manager, Member of the Tender Committee.',

  'Procurement Inspection plan': 'Procurement Inspection plan',
  'Procurement Inspection': 'Procurement Inspection',

  'Command for public procurement': 'Command for public procurement',
  'Order № 115 of October 20, 2020': 'Order № 115 of October 20, 2020',
  'On amendments to the composition of the tender committee of the Inspectorate for the training and certification of seafarers':
    'On amendments to the composition of the tender committee of the Inspectorate for the training and certification of seafarers.',
  'Order № 87 of July 3, 2020': 'Order № 87 of July 3, 2020',
  'On the appointment of an authorized person for the procurement of goods, works and services, the value of which does not exceed 50 thousand UAH':
    'On the appointment of an authorized person for the procurement of goods, works and services, the value of which does not exceed 50 thousand UAH.',
  'Order № 78 of June 10, 2020': 'Order № 78 of June 10, 2020',
  'On the organization and conduct of simplified procurement in the Inspectorate for the training and certification of seafarers':
    'On the organization and conduct of simplified procurement in the Inspectorate for the training and certification of seafarers',
  'Order № 134 of August 15, 2019': 'Order № 134 of August 15, 2019',
  'Amendments to the composition of the tender committee of the Inspectorate for training and certification of seafarers':
    'Amendments to the composition of the tender committee of the Inspectorate for training and certification of seafarers',
  'Order No 118 of July 19, 2019': 'Order No 118 of July 19, 2019',
  'Amendments to the Tender Committee of the Inspectorate for the Training and Certification of Seafarers':
    'Amendments to the Tender Committee of the Inspectorate for the Training and Certification of Seafarers',
  'Order No 63 of April 5, 2019': 'Order No 63 of April 5, 2019',
  'Amendments to the Tender Committee of the Inspectorate for the Training and Certification of Seafarers':
    'Amendments to the Tender Committee of the Inspectorate for the Training and Certification of Seafarers',
  'Order No 32 of February 13, 2019': 'Order No 32 of February 13, 2019',
  'Approval of the composition of the tender committee of the Inspectorate for the Training and Certification of Seafarers':
    'Approval of the composition of the tender committee of the Inspectorate for the Training and Certification of Seafarers',
  'Order No 14 of January 16, 2019': 'Order No 14 of January 16, 2019',
  'On approval of the Procedure for the organization of sub-threshold procurement in case of using the electronic procurement system':
    'On approval of the Procedure for the organization of sub-threshold procurement in case of using the electronic procurement system',
  'Order No 106 of July 08, 2016': 'Order No 106 of July 08, 2016',
  'Approval of the composition of the Tender Committee of the Inspectorate for the Training and Certification of Seafarers and its Regulations':
    'Approval of the composition of the Tender Committee of the Inspectorate for the Training and Certification of Seafarers and its Regulations',

  'Anti-corruption activities': 'Anti-corruption activities',
  Program: 'Program',

  'Composition of commission for the evaluation of corruption risks':
    'Composition of commission for the evaluation of corruption risks',
  'Commissioner for Anti-Corruption, Chairman of the Commission':
    'Commissioner for Anti-Corruption, Chairman of the Commission',
  'Velykan Valeriy': 'Velykan Valeriy',
  'Deputy Head of Document Accounting Sector': 'Deputy Head of Document Accounting Sector',
  'Markova Svetlana': 'Markova Svetlana',
  'Acting Head of HR department': 'Acting Head of HR department',
  'Buzinska Tatiana': 'Buzinska Tatiana',
  'Head of the Legal Department': 'Head of the Legal Department',
  'Burova Elena': 'Burova Elena',
  'Head of the Department for International Cooperation': 'Head of the Department for International Cooperation',
  'Varfolomyeyeva Mariya': 'Varfolomyeyeva Mariya',
  'Head of the occupational safety and fire safety sector': 'Head of the occupational safety and fire safety sector',
  'Holodnyuk Svetlana': 'Holodnyuk Svetlana',
  'Director of the State Register of Seafarers’ Documents of Ukraine':
    'Director of the State Register of Seafarers’ Documents of Ukraine',
  'Gribov Oleg': 'Gribov Oleg',
  'Acting Chief Accountant – Head of Accounting and Reporting':
    'Acting Chief Accountant – Head of Accounting and Reporting',
  'Kirilenko Victoria': 'Kirilenko Victoria',
  'Acting Head of Information Technology Department': 'Acting Head of Information Technology Department',
  'Lenik Arkady': 'Lenik Arkady',
  'Head of Quality Management, Certification and Audit': 'Head of Quality Management, Certification and Audit',
  'Tatiana Makintsiyan': 'Tatiana Makintsiyan',
  'Head of program preparation and development sector': 'Head of program preparation and development sector',
  'Momot Lyudmila': 'Momot Lyudmila',
  'Head of Diploma and Training Department': 'Head of Diploma and Training Department',
  'Pikulik Vladimir': 'Pikulik Vladimir',
  'Head of testing department': 'Head of testing department',
  'Stepanyuk Olga': 'Stepanyuk Olga',
  'Head of logistics department': 'Head of logistics department',
  'Tsiplyakov Igor': 'Tsiplyakov Igor',

  'Methodology for assessing corruption risks': 'Methodology for assessing corruption risks',
  'Corruption is one of the most dangerous threats to human rights, democracy, the rule of law, honesty and social justice, it impedes economic development and threatens the proper and fair functioning of both the state and society as a whole, as well as enterprises, institutions, organizations. Therefore, solving the problem of corruption is one of the priority directions of the state development':
    'Corruption is one of the most dangerous threats to human rights, democracy, the rule of law, honesty and social justice, it impedes economic development and threatens the proper and fair functioning of both the state and society as a whole, as well as enterprises, institutions, organizations. Therefore, solving the problem of corruption is one of the priority directions of the state development.',
  'The Seafarers Training and Inspection Inspectorate (hereinafter – the Inspectorate) is actively working to prevent and detect corruption, and to eliminate the preconditions for its occurrence':
    'The Seafarers Training and Inspection Inspectorate (hereinafter – the Inspectorate) is actively working to prevent and detect corruption, and to eliminate the preconditions for its occurrence.',
  'However, ignorance and misunderstanding of the conditions and circumstances under which corruption occurs and develops create obstacles to the effective prevention of this negative social phenomenon. Therefore, rational planning of anti-corruption measures should always take place with a clear understanding of the full spectrum of corruption risks':
    'However, ignorance and misunderstanding of the conditions and circumstances under which corruption occurs and develops create obstacles to the effective prevention of this negative social phenomenon. Therefore, rational planning of anti-corruption measures should always take place with a clear understanding of the full spectrum of corruption risks.',
  'Carrying out an objective assessment of corruption risks in the activity of the Inspectorate is a necessary step in preventing violations of anti-corruption legislation by the employees of the Inspection':
    'Carrying out an objective assessment of corruption risks in the activity of the Inspectorate is a necessary step in preventing violations of anti-corruption legislation by the employees of the Inspection.',
  'Assessment of corruption risks in the activities of the Inspectorate and State Qualification Commissions (hereinafter referred to as the SCC) will ensure compliance of anti-corruption measures with the specificity of the Inspectorate’s activities and facilitate the rational use of the Inspectorate’s resources allocated to prevent corruption work':
    'Assessment of corruption risks in the activities of the Inspectorate and State Qualification Commissions (hereinafter referred to as the SCC) will ensure compliance of anti-corruption measures with the specificity of the Inspectorate’s activities and facilitate the rational use of the Inspectorate’s resources allocated to prevent corruption work.',

  '1. TERMS': '1. TERMS',
  'This methodology for assessing corruption risks in the activities of the Inspectorate and recommendations for their elimination (hereinafter referred to as the methodology) defines:':
    'This methodology for assessing corruption risks in the activities of the Inspectorate and recommendations for their elimination (hereinafter referred to as the methodology) defines:',
  '– the concept of “corruption risk – ways of detecting corruption risks that may arise in the activities of the Inspection officials; – the reasons that give rise to corruption risks in the activities of the officials of the Inspectorate; – the areas of activity of the Inspectorate with the highest corruption risks; – management of corruption risks (measures to counteract and prevent corruption risks in the Inspectorate)':
    '– the concept of “corruption risk – ways of detecting corruption risks that may arise in the activities of the Inspection officials; – the reasons that give rise to corruption risks in the activities of the officials of the Inspectorate; – the areas of activity of the Inspectorate with the highest corruption risks; – management of corruption risks (measures to counteract and prevent corruption risks in the Inspectorate).',
  'Systematic work on detecting corruption risks in the Inspectorate is carried out with the aim of reducing the manifestation of corruption and its consequences through the implementation of regulatory, organizational and other measures':
    'Systematic work on detecting corruption risks in the Inspectorate is carried out with the aim of reducing the manifestation of corruption and its consequences through the implementation of regulatory, organizational and other measures.',
  'The main objectives of the methodology are: – outline the causes and conditions that may give rise to corruption;–prevention of corruption and corruption-related offenses, minimization of corruption, formation of intolerant among the employees of the Inspectorate, heads and members of DCC, negative attitude towards corruption as a socially dangerous phenomenon; – establishment of an effective system for preventing corruption and identifying facts of corruption in the Inspectorate;– ensuring that employees are aware of measures to prevent corruption and corruption-related offenses':
    'The main objectives of the methodology are: – outline the causes and conditions that may give rise to corruption;–prevention of corruption and corruption-related offenses, minimization of corruption, formation of intolerant among the employees of the Inspectorate, heads and members of DCC, negative attitude towards corruption as a socially dangerous phenomenon; – establishment of an effective system for preventing corruption and identifying facts of corruption in the Inspectorate;– ensuring that employees are aware of measures to prevent corruption and corruption-related offenses.',
  'The purpose of identifying and assessing corruption risks is to identify specific processes in the activity of the Inspectorate, in the implementation of which there is a high likelihood of perpetration by employees of the Inspectorate of actions containing signs of corruption offenses. Determination for each area of ​​activity of the Corruption Risk Inspectorate is not an unquestionable evidence of the existence of corruption facts, but is only a reason to assume their occurrence in case the Inspection officials do not comply with the requirements of anti-corruption legislation and established internal procedures. The recommendations contain an indicative, but not exhaustive, list of typical corruption risks, common methods for detecting them, and rules for elimination (minimization)':
    'The purpose of identifying and assessing corruption risks is to identify specific processes in the activity of the Inspectorate, in the implementation of which there is a high likelihood of perpetration by employees of the Inspectorate of actions containing signs of corruption offenses. Determination for each area of ​​activity of the Corruption Risk Inspectorate is not an unquestionable evidence of the existence of corruption facts, but is only a reason to assume their occurrence in case the Inspection officials do not comply with the requirements of anti-corruption legislation and established internal procedures. The recommendations contain an indicative, but not exhaustive, list of typical corruption risks, common methods for detecting them, and rules for elimination (minimization).',

  '2. THE CONCEPTS OF CORRUPTION RISKS AND THE ALGORITHM OF ACTIONS FOR THEIR DETECTION AND MANAGEMENT':
    '2. THE CONCEPTS OF CORRUPTION RISKS AND THE ALGORITHM OF ACTIONS FOR THEIR DETECTION AND MANAGEMENT',
  'Corruption risks are understood to mean regulatory, administrative, organizational, and other factors (factors, conditions) in the activities of Inspectorate officials that promote or may contribute to the commission of corruption offenses and corruption offenses':
    'Corruption risks are understood to mean regulatory, administrative, organizational, and other factors (factors, conditions) in the activities of Inspectorate officials that promote or may contribute to the commission of corruption offenses and corruption offenses.',
  'The work on corruption risks in the Inspectorate should be constructed in the following sequence:':
    'The work on corruption risks in the Inspectorate should be constructed in the following sequence:',
  '– identification of corruption risks; – assessment of corruption risks; – management (prevention) of corruption risks; – development of anti-corruption programs and plans, as well as control over their implementation':
    '– identification of corruption risks; – assessment of corruption risks; – management (prevention) of corruption risks; – development of anti-corruption programs and plans, as well as control over their implementation',
  'Identification of corruption risks – is the process of identifying, understanding and understanding the corruption risks of the inspection staff':
    'Identification of corruption risks – is the process of identifying, understanding and understanding the corruption risks of the inspection staff.',
  'The assessment of corruption risks is to determine the potential and real consequences for certain areas of the Inspectorate’s activities in case the Inspectorate’s employees do not comply with the requirements of anti-corruption legislation. In addition, when assessing corruption risks, the Inspectorate may determine the likelihood of their occurrence in the activities of the structural unit or the Inspectorate as a whole':
    'The assessment of corruption risks is to determine the potential and real consequences for certain areas of the Inspectorate’s activities in case the Inspectorate’s employees do not comply with the requirements of anti-corruption legislation. In addition, when assessing corruption risks, the Inspectorate may determine the likelihood of their occurrence in the activities of the structural unit or the Inspectorate as a whole.',
  'Corruption risk management is the identification and selection of the most effective measures to counteract and prevent corruption offenses and offenses related to corruption in the Inspectorate. At the stage of planning and monitoring the implementation of relevant anti-corruption programs and plans, further steps are taken by the organization to identify, assess and manage corruption risks, analyze the effectiveness of previously implemented anti-corruption measures and prevent corruption offenses, and control them. In the practical analysis of individual areas and activities of the organization, depending on the specific circumstances, other methods other than those specified in these recommendations may be used to identify corruption risks, and specific methods for their prevention and elimination (minimization), more specific to specific ones, may be developed and used. specific area of activity of each department of the Inspectorate':
    'Corruption risk management is the identification and selection of the most effective measures to counteract and prevent corruption offenses and offenses related to corruption in the Inspectorate. At the stage of planning and monitoring the implementation of relevant anti-corruption programs and plans, further steps are taken by the organization to identify, assess and manage corruption risks, analyze the effectiveness of previously implemented anti-corruption measures and prevent corruption offenses, and control them. In the practical analysis of individual areas and activities of the organization, depending on the specific circumstances, other methods other than those specified in these recommendations may be used to identify corruption risks, and specific methods for their prevention and elimination (minimization), more specific to specific ones, may be developed and used. specific area of ​​activity of each department of the Inspectorate',

  '3. WAYS TO IDENTIFY CORRUPTION RISKS': '3. WAYS TO IDENTIFY CORRUPTION RISKS',
  'In order to identify corruption risks in the activity of the Inspectorate, it is necessary to be guided by the information available in the Inspection, as well as to use internal and external resources, for which it is necessary to carry out a thorough analysis of the following components':
    'In order to identify corruption risks in the activity of the Inspectorate, it is necessary to be guided by the information available in the Inspection, as well as to use internal and external resources, for which it is necessary to carry out a thorough analysis of the following components',
  '– previous activity of the structural units of the Inspectorate in order to identify measures, procedures and decisions that caused the inspection by the Inspectorate of Corruption Offenses and / or Corruption Offenses':
    '– previous activity of the structural units of the Inspectorate in order to identify measures, procedures and decisions that caused the inspection by the Inspectorate of Corruption Offenses and / or Corruption Offenses',
  '– previous activities of the Inspectorate’s structural units in order to identify measures, procedures and decisions aimed at eliminating (minimizing) the consequences of corruption offenses and / or corruption offenses committed by the inspection personnel':
    '– previous activities of the Inspectorate’s structural units in order to identify measures, procedures and decisions aimed at eliminating (minimizing) the consequences of corruption offenses and / or corruption offenses committed by the inspection personnel',
  '– results of external and internal inspections, official investigations, which consists in the analysis of acts, certificates and other documented results of the conducted measures, in particular, of the given recommendations, in order to maximally study the spheres whose activities were carried out in violation of the relevant regulatory requirements and their current status likelihood of committing (facilitating) corruption and / or corruption-related offenses':
    '– results of external and internal inspections, official investigations, which consists in the analysis of acts, certificates and other documented results of the conducted measures, in particular, of the given recommendations, in order to maximally study the spheres whose activities were carried out in violation of the relevant regulatory requirements and their current status likelihood of committing (facilitating) corruption and / or corruption-related offenses',
  '– letters, appeals, complaints received from individuals and legal entities containing information about the areas and areas of activity of the Inspectorate, in which there are signs of actions that indicate a possible commission of corruption offenses. In doing so, special attention should be paid to complaints about the activities of individual officials of the Inspectorate, which, in turn, allows to trace the systematic nature of their misconduct':
    '– letters, appeals, complaints received from individuals and legal entities containing information about the areas and areas of activity of the Inspectorate, in which there are signs of actions that indicate a possible commission of corruption offenses. In doing so, special attention should be paid to complaints about the activities of individual officials of the Inspectorate, which, in turn, allows to trace the systematic nature of their misconduct',
  '– regulatory, organizational and regulatory documents governing the activities of the Inspectorate’s structural subdivision and individual procedures to ensure that they have provisions that may facilitate the commission of corruption offenses and / or offenses related to corruption (corrupt factors)':
    '– regulatory, organizational and regulatory documents governing the activities of the Inspectorate’s structural subdivision and individual procedures to ensure that they have provisions that may facilitate the commission of corruption offenses and / or offenses related to corruption (corrupt factors)',
  '– the list, nature and extent of authority of the officials of the Inspectorate, which provides for the study of the official instructions of such persons, as well as their comparison with the real scope of competence and activities carried out':
    '– the list, nature and extent of authority of the officials of the Inspectorate, which provides for the study of the official instructions of such persons, as well as their comparison with the real scope of competence and activities carried out',
  '– the current jurisprudence to hold public law enforcement officials accountable for corruption and corruption-related offenses':
    '– the current jurisprudence to hold public law enforcement officials accountable for corruption and corruption-related offenses',
  'The use of internal resources involves': 'The use of internal resources involves',
  '– general and / or anonymous surveys (questionnaires) of employees of a separate structural unit or of the whole Inspection, by filling in the appropriate forms to identify areas of risk, internal shortcomings of certain procedures':
    '– general and / or anonymous surveys (questionnaires) of employees of a separate structural unit or of the whole Inspection, by filling in the appropriate forms to identify areas of risk, internal shortcomings of certain procedures',
  '– personal interviews (interviews) with individual employees of the Inspectorate who are able, in the light of their experience, competence, duties performed, to cover in more detail the problematic (potentially risky) areas of the Inspectorate’s activity':
    '– personal interviews (interviews) with individual employees of the Inspectorate who are able, in the light of their experience, competence, duties performed, to cover in more detail the problematic (potentially risky) areas of the Inspectorate’s activity',
  '– discussions at meetings, meetings on issues related to corruption risks in the activities of the officials of the Inspectorate, and a joint search for ways to eliminate them (minimize)':
    '– discussions at meetings, meetings on issues related to corruption risks in the activities of the officials of the Inspectorate, and a joint search for ways to eliminate them (minimize)',
  '– official investigations (investigations) in order to examine the facts of the illegal behavior of the officials of the Inspectorate and their results, as well as the reasons and conditions that contributed to the commission of actions containing signs of offenses':
    '– official investigations (investigations) in order to examine the facts of the illegal behavior of the officials of the Inspectorate and their results, as well as the reasons and conditions that contributed to the commission of actions containing signs of offenses',
  'The use of external resources involves periodic holding': 'The use of external resources involves periodic holding',
  '– analysis of external experience, which is to study the state of implementation of procedures aimed at detecting and preventing corruption by other state enterprises, institutions, organizations, as well as state bodies':
    '– analysis of external experience, which is to study the state of implementation of procedures aimed at detecting and preventing corruption by other state enterprises, institutions, organizations, as well as state bodies',
  '– analysis of ways and positive practices of managing corruption risks, their elimination (minimization) by other state enterprises, institutions, organizations, as well as state bodies':
    '– analysis of ways and positive practices of managing corruption risks, their elimination (minimization) by other state enterprises, institutions, organizations, as well as state bodies',
  '– general and / or anonymous surveys (surveys) of members of the public and business entities who have had direct experience (both negative and positive) of interacting with the Inspectorate for the effectiveness of appropriate procedures and artificial burdens. It should be noted that the effectiveness of the implementation of the measure will be enhanced if it is combined with an internal survey (questionnaire) and subsequent comparison and analysis of the results':
    '– general and / or anonymous surveys (surveys) of members of the public and business entities who have had direct experience (both negative and positive) of interacting with the Inspectorate for the effectiveness of appropriate procedures and artificial burdens. It should be noted that the effectiveness of the implementation of the measure will be enhanced if it is combined with an internal survey (questionnaire) and subsequent comparison and analysis of the results',
  '– group discussions in the form of thematic meetings, roundtable discussions, involving representatives of government bodies, economic entities, the public and experts in relevant areas to discuss corruption risks, and to work out ways to eliminate them (minimize)':
    '– group discussions in the form of thematic meetings, roundtable discussions, involving representatives of government bodies, economic entities, the public and experts in relevant areas to discuss corruption risks, and to work out ways to eliminate them (minimize)',
  '– analysis of national and international studies, ie study and generalization of conclusions and recommendations on the results of independent sociological, scientific, expert and other developments in the anticorruption sphere':
    '– analysis of national and international studies, ie study and generalization of conclusions and recommendations on the results of independent sociological, scientific, expert and other developments in the anticorruption sphere',
  '– analysis of reports in the media about deficiencies in certain procedures, corruption schemes, bad behavior of the officials of the Inspectorate (in particular, appearances on radio and television, publications and comments in print media, online publications, etc.)':
    '– analysis of reports in the media about deficiencies in certain procedures, corruption schemes, bad behavior of the officials of the Inspectorate (in particular, appearances on radio and television, publications and comments in print media, online publications, etc.)',

  '4. GENERAL RULES FOR CORRUPTION RISK (MINIMIZATION) REMOVAL':
    '4. GENERAL RULES FOR CORRUPTION RISK (MINIMIZATION) REMOVAL',
  'In order to eliminate (minimize) corruption risks, the Inspectorate should use the following general rules:':
    'In order to eliminate (minimize) corruption risks, the Inspectorate should use the following general rules:',
  '– improvement of the regulatory and organizational documents of the Inspectorate in order to eliminate (prevent) provisions that could potentially contribute to corruption;':
    '– improvement of the regulatory and organizational documents of the Inspectorate in order to eliminate (prevent) provisions that could potentially contribute to corruption;',
  '– clarification (specification) of the list and powers of the officials of the Inspectorate;':
    '– clarification (specification) of the list and powers of the officials of the Inspectorate;',
  '– developing and implementing in the Inspectorate provisions on mechanisms for preventing, detecting and resolving conflicts of interest, restrictions and rules for receiving gifts, benefits, benefits, other external (charitable) assistance, periodically reviewing and updating such provisions;':
    '– developing and implementing in the Inspectorate provisions on mechanisms for preventing, detecting and resolving conflicts of interest, restrictions and rules for receiving gifts, benefits, benefits, other external (charitable) assistance, periodically reviewing and updating such provisions;',
  '– Conducting anti-corruption training on an ongoing basis for Inspectorate staff, including its management;':
    '– Conducting anti-corruption training on an ongoing basis for Inspectorate staff, including its management;',
  '– specifying and setting specific timeframes for the implementation of certain actions to be performed by the specific executor of the procedure and overall simplification and transparency of the management decision-making process;':
    '– specifying and setting specific timeframes for the implementation of certain actions to be performed by the specific executor of the procedure and overall simplification and transparency of the management decision-making process;',
  '– minimizing the influence (use) of the human factor (direct contact and influence) in the decision-making process;':
    '– minimizing the influence (use) of the human factor (direct contact and influence) in the decision-making process;',
  '– carrying out official investigations on each fact of alleged violation of the requirements of anti-corruption legislation and ensuring the inevitability of liability in accordance with the current legislation in case of confirmation of such violations;':
    '– carrying out official investigations on each fact of alleged violation of the requirements of anti-corruption legislation and ensuring the inevitability of liability in accordance with the current legislation in case of confirmation of such violations;',
  '– the inevitability of personal responsibility for improperly performing the responsibilities of managing subordinate personnel':
    '– the inevitability of personal responsibility for improperly performing the responsibilities of managing subordinate personnel.',
  'At the same time, the Inspectorate develops, implements and implements appropriate outreach work on a “corruption risk map”, which will include a list of the main Inspection processes and the procedures that describe them, their level of risk, and a summary description of possible corruption offenses. Such a “corruption risk map” may form part of an anti-corruption program or exist in the form of a separate document approved by the order of the Inspector General. In addition, a list of positions related to high corruption risks in the Inspectorate is required':
    'At the same time, the Inspectorate develops, implements and implements appropriate outreach work on a “corruption risk map”, which will include a list of the main Inspection processes and the procedures that describe them, their level of risk, and a summary description of possible corruption offenses. Such a “corruption risk map” may form part of an anti-corruption program or exist in the form of a separate document approved by the order of the Inspector General. In addition, a list of positions related to high corruption risks in the Inspectorate is required.',
  'Employees holding such positions may have specific requirements for anti-corruption procedures, such as conducting a background check on hiring and / or corruption-related offenses, regularly completing a conflict of interest form , reports of cases of solicitation or instigation of corruption or corruption-related offenses, etc':
    'Employees holding such positions may have specific requirements for anti-corruption procedures, such as conducting a background check on hiring and / or corruption-related offenses, regularly completing a conflict of interest form , reports of cases of solicitation or instigation of corruption or corruption-related offenses, etc.',

  '5. SPECIAL RULES FOR CORRUPTION RISK REDUCTION (MINIMIZATION)':
    '5. SPECIAL RULES FOR CORRUPTION RISK REDUCTION (MINIMIZATION)',
  'When analyzing the activities of the Inspectorate to identify corruption risks, first of all, attention should be paid to the areas of activity that may include the following typical corruption risks':
    'When analyzing the activities of the Inspectorate to identify corruption risks, first of all, attention should be paid to the areas of activity that may include the following typical corruption risks.',
  'The activities of the Inspectorate are characterized by corruption risks in the following areas:':
    'The activities of the Inspectorate are characterized by corruption risks in the following areas:',
  '5.1. PROFESSIONAL SELECTION AND PERSONNING': '5.1. PROFESSIONAL SELECTION AND PERSONNING',
  'Staffing is the main resource of the organization, which determines in the first place the success of its activities. Therefore, selection of personnel is one of the most important management functions, which determines the further fate of the Inspectorate, without which other management functions cannot be realized':
    'Staffing is the main resource of the organization, which determines in the first place the success of its activities. Therefore, selection of personnel is one of the most important management functions, which determines the further fate of the Inspectorate, without which other management functions cannot be realized.',
  'Recruitment is the procedure of selecting candidates from the total number of candidates for a vacant position, differentiating candidates by the degree of their suitability for future activity, and deciding on their possible work in the Inspectorate':
    'Recruitment is the procedure of selecting candidates from the total number of candidates for a vacant position, differentiating candidates by the degree of their suitability for future activity, and deciding on their possible work in the Inspectorate.',
  'It should be noted that the selection system for the management staff, as well as the inspection staff, should be clear and, where appropriate, transparent. In this case, the mandatory criterion for the selection of personnel should be the presence of applicants for the post of special knowledge, skills, abilities, experience in the relevant field, high moral and ethical qualities':
    'It should be noted that the selection system for the management staff, as well as the inspection staff, should be clear and, where appropriate, transparent. In this case, the mandatory criterion for the selection of personnel should be the presence of applicants for the post of special knowledge, skills, abilities, experience in the relevant field, high moral and ethical qualities.',

  'Typical risks:': 'Typical risks:',
  '- the competitive bidding for the vacant vacations of the Inspectorate (the lobby is designated, without the need for clarity and vigorous procedures with all possible minds for all potential candidates);':
    '- the competitive bidding for the vacant vacations of the Inspectorate (the lobby is designated, without the need for clarity and vigorous procedures with all possible minds for all potential candidates);',
  '- non-response by the applicant for a busy vacant landing about potential potential real interest;':
    '- non-response by the applicant for a busy vacant landing about potential potential real interest;',
  '- submission by the applicant for the landing of inadequate data':
    '- submission by the applicant for the landing of inadequate data.',
  'Recommended remedies:': 'Recommended remedies:',
  '- develop and approve the selection conditions for vacancies in the Inspectorate;':
    '- develop and approve the selection conditions for vacancies in the Inspectorate;',
  '- to engage in interviews with candidates for the vacant posts of the Inspectorate of the Anti-Corruption Commissioner in the Inspectorate;':
    '- to engage in interviews with candidates for the vacant posts of the Inspectorate of the Anti-Corruption Commissioner in the Inspectorate;',
  '- ensure proper documentation of interviews with candidates for vacant positions in the Inspectorate in accordance with the selection conditions;':
    '- ensure proper documentation of interviews with candidates for vacant positions in the Inspectorate in accordance with the selection conditions;',
  '- to warn applicants for the position of responsibility for submitting false information about themselves;':
    '- to warn applicants for the position of responsibility for submitting false information about themselves;',
  '- to verify the accuracy of the information submitted by the applicant for the position of himself / herself with the originals or certified copies of the relevant documents, as well as his / her compliance with the qualification requirements;':
    '- to verify the accuracy of the information submitted by the applicant for the position of himself / herself with the originals or certified copies of the relevant documents, as well as his / her compliance with the qualification requirements;',

  '5.2. WORKING WITH STAFF': '5.2. WORKING WITH STAFF',
  'Staffing is all the managerial actions that directly affect employees':
    'Staffing is all the managerial actions that directly affect employees.',
  'Typical risks:': 'Typical risks:',
  'privileged or knowingly prejudiced treatment of individual Inspection personnel;':
    'privileged or knowingly prejudiced treatment of individual Inspection personnel;',
  'the application of repressive measures of influence (translation, attestation, change of working conditions, etc.) to persons who assist in the prevention and counteraction of corruption (accusers);':
    'the application of repressive measures of influence (translation, attestation, change of working conditions, etc.) to persons who assist in the prevention and counteraction of corruption (accusers);',
  'concealing unethical behavior of subordinates;': 'concealing unethical behavior of subordinates;',
  'concealing unethical behavior of subordinates;': 'concealing unethical behavior of subordinates;',
  'receipt by officials of the Inspection of Gifts from subordinates':
    'receipt by officials of the Inspection of Gifts from subordinates.',
  'Recommended remedies:': 'Recommended remedies:',
  'ensure that channels are available and effective to inform the Inspectorate staff about the facts of corruption or corruption-related offenses;':
    'ensure that channels are available and effective to inform the Inspectorate staff about the facts of corruption or corruption-related offenses;',
  'conduct training and professional development of managers in management issues;':
    'conduct training and professional development of managers in management issues;',
  'to provide increased control over employees whose activities are associated with increased corruption risk;':
    'to provide increased control over employees whose activities are associated with increased corruption risk;',
  'Conduct periodic anonymous surveys of employees to determine job satisfaction and overall ethics':
    'Conduct periodic anonymous surveys of employees to determine job satisfaction and overall ethics.',

  '5.3. DELEGATION OF AUTHORITY': '5.3. DELEGATION OF AUTHORITY',
  'Delegation of authority is the process of delegating to a supervisor any part of his or her work and authority necessary for its performance to a subordinate who assumes responsibility for its performance':
    'Delegation of authority is the process of delegating to a supervisor any part of his or her work and authority necessary for its performance to a subordinate who assumes responsibility for its performance.',
  'The delegation of authority is:': 'The delegation of authority is:',
  'delegated powers (granting a subordinate a limited right to make decisions, use resources and direct efforts of subordinates to accomplish their tasks);':
    'delegated powers (granting a subordinate a limited right to make decisions, use resources and direct efforts of subordinates to accomplish their tasks);',
  'accepted responsibilities (the subordinate undertakes to fulfill the tasks set and to ensure their solution);':
    'accepted responsibilities (the subordinate undertakes to fulfill the tasks set and to ensure their solution);',
  'accountability (a subordinate who, using his authority, should be aware of the consequences of his actions and report them to his immediate supervisor)':
    'accountability (a subordinate who, using his authority, should be aware of the consequences of his actions and report them to his immediate supervisor).',
  'Typical risks:': 'Typical risks:',
  'the use by the Inspectorate staff of delegated powers for personal or third party purposes contrary to the interests of the Inspectorate (abuse of authority);':
    'the use by the Inspectorate staff of delegated powers for personal or third party purposes contrary to the interests of the Inspectorate (abuse of authority);',
  'exceeding the limits of the delegated powers': 'exceeding the limits of the delegated powers.',
  'Recommended remedies:': 'Recommended remedies:',
  'establish clear boundaries and procedures for the exercise of delegated authority by the inspection staff;':
    'establish clear boundaries and procedures for the exercise of delegated authority by the inspection staff;',
  'ensure control over the exercise of the powers vested in the subordinates':
    'ensure control over the exercise of the powers vested in the subordinates,',
  'as well as the application of disciplinary measures to them in the event of detection of facts of improper exercise of authority or performance of a person’s work in their own interests or to the exercise of which they were not entitled;':
    'as well as the application of disciplinary measures to them in the event of detection of facts of improper exercise of authority or performance of a person’s work in their own interests or to the exercise of which they were not entitled;',
  'ensure control over the observance of the rules of record keeping on the subject of fixation of the performed work (storage of the bound copies of the executed documents, their proper registration, etc.)':
    'ensure control over the observance of the rules of record keeping on the subject of fixation of the performed work (storage of the bound copies of the executed documents, their proper registration, etc.).',

  '5.4. THE AVAILABILITY OF OFFICIALS OF THE CORPORATE RIGHTS ORGANIZATION IN THE FIELD OF WHICH THEY EXERCISE THEIR AUTHORITIES':
    '5.4. THE AVAILABILITY OF OFFICIALS OF THE CORPORATE RIGHTS ORGANIZATION IN THE FIELD OF WHICH THEY EXERCISE THEIR AUTHORITIES',
  'According to the first part of Art. 167 of the Commercial Code of Ukraine, corporate rights are the rights of a person whose share is determined in the authorized capital (property) of a business organization, which include the power to participate in the management of a business organization, to receive a certain share of profits (dividends) of the organization and assets in the event of liquidation the latter in accordance with the law, as well as other powers provided for by law and statutes':
    'According to the first part of Art. 167 of the Commercial Code of Ukraine, corporate rights are the rights of a person whose share is determined in the authorized capital (property) of a business organization, which include the power to participate in the management of a business organization, to receive a certain share of profits (dividends) of the organization and assets in the event of liquidation the latter in accordance with the law, as well as other powers provided for by law and statutes.',
  'An enterprise is an independent business entity created by a competent state or local government body or other entities to meet social and personal needs through the systematic implementation of industrial, research, trade, and other economic activities in the manner prescribed by the Commercial Code Ukraine and other laws (Article 62 of the Economic Code of Ukraine)':
    'An enterprise is an independent business entity created by a competent state or local government body or other entities to meet social and personal needs through the systematic implementation of industrial, research, trade, and other economic activities in the manner prescribed by the Commercial Code Ukraine and other laws (Article 62 of the Economic Code of Ukraine).',
  'However, if there are economic relations between the Inspectorate with a legal entity owned by an Inspection official, then such an official already has a conflict of interest':
    'However, if there are economic relations between the Inspectorate with a legal entity owned by an Inspection official, then such an official already has a conflict of interest.',
  'Typical risks:': 'Typical risks:',
  'use of the Inspectorate’s resources (official transport, communications, office equipment, official information, etc.) for the benefit of his enterprise;':
    'use of the Inspectorate’s resources (official transport, communications, office equipment, official information, etc.) for the benefit of his enterprise;',
  'improper assistance in the management activities;': 'improper assistance in the management activities;',
  'not informing the official of the Inspectorate of his / her direct supervisor about the real or potential conflict of interest that arises in connection with work in his enterprise;':
    'not informing the official of the Inspectorate of his / her direct supervisor about the real or potential conflict of interest that arises in connection with work in his enterprise;',
  'use by an official of the Inspectorate of subordinates to carry out work at their enterprise':
    'use by an official of the Inspectorate of subordinates to carry out work at their enterprise.',
  'Recommended remedies:': 'Recommended remedies:',
  'warn employees of the Inspectorate of the inadmissibility of using its resources for the benefit of their enterprise;':
    'warn employees of the Inspectorate of the inadmissibility of using its resources for the benefit of their enterprise;',
  'take action to resolve a real or potential conflict of interest':
    'take action to resolve a real or potential conflict of interest.',

  '5.5 MANAGEMENT RELATIONSHIPS WITH OTHER ENTERPRISES, INSTITUTIONS AND ORGANIZATIONS':
    '5.5 MANAGEMENT RELATIONSHIPS WITH OTHER ENTERPRISES, INSTITUTIONS AND ORGANIZATIONS',
  'A separate group of corruption risks is those arising in the sphere of the external activity of the Inspectorate, ie in its relations with other enterprises, institutions, organizations or state bodies':
    'A separate group of corruption risks is those arising in the sphere of the external activity of the Inspectorate, ie in its relations with other enterprises, institutions, organizations or state bodies',
  'Typical risks': 'Typical risks',
  '- concealment by an official of the Inspectorate of informal relations with a business entity with which the Inspectorate is in a contractual relationship or intends to enter into a contract (contract)':
    '- concealment by an official of the Inspectorate of informal relations with a business entity with which the Inspectorate is in a contractual relationship or intends to enter into a contract (contract)',
  '- assisting the Inspection officer with certain economic entities in carrying out economic activities':
    '- assisting the Inspection officer with certain economic entities in carrying out economic activities',
  '- the possibility of obtaining inaccurate information from outside organizations for further consideration and processing of this information by the inspection staff':
    '- the possibility of obtaining inaccurate information from outside organizations for further consideration and processing of this information by the inspection staff',
  'Recommended remedies': 'Recommended remedies',
  '- to take into account the financial interests (in particular, the existing corporate rights) of officials, on the basis of information contained in declarations of property, income, expenses and financial obligations':
    '- to take into account the financial interests (in particular, the existing corporate rights) of officials, on the basis of information contained in declarations of property, income, expenses and financial obligations',
  '- ensure that all documentation is recorded and stored as part of the work (service) procurement procedure':
    '- ensure that all documentation is recorded and stored as part of the work (service) procurement procedure',
  '- ensure the protection of confidential information, agree between the company and the entity the rules of its protection':
    '- ensure the protection of confidential information, agree between the company and the entity the rules of its protection',
  '- ensure clear and properly structured contracts (contracts)':
    '- ensure clear and properly structured contracts (contracts)',
  '- to carry out an assessment of corruption risks and to identify measures for their elimination before the conclusion of the contract (contract)':
    '- to carry out an assessment of corruption risks and to identify measures for their elimination before the conclusion of the contract (contract)',
  '- ensure proper financial accounting and periodic audit of the enterprise':
    '- ensure proper financial accounting and periodic audit of the enterprise',

  '5.6. PUBLIC PROCUREMENT': '5.6. PUBLIC PROCUREMENT',
  'The area of public procurement is characterized by a high level of corruption, which is related to the widespread practice of non-transparent procurement procedures. At the same time, the adoption of the new Law on Public Procurement has eliminated many corruption-related factors in this field at the legislative level. The law, in particular, improved the procurement rules and conditions and ensured the creation of an e-procurement system. However, at the organizational level, there are some risks, especially when it comes to performing ancillary purchases':
    'The area of public procurement is characterized by a high level of corruption, which is related to the widespread practice of non-transparent procurement procedures. At the same time, the adoption of the new Law on Public Procurement has eliminated many corruption-related factors in this field at the legislative level. The law, in particular, improved the procurement rules and conditions and ensured the creation of an e-procurement system. However, at the organizational level, there are some risks, especially when it comes to performing ancillary purchases.',
  'The Inspectorate makes every effort to minimize the risks of business relations with counterparties that may be involved in corruption activities, for which it checks the counterparties’ tolerance for corruption, including the verification of their own anti-corruption programs, their readiness to include anti-corruption agreements. (caveat) and provide mutual support for ethical conduct of business and prevention of corruption':
    'The Inspectorate makes every effort to minimize the risks of business relations with counterparties that may be involved in corruption activities, for which it checks the counterparties’ tolerance for corruption, including the verification of their own anti-corruption programs, their readiness to include anti-corruption agreements. (caveat) and provide mutual support for ethical conduct of business and prevention of corruption.',
  'Typical risks:': 'Typical risks:',
  '- disclosure by the Inspection official to one of the participants of the procurement procedure of confidential information regarding offers of other participants;':
    '- disclosure by the Inspection official to one of the participants of the procurement procedure of confidential information regarding offers of other participants;',
  '- concealing an employee of the Inspectorate who is directly involved in organizing a procurement procedure that has a conflict of interest;':
    '- concealing an employee of the Inspectorate who is directly involved in organizing a procurement procedure that has a conflict of interest;',
  '- conspiracy of participants in the procurement procedure;':
    '- conspiracy of participants in the procurement procedure;',
  '- falsification of documentation;': '- falsification of documentation;',
  '- publication on the official website of incomplete information about the procedure for the procurement, their evaluation and obtaining the approval decision;':
    '- publication on the official website of incomplete information about the procedure for the procurement, their evaluation and obtaining the approval decision;',
  '- failure to notify the chairman and / or the member of the tender committee or the member of the technical committee about the presence of conflict of interest;':
    '- failure to notify the chairman and / or the member of the tender committee or the member of the technical committee about the presence of conflict of interest;',
  '- acceptance of the offer, promise or undue benefit of the potential counterparty by the chairman and / or member of the tender committee or member of the technical committee;':
    '- acceptance of the offer, promise or undue benefit of the potential counterparty by the chairman and / or member of the tender committee or member of the technical committee;',
  '- abuse in the evaluation of the bids of the procurement participants in order to reject the bid of the successful bidder':
    '- abuse in the evaluation of the bids of the procurement participants in order to reject the bid of the successful bidder',
  'Recommended remedies:': 'Recommended remedies:',
  '- ensure that clear, one-size-fits-all qualification criteria are set out for tenderers;':
    '- ensure that clear, one-size-fits-all qualification criteria are set out for tenderers;',
  '- establish unqualified qualification criteria for the participants in the procurement procedure;':
    '- establish unqualified qualification criteria for the participants in the procurement procedure;',
  '- create conditions for free and equal access of potential participants of the procurement procedure to the information concerning the procedure for their conduct;':
    '- create conditions for free and equal access of potential participants of the procurement procedure to the information concerning the procedure for their conduct;',
  '- ensure the expansion of the competition market by taking appropriate effective measures':
    '- ensure the expansion of the competition market by taking appropriate effective measures.',

  '5.7. CONFLICT OF INTERESTS': '5.7. CONFLICT OF INTERESTS',
  'Particular attention in the activities of the officials of the Inspectorate should be given to the prevention and resolution of conflicts of interest. Unlike the previous Law of Ukraine “On the Principles of Prevention and Combating Corruption”, which provided only a general definition of conflict of interest, the Law of Ukraine “On Prevention of Corruption” distinguishes two of its types: the real one and the potential one. According to Art. 1 of the said Law:':
    'Particular attention in the activities of the officials of the Inspectorate should be given to the prevention and resolution of conflicts of interest. Unlike the previous Law of Ukraine “On the Principles of Prevention and Combating Corruption”, which provided only a general definition of conflict of interest, the Law of Ukraine “On Prevention of Corruption” distinguishes two of its types: the real one and the potential one. According to Art. 1 of the said Law:',
  'potential conflict of interest': 'potential conflict of interest',
  '– is the presence of a private interest in the field in which he or she performs his / her official or representative powers, which may affect the objectivity or impartiality of his / her decision-making, or the act or omission of actions during the exercise of such powers;':
    '– is the presence of a private interest in the field in which he or she performs his / her official or representative powers, which may affect the objectivity or impartiality of his / her decision-making, or the act or omission of actions during the exercise of such powers;',
  'real conflict of interest': 'real conflict of interest',
  '– it is a contradiction between the private interest of the person and his or her official or representative powers, which affects the objectivity or impartiality of decision-making, or the act or omission to act in the exercise of those powers':
    '– it is a contradiction between the private interest of the person and his or her official or representative powers, which affects the objectivity or impartiality of decision-making, or the act or omission to act in the exercise of those powers.',
  'What is important is that the existence of a conflict of interest does not necessarily lead to facts of corruption, but it substantially increases the risk of corruption and is a prerequisite for them. Therefore, the legislation provides for a number of provisions to prevent and resolve conflicts of interest':
    'What is important is that the existence of a conflict of interest does not necessarily lead to facts of corruption, but it substantially increases the risk of corruption and is a prerequisite for them. Therefore, the legislation provides for a number of provisions to prevent and resolve conflicts of interest.',
  'Thus, for public law officials, the Law of Ukraine “On Prevention of Corruption” stipulates the following duties aimed at preventing and resolving conflicts of interest:':
    'Thus, for public law officials, the Law of Ukraine “On Prevention of Corruption” stipulates the following duties aimed at preventing and resolving conflicts of interest:',
  '– take steps to prevent a real, potential conflict of interest;':
    '– take steps to prevent a real, potential conflict of interest;',
  '– not to take actions or make decisions in the conditions of real conflict of interest;':
    '– not to take actions or make decisions in the conditions of real conflict of interest;',
  '– take steps to resolve real or potential conflicts of interest;':
    '– take steps to resolve real or potential conflicts of interest;',
  '– to report no later than the next business day from the moment when the person found out or should have known about the real or potential conflict of interest of the immediate supervisor':
    '– to report no later than the next business day from the moment when the person found out or should have known about the real or potential conflict of interest of the immediate supervisor.',

  'Inspection staff are advised to refrain from any suggestions that may place them in a conflict of interest situation':
    'Inspection staff are advised to refrain from any suggestions that may place them in a conflict of interest situation.',
  'The above means that, first of all, a conflict of interest must be prevented. Therefore, the practice of deliberately creating by the Inspectorate of the Conflict of Interests circumstances, and subsequently taking measures to resolve it, is wrong':
    'The above means that, first of all, a conflict of interest must be prevented. Therefore, the practice of deliberately creating by the Inspectorate of the Conflict of Interests circumstances, and subsequently taking measures to resolve it, is wrong.',
  'As a result, the Inspectorate, which plans to take steps to prevent and resolve conflicts of interest, has the difficult task of striking a balance between the interests of the Inspectorate and those of its employees':
    'As a result, the Inspectorate, which plans to take steps to prevent and resolve conflicts of interest, has the difficult task of striking a balance between the interests of the Inspectorate and those of its employees.',
  'On the one hand, the employees of the Inspectorate have the right to engage in any activities not prohibited by law, engage in property relations, and so forth in their free time. On the other hand, such private activity of employees, as well as family and other personal relationships existing with employees may conflict with the interests of the Inspectorate':
    'On the one hand, the employees of the Inspectorate have the right to engage in any activities not prohibited by law, engage in property relations, and so forth in their free time. On the other hand, such private activity of employees, as well as family and other personal relationships existing with employees may conflict with the interests of the Inspectorate.',
  'Therefore, the main task of the Inspectorate to prevent and resolve conflicts of interest should be to limit and eliminate the influence of the private interest of employees on the exercise of their job functions, in particular, in making business decisions, rather than necessarily eliminating the circumstances that lead to a conflict of interest. Ways to resolve conflicts of interest, defined by the Law of Ukraine “On Prevention of Corruption” (Article 29 of the said Law):':
    'Therefore, the main task of the Inspectorate to prevent and resolve conflicts of interest should be to limit and eliminate the influence of the private interest of employees on the exercise of their job functions, in particular, in making business decisions, rather than necessarily eliminating the circumstances that lead to a conflict of interest. Ways to resolve conflicts of interest, defined by the Law of Ukraine “On Prevention of Corruption” (Article 29 of the said Law):',
  '– removing a person from completing a task, taking action, taking a decision or participating in its taking in the face of a real or potential conflict of interest;':
    '– removing a person from completing a task, taking action, taking a decision or participating in its taking in the face of a real or potential conflict of interest;',
  '– Applying external control over a person’s execution of a task, committing it to certain actions or making decisions;':
    '– Applying external control over a person’s execution of a task, committing it to certain actions or making decisions;',
  '– restricting a person’s access to certain information;': '– restricting a person’s access to certain information;',
  '– viewing the amount of authority a person has;': '– viewing the amount of authority a person has;',
  '– transfer of a person to another position;': '– transfer of a person to another position;',
  '– release of the person': '– release of the person.',
  'When resolving a conflict of interest, the softest measure of resolution should be chosen, taking into account existing circumstances. Stricter measures should be used when necessitated by real necessity, or where the more lenient measures have proved to be ineffective. The following principles should be based on the work of conflict of interest management (prevention, settlement) at the Inspectorate:':
    'When resolving a conflict of interest, the softest measure of resolution should be chosen, taking into account existing circumstances. Stricter measures should be used when necessitated by real necessity, or where the more lenient measures have proved to be ineffective. The following principles should be based on the work of conflict of interest management (prevention, settlement) at the Inspectorate:',
  '– individual review and assessment of material and reputational risks to the Inspectorate in identifying and resolving any conflict of interest;':
    '– individual review and assessment of material and reputational risks to the Inspectorate in identifying and resolving any conflict of interest;',
  '– confidentiality of conflict of interest disclosure and resolution processes;':
    '– confidentiality of conflict of interest disclosure and resolution processes;',
  '– maintaining the balance of interests of the Inspectorate and the employee in the resolution of conflicts of interest;':
    '– maintaining the balance of interests of the Inspectorate and the employee in the resolution of conflicts of interest;',
  '– protecting an employee from harassment due to a conflict of interest message from another employee of the organization':
    '– protecting an employee from harassment due to a conflict of interest message from another employee of the organization.',

  'Typical risks:': 'Typical risks:',
  '– not informing the official of the Inspectorate of his / her direct supervisor about the conflict of interest and taking decisions in the conditions of his / her presence;':
    '– not informing the official of the Inspectorate of his / her direct supervisor about the conflict of interest and taking decisions in the conditions of his / her presence;',
  '– use of authority by the official to obtain material or other personal gain for himself or others;':
    '– use of authority by the official to obtain material or other personal gain for himself or others;',
  '– the use by the official of powers for the purpose of adversely affecting persons to whom they have an unfavorable (biased) attitude;':
    '– the use by the official of powers for the purpose of adversely affecting persons to whom they have an unfavorable (biased) attitude;',
  '– claiming unlawful rewards for committing certain actions;':
    '– claiming unlawful rewards for committing certain actions;',
  '– deliberate concealment of conflicts of interest;': '– deliberate concealment of conflicts of interest;',
  '– unduly promoting individual or legal entities or their associations':
    '– unduly promoting individual or legal entities or their associations;',
  'Recommended remedies:': 'Recommended remedies:',
  '– to develop methodological recommendations on the prevention and settlement of conflicts of interest in the activities of officials of the Inspectorate, which provide typical examples of situations of conflict of interest, to determine an approximate algorithm for actions aimed at its identification and settlement;':
    '– to develop methodological recommendations on the prevention and settlement of conflicts of interest in the activities of officials of the Inspectorate, which provide typical examples of situations of conflict of interest, to determine an approximate algorithm for actions aimed at its identification and settlement;',
  '– Regularly review the declarations of property, income, expenses and commitments of a financial nature submitted by Inspection officials for a conflict of interest;':
    '– Regularly review the declarations of property, income, expenses and commitments of a financial nature submitted by Inspection officials for a conflict of interest;',
  '– ensure the timely notification of the Inspection officer to his immediate supervisor that he or she has a real or potential conflict of interest;':
    '– ensure the timely notification of the Inspection officer to his immediate supervisor that he or she has a real or potential conflict of interest;',
  '– regularly collect information on conflict of interest from the inspection staff;':
    '– regularly collect information on conflict of interest from the inspection staff;',
  '– to ensure unconditional prosecution of employees of the enterprise in case of failure to notify them in the cases established by law and the procedure for the presence of real conflict of interests':
    '– to ensure unconditional prosecution of employees of the enterprise in case of failure to notify them in the cases established by law and the procedure for the presence of real conflict of interests.',

  '5.8. PRESENTS': '5.8. PRESENTS',
  'According to Art. 1 of the Law of Ukraine “On Prevention of Corruption” is a gift of money or other property, benefits, privileges, services, intangible assets that provide / receive free of charge or at a price below the minimum market. Inspection officials should refuse to receive a gift if it is likely that:':
    'According to Art. 1 of the Law of Ukraine “On Prevention of Corruption” is a gift of money or other property, benefits, privileges, services, intangible assets that provide / receive free of charge or at a price below the minimum market. Inspection officials should refuse to receive a gift if it is likely that:',
  '– such a gift is related to the person’s post in the Inspectorate;':
    '– such a gift is related to the person’s post in the Inspectorate;',
  '– this may cause conflicts of interest;': '– this may cause conflicts of interest;',
  '– doing so will discredit the Inspectorate or damage its reputation':
    '– doing so will discredit the Inspectorate or damage its reputation.',
  'At the same time, officials of the Inspectorate may accept gifts that are in accordance with generally accepted ideas about hospitality, within the limits specified by the Law of Ukraine “On Prevention of Corruption”':
    'At the same time, officials of the Inspectorate may accept gifts that are in accordance with generally accepted ideas about hospitality, within the limits specified by the Law of Ukraine “On Prevention of Corruption”.',
  'Generally accepted notions of hospitality can be understood to mean receiving gifts from loved ones, long-time friends or acquaintances who give gifts on the occasion of a birthday, anniversary or public holiday (New Year, International Women’s Day, Defender of Ukraine Day, etc.)':
    'Generally accepted notions of hospitality can be understood to mean receiving gifts from loved ones, long-time friends or acquaintances who give gifts on the occasion of a birthday, anniversary or public holiday (New Year, International Women’s Day, Defender of Ukraine Day, etc.).',
  'A prerequisite for accepting gifts and hospitality is that such gifts and hospitality are not permanent (systematic) and will not affect the decision-making (withholding) of the Inspectorate’s decision-maker in the exercise of his or her authority in Inspections':
    'A prerequisite for accepting gifts and hospitality is that such gifts and hospitality are not permanent (systematic) and will not affect the decision-making (withholding) of the Inspectorate’s decision-maker in the exercise of his or her authority in Inspections.',
  'Typical risks:': 'Typical risks:',
  '– the use of the tradition of welcoming Inspectorate officials for personal and professional holidays;':
    '– the use of the tradition of welcoming Inspectorate officials for personal and professional holidays;',
  '– lack of proper control over the observance of the provisions of the Law of Ukraine “On Prevention of Corruption” regarding the refusal to receive gifts, as well as the declaration of gifts received;':
    '– lack of proper control over the observance of the provisions of the Law of Ukraine “On Prevention of Corruption” regarding the refusal to receive gifts, as well as the declaration of gifts received;',
  '– a conflict of interest as a result of receiving a gift':
    '– a conflict of interest as a result of receiving a gift.',
  'Recommended remedies:': 'Recommended remedies:',
  '– to keep records (register) of gifts and gifts offered to officials of the Inspectorate, received as hospitality or as gifts for the Inspectorate;':
    '– to keep records (register) of gifts and gifts offered to officials of the Inspectorate, received as hospitality or as gifts for the Inspectorate;',
  '– eliminate (minimize) personal, informal (non-working) contacts of officials of the Inspectorate with representatives of the enterprises, institutions, organizations with which the Inspectorate cooperates':
    '– eliminate (minimize) personal, informal (non-working) contacts of officials of the Inspectorate with representatives of the enterprises, institutions, organizations with which the Inspectorate cooperates.',

  '5.9. INFORMATION MANAGEMENT': '5.9. INFORMATION MANAGEMENT',
  'According to Art. 1 of the Law of Ukraine “On Information” information is any information and / or data that can be stored on a material medium or displayed electronically':
    'According to Art. 1 of the Law of Ukraine “On Information” information is any information and / or data that can be stored on a material medium or displayed electronically.',
  'Information about an individual is confidential, as well as information that is restricted to an individual or legal entity, except for the authorities. Confidential information may be disseminated at the request (consent) of the person concerned in accordance with the procedure prescribed by it, as well as in other cases specified by law (Part 2 of Article 21 of the Law of Ukraine “On Information”)':
    'Information about an individual is confidential, as well as information that is restricted to an individual or legal entity, except for the authorities. Confidential information may be disseminated at the request (consent) of the person concerned in accordance with the procedure prescribed by it, as well as in other cases specified by law (Part 2 of Article 21 of the Law of Ukraine “On Information”).',
  'The official information may include the following information (Article 9 of the Law of Ukraine “On Access to Public Information”):':
    'The official information may include the following information (Article 9 of the Law of Ukraine “On Access to Public Information”):',
  '– contained in the documents of the entities that make up the inter-departmental official correspondence, supporting notes, recommendations, if they are related to the development of the direction of activity of the institution or the exercise of control, supervisory functions by public authorities, the decision-making process and preceding public discussion and / or decision making;':
    '– contained in the documents of the entities that make up the inter-departmental official correspondence, supporting notes, recommendations, if they are related to the development of the direction of activity of the institution or the exercise of control, supervisory functions by public authorities, the decision-making process and preceding public discussion and / or decision making;',
  '– collected in the course of operational search, counterintelligence, defense of a country that is not classified as a state secret':
    '– collected in the course of operational search, counterintelligence, defense of a country that is not classified as a state secret.',
  'In turn, information related to the production, technology, management, financial and other activities of a non-State secret entity, disclosure of which may harm the entity’s interests, may be recognized as a trade secret. The composition and amount of information that constitutes a trade secret, the method of their protection are determined by the entity in accordance with the law (Part 1 of Article 36 of the Economic Code of Ukraine)':
    'In turn, information related to the production, technology, management, financial and other activities of a non-State secret entity, disclosure of which may harm the entity’s interests, may be recognized as a trade secret. The composition and amount of information that constitutes a trade secret, the method of their protection are determined by the entity in accordance with the law (Part 1 of Article 36 of the Economic Code of Ukraine).',
  'Misuse of confidential, commercial or other proprietary informationMisuse of confidential, commercial or other proprietary information':
    'Misuse of confidential, commercial or other proprietary informationMisuse of confidential, commercial or other proprietary information',
  'Typical risks:': 'Typical risks:',
  '– the transfer of confidential, commercial or other proprietary information to third parties, without legitimate reason, for the purpose of obtaining undue advantage;':
    '– the transfer of confidential, commercial or other proprietary information to third parties, without legitimate reason, for the purpose of obtaining undue advantage;',
  '– use of personal data or information about individuals for their own gainful purposes;':
    '– use of personal data or information about individuals for their own gainful purposes;',
  '– disclosure of confidential, commercial or other proprietary information upon dismissal;':
    '– disclosure of confidential, commercial or other proprietary information upon dismissal;',
  '– use of confidential, commercial and other official information, other resources of the enterprise for the purpose of organizing their own business':
    '– use of confidential, commercial and other official information, other resources of the enterprise for the purpose of organizing their own business.',
  'Recommended remedies:': 'Recommended remedies:',
  '– provide written warning of restrictions related to the handling of confidential, commercial or other proprietary information;':
    '– provide written warning of restrictions related to the handling of confidential, commercial or other proprietary information;',
  '– provide technical restrictions on the use of confidential, commercial or other proprietary information, including, in part, access to it, making it impossible to copy, transmit or otherwise move such information;':
    '– provide technical restrictions on the use of confidential, commercial or other proprietary information, including, in part, access to it, making it impossible to copy, transmit or otherwise move such information;',
  '– ensure that accounting and periodic reviews of access to and use of sensitive information are made compulsory;':
    '– ensure that accounting and periodic reviews of access to and use of sensitive information are made compulsory;',
  '– warn of the inadmissibility of disclosure of confidential, commercial and other proprietary information by a person upon dismissal':
    '– warn of the inadmissibility of disclosure of confidential, commercial and other proprietary information by a person upon dismissal.',

  '5.10. FINANCE MANAGEMENT': '5.10. FINANCE MANAGEMENT',
  'Financial management – purposeful formation of the process of redistribution of financial resources, activities for attracting and efficient use of financial resources':
    'Financial management – purposeful formation of the process of redistribution of financial resources, activities for attracting and efficient use of financial resources.',
  '5.10.1. Payment for goods, works, services': '5.10.1. Payment for goods, works, services',
  'Typical risks:': 'Typical risks:',
  '– intentionally, by agreement with the supplier, overestimation of the cost of goods, works and services;':
    '– intentionally, by agreement with the supplier, overestimation of the cost of goods, works and services;',
  '– violation of officials of the Payment Discipline Inspectorate;':
    '– violation of officials of the Payment Discipline Inspectorate;',
  '– the bias of officials, in particular because of private interest, when allocating funds to the Inspectorate’s counterparties (payment of services by some and delay of payment for the benefit of others)':
    '– the bias of officials, in particular because of private interest, when allocating funds to the Inspectorate’s counterparties (payment of services by some and delay of payment for the benefit of others);',
  'Recommended remedies:': 'Recommended remedies:',
  '– periodically conduct internal audit of the use of financial resources;':
    '– periodically conduct internal audit of the use of financial resources;',
  '– to check compliance with the order of payments in accordance with the organizational and administrative documents of the Inspectorate':
    '– to check compliance with the order of payments in accordance with the organizational and administrative documents of the Inspectorate.',
  '5.10.2. Remuneration': '5.10.2. Remuneration',
  'Remuneration (wages) is the monetary expression of the value and cost of labor that acts in the form of any earnings paid by the owner of the enterprise to an employee for the work performed':
    'Remuneration (wages) is the monetary expression of the value and cost of labor that acts in the form of any earnings paid by the owner of the enterprise to an employee for the work performed.',
  'As a socio-economic category, wages serve as the main means of meeting the personal needs of workers, an economic lever that stimulates the development of social production, the growth of labor productivity, and the reduction of production costs':
    'As a socio-economic category, wages serve as the main means of meeting the personal needs of workers, an economic lever that stimulates the development of social production, the growth of labor productivity, and the reduction of production costs.',
  'Typical risks:': 'Typical risks:',
  '– receiving an employee of the Inspectorate who was not entitled to payments;':
    '– receiving an employee of the Inspectorate who was not entitled to payments;',
  '– a selective (privileged or biased) approach in determining tariffs, salaries, allowances, surcharges, bonuses, and other incentive and compensation payments':
    '– a selective (privileged or biased) approach in determining tariffs, salaries, allowances, surcharges, bonuses, and other incentive and compensation payments.',
  'Recommended remedies:': 'Recommended remedies:',
  '– to comply with officials in determining tariff rates, salaries, allowances, surcharges, bonuses of other incentive and compensation payments of the organizational and administrative documents of the Inspectorate, as well as the current legislation of Ukraine;':
    '– to comply with officials in determining tariff rates, salaries, allowances, surcharges, bonuses of other incentive and compensation payments of the organizational and administrative documents of the Inspectorate, as well as the current legislation of Ukraine;',
  '– periodically record the working time of employees (stay in the workplace)':
    '– periodically record the working time of employees (stay in the workplace).',

  '5.11. RESOURCES MANAGEMENT': '5.11. RESOURCES MANAGEMENT',
  'Resource management is the use and disposal of Inspection officials':
    'Resource management is the use and disposal of Inspection officials.',
  '5.11.1. Use of material resources': '5.11.1. Use of material resources',
  'Typical risks:': 'Typical risks:',
  '– use of the Inspectorate’s resources (communications, office equipment, transport, etc.) for its own purposes, including for the purpose of obtaining undue advantage;':
    '– use of the Inspectorate’s resources (communications, office equipment, transport, etc.) for its own purposes, including for the purpose of obtaining undue advantage;',
  '– the use by the Inspectorate of its resources for profit through the use of its own enterprises (corporate rights);':
    '– the use by the Inspectorate of its resources for profit through the use of its own enterprises (corporate rights);',
  '– appropriation by the employee of material resources of the Inspection;':
    '– appropriation by the employee of material resources of the Inspection;',
  '– non-return of the resources of the enterprise received for personal use (for storage) after dismissal':
    '– non-return of the resources of the enterprise received for personal use (for storage) after dismissal.',
  'Recommended remedies:': 'Recommended remedies:',
  '– to warn employees about the inadmissibility of using material resources of the Inspectorate for other purposes not caused by official necessity;':
    '– to warn employees about the inadmissibility of using material resources of the Inspectorate for other purposes not caused by official necessity;',
  '– ensure compliance with the rules for the use of individual resources, such as communications, vehicles, etc.;':
    '– ensure compliance with the rules for the use of individual resources, such as communications, vehicles, etc.;',
  '– to impose a ban on the personal one-person solution of the issues of allocation (transfer) of material resources of the Inspectorate for his personal use (for storage);':
    '– to impose a ban on the personal one-person solution of the issues of allocation (transfer) of material resources of the Inspectorate for his personal use (for storage);',
  '– to account for the resources provided (transferred) to the officials of the Inspectorate for personal use (for storage), to carry out periodic checks of such resources and the needs for their further personal use (storage);':
    '– to account for the resources provided (transferred) to the officials of the Inspectorate for personal use (for storage), to carry out periodic checks of such resources and the needs for their further personal use (storage);',
  '– to ensure steady return of material resources of the enterprise allocated (transferred) to officials of the Inspectorate for use (for storage) upon dismissal':
    '– to ensure steady return of material resources of the enterprise allocated (transferred) to officials of the Inspectorate for use (for storage) upon dismissal.',
  '5.11.2. Management of material resources': '5.11.2. Management of material resources',
  'Typical risks:': 'Typical risks:',
  '– deliberate overstatement of orders for personal use;': '– deliberate overstatement of orders for personal use;',
  '– artificially lowering the operational characteristics of material resources in use for their subsequent write-off (disposal) or replacement;':
    '– artificially lowering the operational characteristics of material resources in use for their subsequent write-off (disposal) or replacement;',
  '– understating the value of the property to be written off (disposed of) with a view to its further acquisition':
    '– understating the value of the property to be written off (disposed of) with a view to its further acquisition.',
  'Recommended remedies:': 'Recommended remedies:',
  '– ensure that persons entrusted with the authority over the management of the material resources of the Inspection are held accountable for the failure or improper performance of the duties of management and disposal of property;':
    '– ensure that persons entrusted with the authority over the management of the material resources of the Inspection are held accountable for the failure or improper performance of the duties of management and disposal of property;',
  '– to carry out checks of available material resources with the ones specified in the acquisition and write-off (disposal) information;':
    '– to carry out checks of available material resources with the ones specified in the acquisition and write-off (disposal) information;',
  '– periodically inventory and evaluate material resources':
    '– periodically inventory and evaluate material resources.',

  CONCLUSIONS: 'CONCLUSIONS',
  'Based on the analysis of the activities of the officials of the Inspectorate, a number of possible corruption risks were identified and recommendations were given on how to eliminate them':
    'Based on the analysis of the activities of the officials of the Inspectorate, a number of possible corruption risks were identified and recommendations were given on how to eliminate them.',
  'The following procedure for carrying out corruption risk assessment is proposed:':
    'The following procedure for carrying out corruption risk assessment is proposed:',
  '– consideration of the activities of the Inspectorate in the form of separate processes (for example: relations with regulatory authorities; participation in public procurement;':
    '– consideration of the activities of the Inspectorate in the form of separate processes (for example: relations with regulatory authorities; participation in public procurement;',
  '– relations with suppliers and contractors, etc.), in each of which analyze and distinguish components (subprocesses);':
    '– relations with suppliers and contractors, etc.), in each of which analyze and distinguish components (subprocesses);',
  '– identification of persons implementing each specific process and sub-process (“process owners”), as well as persons exercising independent control over the implementation of such processes (“controllers”) (for example: approval of decisions by the head of another department of the Inspectorate, lawyer, accountant; sighting documents, etc.);':
    '– identification of persons implementing each specific process and sub-process (“process owners”), as well as persons exercising independent control over the implementation of such processes (“controllers”) (for example: approval of decisions by the head of another department of the Inspectorate, lawyer, accountant; sighting documents, etc.);',
  '– determining the degree of risk for each process and sub-process in which the occurrence of corruption offenses is most likely':
    '– determining the degree of risk for each process and sub-process in which the occurrence of corruption offenses is most likely.',
  'Determining the degree of risk is desirable to indicate: “minimally risky”; “Medium risk”; “Potentially risky”; “risky”; “Extremely risky” (requires maximum level of control)':
    'Determining the degree of risk is desirable to indicate: “minimally risky”; “Medium risk”; “Potentially risky”; “risky”; “Extremely risky” (requires maximum level of control).',
  'Additionally, for each subprocess whose implementation involves corruption risks, a description of possible corruption offenses should be provided, including:':
    'Additionally, for each subprocess whose implementation involves corruption risks, a description of possible corruption offenses should be provided, including:',
  '– a description of the unlawful benefits or benefits that may be obtained by individual employees of the Inspectorate as a result of committing a corruption offense;':
    '– a description of the unlawful benefits or benefits that may be obtained by individual employees of the Inspectorate as a result of committing a corruption offense;',
  '– positions in the organization that are “key” to the potential commission of a corruption offense, ie participation of which is necessary to make the commission of a corruption offense possible (for example, persons responsible for managing funds for representation expenses, giving gifts on behalf of the Inspectorate, etc.);':
    '– positions in the organization that are “key” to the potential commission of a corruption offense, ie participation of which is necessary to make the commission of a corruption offense possible (for example, persons responsible for managing funds for representation expenses, giving gifts on behalf of the Inspectorate, etc.);',
  '– possible forms of committing corruption offenses (making payments, providing tangible assets in the form of gifts, use of property)':
    '– possible forms of committing corruption offenses (making payments, providing tangible assets in the form of gifts, use of property).',
  'The ways of minimizing corruption risks in the activities of the Inspection officials are:':
    'The ways of minimizing corruption risks in the activities of the Inspection officials are:',

  'analysis of administrative processes in the Inspectorate for corruption and preparation of proposals for their elimination or minimization;':
    'analysis of administrative processes in the Inspectorate for corruption and preparation of proposals for their elimination or minimization;',
  'assessing the effectiveness of the Inspectorate’s anti-corruption policy;':
    'assessing the effectiveness of the Inspectorate’s anti-corruption policy;',
  'conducting surveys, questionnaires and other sociological research methods in order to identify corruption risks and violations of the rules of ethical conduct in the activity of the Inspectorate;':
    'conducting surveys, questionnaires and other sociological research methods in order to identify corruption risks and violations of the rules of ethical conduct in the activity of the Inspectorate;',
  'organization of trainings, practical exercises on anticorruption topics;':
    'organization of trainings, practical exercises on anticorruption topics;',
  'organization of fruitful interaction with the public, international organizations in the field of preventing and combating corruption;':
    'organization of fruitful interaction with the public, international organizations in the field of preventing and combating corruption;',
  'forming and periodically updating the list of positions of the Inspectorate with the highest risks of committing corruption offenses or violations of ethical rules;':
    'forming and periodically updating the list of positions of the Inspectorate with the highest risks of committing corruption offenses or violations of ethical rules;',
  'to take other measures to form the perception and condemnation by employees of any manifestations of corruption in the Inspectorate':
    'to take other measures to form the perception and condemnation by employees of any manifestations of corruption in the Inspectorate.',

  'Actual plan for the prevention and counteraction of corruption offenses':
    'Actual plan for the prevention and counteraction of corruption offenses',
  'Action plan': 'Action plan',

  'Lay for anti-corruption verification of business partners':
    'Lay for anti-corruption verification of business partners',

  '1. This Regulation defines the criteria and procedure for the selection of business partners for the various fields of activity of the Inspectorate for Training and Certification of Seafarers (hereinafter – the Inspection)':
    '1. This Regulation defines the criteria and procedure for the selection of business partners for the various fields of activity of the Inspectorate for Training and Certification of Seafarers (hereinafter – the Inspection)..',
  '2. The development of business relations with business partners determines the relevance of the problem of selecting the most suitable contractors of the Inspectorate, so its potential partners are selected according to criteria based on:':
    '2. The development of business relations with business partners determines the relevance of the problem of selecting the most suitable contractors of the Inspectorate, so its potential partners are selected according to criteria based on:',
  'transparency of production, economic and financial activities;':
    'transparency of production, economic and financial activities;',
  'fair competition among participants;': 'fair competition among participants;',
  'maximum savings and efficiency;': 'maximum savings and efficiency;',
  'openness and transparency at all stages of procurement;': 'openness and transparency at all stages of procurement;',
  'non-discrimination of participants;': 'non-discrimination of participants;',
  'an objective and impartial evaluation of the tender proposals;':
    'an objective and impartial evaluation of the tender proposals;',
  'prevention of corruption and abuse;': 'prevention of corruption and abuse;',
  'quality of goods, works and services;': 'quality of goods, works and services;',
  'reliability and stability': 'reliability and stability.',
  '3. Choosing a business partner is an important part of the Inspectorate’s activities, which includes the following measures:':
    '3. Choosing a business partner is an important part of the Inspectorate’s activities, which includes the following measures:',
  '– identify a range of potential partners, including joint ventures, among those who hold a leading position in the field;':
    '– identify a range of potential partners, including joint ventures, among those who hold a leading position in the field;',
  '– analysis of indicators of financial position and business activity of enterprises, selection of potential partners and sending them a formal request with a proposal to participate in the procurement of goods / works / services;':
    '– analysis of indicators of financial position and business activity of enterprises, selection of potential partners and sending them a formal request with a proposal to participate in the procurement of goods / works / services;',
  '– Carrying out a comparative technical and economic evaluation of the commercial proposals, including the assessment of the competitiveness of products and the final choice of the partner':
    '– Carrying out a comparative technical and economic evaluation of the commercial proposals, including the assessment of the competitiveness of products and the final choice of the partner.',
  '4. To select and organize information about the business partner uses a wide range of materials that characterize it: data from periodicals, individual monographs, catalogs, prospectuses, anniversary and advertising publications of enterprises. It is useful to use the services of the Chamber of Commerce and many existing specialized companies to provide information services':
    '4. To select and organize information about the business partner uses a wide range of materials that characterize it: data from periodicals, individual monographs, catalogs, prospectuses, anniversary and advertising publications of enterprises. It is useful to use the services of the Chamber of Commerce and many existing specialized companies to provide information services.',
  '5. The sources for obtaining information about potential inspection partners are open source information, namely:':
    '5. The sources for obtaining information about potential inspection partners are open source information, namely:',
  '– banking or business information;': '– banking or business information;',
  '– balance sheet;': '– balance sheet;',
  '– income statement;': '– income statement;',
  '– statement of changes in financial position;': '– statement of changes in financial position;',
  '– a report on the sources and uses of fixed and current assets':
    '– a report on the sources and uses of fixed and current assets.',

  '6. The inspection establishes the criteria for the procurement procedure (hereinafter referred to as the tenderers) and decides on the refusal of the tenderer to participate in the procurement procedure, and is obliged to reject the tenderer’s tender if:':
    '– there is conclusive evidence that the participant offers, gives or agrees to give directly or indirectly to any official of the Inspectorate, any other public authority in any form (employment offer, valuable item, service, etc.) in order to influence acceptance the decision to determine the winner of the procurement procedure or the use by the customer of a specific procurement procedure;',
  '– information about a legal entity that is a participant, entered in the Unified State Register of persons who committed corruption or corruption-related offenses;':
    '– information about a legal entity that is a participant, entered in the Unified State Register of persons who committed corruption or corruption-related offenses;',
  '– the official (official) of the participant who is authorized by the participant to represent his interests during the procurement procedure;':
    '– the official (official) of the participant who is authorized by the participant to represent his interests during the procurement procedure;',
  '– the entity (participant) for the past three years has been held liable for the violation provided for in paragraph 4 of part 2 of Article 6, paragraph 1 of Article 50 of the Law of Ukraine “On Protection of Economic Competition” (tenders);':
    '– the entity (participant) for the past three years has been held liable for the violation provided for in paragraph 4 of part 2 of Article 6, paragraph 1 of Article 50 of the Law of Ukraine “On Protection of Economic Competition” (tenders);',
  '– a natural person who is a participant has been convicted of a crime committed on the basis of self-serving motives, the conviction of which has not been withdrawn or repaid in the manner prescribed by law;':
    '– a natural person who is a participant has been convicted of a crime committed on the basis of self-serving motives, the conviction of which has not been withdrawn or repaid in the manner prescribed by law;',
  '– the official (official) of the tenderer who has signed the tender offer has been convicted of a crime committed for selfish reasons, the conviction of which has not been withdrawn or repaid in accordance with the procedure established by law;':
    '– the official (official) of the tenderer who has signed the tender offer has been convicted of a crime committed for selfish reasons, the conviction of which has not been withdrawn or repaid in accordance with the procedure established by law;',
  '– the tender offer is submitted by the participant of the procurement procedure, who is a related party with other participants of the procurement procedure and / or with the member (s) of the tender committee authorized by the person (s) of the contracting authority;':
    '– the tender offer is submitted by the participant of the procurement procedure, who is a related party with other participants of the procurement procedure and / or with the member (s) of the tender committee authorized by the person (s) of the contracting authority;',
  '– the participant is declared bankrupt by the statutory procedure and the liquidation procedure is opened against him;':
    '– the participant is declared bankrupt by the statutory procedure and the liquidation procedure is opened against him;',
  '– there is no information in the Unified State Register of Legal Entities, Individuals – Entrepreneurs and Public Formations provided for in paragraph 9 of Part 2 of Article 9 of the Law of Ukraine “On State Registration of Legal Entities':
    '– there is no information in the Unified State Register of Legal Entities, Individuals – Entrepreneurs and Public Formations provided for in paragraph 9 of Part 2 of Article 9 of the Law of Ukraine “On State Registration of Legal Entities',
  '-Individuals – Entrepreneurs and Public Formations': '- Individuals – Entrepreneurs and Public Formations;',
  '– the participating legal entity does not have an anti-corruption program or an anti-corruption program commissioner if the cost of purchasing the goods (goods), services (services) or works is equal to or exceeds UAH 20 million;':
    '– the participating legal entity does not have an anti-corruption program or an anti-corruption program commissioner if the cost of purchasing the goods (goods), services (services) or works is equal to or exceeds UAH 20 million;',
  '– the participant has arrears of taxes and fees (compulsory payments)':
    '– the participant has arrears of taxes and fees (compulsory payments).',
  '7. The Inspectorate’s Corruption Prevention and Detection Officer (hereinafter – “the Commissioner”) conducts an anti-corruption audit of the Inspectorate’s existing or potential counterparties to assess the existence of corruption risks':
    '7. The Inspectorate’s Corruption Prevention and Detection Officer (hereinafter – “the Commissioner”) conducts an anti-corruption audit of the Inspectorate’s existing or potential counterparties to assess the existence of corruption risks.',
  '8. The Commissioner checks whether the business partner has the reputation of an entity whose activities are related to corruption (even in the absence of appropriate court decisions), and whether the business partner will be used as an intermediary for the transfer to third parties (or to obtain from third parties) of unlawful benefits':
    '8. The Commissioner checks whether the business partner has the reputation of an entity whose activities are related to corruption (even in the absence of appropriate court decisions), and whether the business partner will be used as an intermediary for the transfer to third parties (or to obtain from third parties) of unlawful benefits.',
  '9. Anti-corruption checks are carried out in accordance with the requirements of the Anti-Corruption Program of the Inspectorate, as well as standards for various fields of activity of the Inspectorate':
    '9. Anti-corruption checks are carried out in accordance with the requirements of the Anti-Corruption Program of the Inspectorate, as well as standards for various fields of activity of the Inspectorate.',
  '10. Based on the results of anti-corruption inspection of the business partner of the Inspection, the Commissioner makes a written recommendation to the Chief of Inspection in any form':
    '10. Based on the results of anti-corruption inspection of the business partner of the Inspection, the Commissioner makes a written recommendation to the Chief of Inspection in any form.',
  '11. In case of a negative recommendation of the Commissioner for continuation or commencement of legal relations with such a business partner, the Head of the Inspection should make a reasonable decision on this matter':
    '11. In case of a negative recommendation of the Commissioner for continuation or commencement of legal relations with such a business partner, the Head of the Inspection should make a reasonable decision on this matter.',
  '12. The materials of the anti-corruption check of the business partners of the Inspection shall be kept for at least 5 years':
    '12. The materials of the anti-corruption check of the business partners of the Inspection shall be kept for at least 5 years.',

  'Inform us about all known the facts of bribery, corruption and other unlawful actions of the employees of the Inspection':
    'Inform us about all known the facts of bribery, corruption and other unlawful actions of the employees of the Inspection',
  'You can report all known facts of bribery, corruption and other illegal actions of the Inspectorate staff by phone:':
    'You can report all known facts of bribery, corruption and other illegal actions of the Inspectorate staff by phone:',
  Tel: 'Tel',
  Mob: 'Mob',
  'day and night': 'day and night',
  'OFFICIAL AGAINST ANTI-CORRUPTION ACTIVITIES:': 'OFFICIAL AGAINST ANTI-CORRUPTION ACTIVITIES:',
  'Velykan Valery Nikolaevich': 'Velykan Valery Nikolaevich',
  'or send a written request to:': 'or send a written request to:',
  '04080, Kyiv, street Olenivska, 25': '04080, Kyiv, street Olenivska, 25,',
  'or send an e-mail to:': 'or send an e-mail to:',

  "The Inspectorate for Training and Certification of Seafarers introducing the web version of the 'Seafarer's Electronic Cabinet'! The personal cabinet is now available in your browser, with the aim of simplifying the process of document processing and ensuring convenient self-service":
    "The Inspectorate for Training and Certification of Seafarers introducing the web version of the 'Seafarer's Electronic Cabinet'! The personal cabinet is now available in your browser, with the aim of simplifying the process of document processing and ensuring convenient self-service.",
  "The new personal cabinet is accessible on all devices that support web browsers and includes all the features of the mobile app 'Seafarer's Electronic Cabinet':":
    "The new personal cabinet is accessible on all devices that support web browsers and includes all the features of the mobile app 'Seafarer's Electronic Cabinet':",
  'Take advantage of all the benefits of digital document circulation, which significantly simplifies document processing':
    'Take advantage of all the benefits of digital document circulation, which significantly simplifies document processing.',
  'View information about all your available documents, allowing you to easily navigate their status and validity periods':
    'View information about all your available documents, allowing you to easily navigate their status and validity periods.',
  'Submit applications for government services with just a few clicks, saving time and effort':
    'Submit applications for government services with just a few clicks, saving time and effort.',
  'Remotely manage your work documents, getting the ability to edit and supplement them from any device with internet access':
    'Remotely manage your work documents, getting the ability to edit and supplement them from any device with internet access.',
  'Now you can easily access all services on any device with the Internet, greatly simplifying the lives of seafarers and allowing them to focus on their main professional activities':
    'Now you can easily access all services on any device with the Internet, greatly simplifying the lives of seafarers and allowing them to focus on their main professional activities.',
  'Take advantage of the personal cabinet today and see for yourself its convenience and efficiency!':
    'Take advantage of the personal cabinet today and see for yourself its convenience and efficiency!',
  Login: 'Login',
  Back: 'Back',
  'Last news': 'Last news',
  'Since the imposing of martial law, the Inspectorate for Training and Certification of Seafarers has not stopped its activities and continues to provide services to Seafarers. State Qualification Commissions continue to hold meetings in the cities of Odessa and Izmail. At the addresses of the Odessa and Izmail branches of Morrichservis service centers, sailors can pass a computer test to confirm their qualifications by using the Crew Evaluation system (CES) 6.0 test or an AST test in accordance with applications that were submitted before 02.21.2022 and receive the relevant SQC protocols based on the results of testing':
    'Since the imposing of martial law, the Inspectorate for Training and Certification of Seafarers has not stopped its activities and continues to provide services to Seafarers. State Qualification Commissions continue to hold meetings in the cities of Odessa and Izmail. At the addresses of the Odessa and Izmail branches of Morrichservis service centers, sailors can pass a computer test to confirm their qualifications by using the Crew Evaluation system (CES) 6.0 test or an AST test in accordance with applications that were submitted before 02.21.2022 and receive the relevant SQC protocols based on the results of testing',
  'Re-confirmation of the diploma without passing an exam in the State Qualification Commission, if there is confirmed work experience on ships with the performance of functions corresponding to the diploma, which is confirmed, as well as compliance with other requirements regulated by Article 51 of the Merchant Shipping Code of Ukraine, is carried out in the usual mode, taking into account the restrictions and prohibitions established by martial law':
    'Re-confirmation of the diploma without passing an exam in the State Qualification Commission, if there is confirmed work experience on ships with the performance of functions corresponding to the diploma, which is confirmed, as well as compliance with other requirements regulated by Article 51 of the Merchant Shipping Code of Ukraine, is carried out in the usual mode, taking into account the restrictions and prohibitions established by martial law',
  "The issuance of Seaman's Seagoing Service Record Books is also continuing, but this service can only be obtained at the Morrichservis service centers in the Odessa and Izmail, since courier delivery in the country is difficult":
    "The issuance of Seaman's Seagoing Service Record Books is also continuing, but this service can only be obtained at the Morrichservis service centers in the Odessa and Izmail, since courier delivery in the country is difficult",

  'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority':
    'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority.',
  'The Inspectorate for Training and Certification of Seafarers introducing the web version of the "Seafarer\'s Electronic Cabinet"! The personal cabinet is now available in your browser, with the aim of simplifying the process of document processing and ensuring convenient self-service':
    'The Inspectorate for Training and Certification of Seafarers introducing the web version of the "Seafarer\'s Electronic Cabinet"! The personal cabinet is now available in your browser, with the aim of simplifying the process of document processing and ensuring convenient self-service',
  'Since the imposing of martial law, the Inspectorate for Training and Certification of Seafarers has not stopped its activities and continues to provide services to Seafarers':
    'Since the imposing of martial law, the Inspectorate for Training and Certification of Seafarers has not stopped its activities and continues to provide services to Seafarers.',
  'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority':
    'Due to numerous appeals of sailors, all AST Test applications received before February 21, 2022 will be considered an order of priority.',

  'CES testing will be the only way to confirm the qualification of seafarers':
    'CES testing will be the only way to confirm the qualification of seafarers',
  'Starting from February 21, 2022, applications for computer testing to confirm qualifications, as well as confirmation of work experience on the ship, are accepted only for the Norwegian Crew Evaluation system (CES) 6.0 test':
    'Starting from February 21, 2022, applications for computer testing to confirm qualifications, as well as confirmation of work experience on the ship, are accepted only for the Norwegian Crew Evaluation system (CES) 6.0 test.',
  'Applications for the AST Test received before February 21, 2022 will be considered an order of priority. Switching exclusively to CES testing from the beginning was our top priority. However, despite the reaction of the industry, in November 2021 we made a consensus decision — to implement the CES test, leaving the sailors the right to choose between CES and the Ukrainian AST Test':
    'Applications for the AST Test received before February 21, 2022 will be considered an order of priority. Switching exclusively to CES testing from the beginning was our top priority. However, despite the reaction of the industry, in November 2021 we made a consensus decision — to implement the CES test, leaving the sailors the right to choose between CES and the Ukrainian AST Test.',
  "Today we can safely talk about the positive reaction of Ukrainian sailors to the Norwegian test. Because it is a transparent tool that completely excludes any corruption opportunities. Unfortunately, we can't say the same about the AST Test":
    "Today we can safely talk about the positive reaction of Ukrainian sailors to the Norwegian test. Because it is a transparent tool that completely excludes any corruption opportunities. Unfortunately, we can't say the same about the AST Test.",
  'Ukraine is among the top 5 countries in the world by the number of sailors. Taking into account the level of training, knowledge and generally high professional qualities of our sailors, we are confident that the industry is ready to move to international procedures for confirming work experience. The choice of CES testing further integrates Ukraine into the system of modern European anti-corruption practices, - says Oleksandr Kubrakov, Minister of Infrastructure of Ukraine':
    'Ukraine is among the top 5 countries in the world by the number of sailors. Taking into account the level of training, knowledge and generally high professional qualities of our sailors, we are confident that the industry is ready to move to international procedures for confirming work experience. The choice of CES testing further integrates Ukraine into the system of modern European anti-corruption practices, - says Oleksandr Kubrakov, Minister of Infrastructure of Ukraine.',
  'Trial testing': 'Trial testing',
  'Also, since February 1, Morrechservis service centers have introduced a CES test trial service. It makes it possible to pass testing in conditions similar to this exam. The number of test attempts is unlimited and the results are generated automatically immediately after completion. 24 sailors have already used the service as of February 11':
    'Also, since February 1, Morrechservis service centers have introduced a CES test trial service. It makes it possible to pass testing in conditions similar to this exam. The number of test attempts is unlimited and the results are generated automatically immediately after completion. 24 sailors have already used the service as of February 11.',
  'About the Crew Evaluation System': 'About the Crew Evaluation System',
  'The CES 6.0 computer testing system was developed by the Norwegian company Seagull Maritime AS. The CES test is one of the most widely used in the world and has been used for more than 25 years in more than 350 global companies. The system contains more than 6,500 multiple-choice questions and complies with the standards of the STCW Convention. Recall that since the launch of CES testing, 201 exams have been conducted, of which 128 were passed by sailors successfully':
    'The CES 6.0 computer testing system was developed by the Norwegian company Seagull Maritime AS. The CES test is one of the most widely used in the world and has been used for more than 25 years in more than 350 global companies. The system contains more than 6,500 multiple-choice questions and complies with the standards of the STCW Convention. Recall that since the launch of CES testing, 201 exams have been conducted, of which 128 were passed by sailors successfully.',
  'The license to use the CES electronic testing system was granted with the support of the USAID/UK aid Project "Transparency and accountability in public administration and services/TAPAS"':
    'The license to use the CES electronic testing system was granted with the support of the USAID/UK aid Project "Transparency and accountability in public administration and services/TAPAS".',

  'Information of documents processing for seafarers': 'Information of documents processing for seafarers',
  "The Security Service of Ukraine exposed the illegal scheme of processing approval documents for sailors in July 2021. Employees of the Security Service of Ukraine seized the servers that served the State Register of Seafarers' Documents (hereinafter the Register) from private individuals and transferred them to state control":
    "The Security Service of Ukraine exposed the illegal scheme of processing approval documents for sailors in July 2021. Employees of the Security Service of Ukraine seized the servers that served the State Register of Seafarers' Documents (hereinafter the Register) from private individuals and transferred them to state control.",
  'Based on the cooperative work results of specialists from the Ministry of Infrastructure of Ukraine and the ITCS, was launched the operation of information systems related to electronic services for seafarers and was resumed work of the official information resource of the Inspectorate on the website: https://itcs.gov.ua since 04.11.2021':
    'Based on the cooperative work results of specialists from the Ministry of Infrastructure of Ukraine and the ITCS, was launched the operation of information systems related to electronic services for seafarers and was resumed work of the official information resource of the Inspectorate on the website: https://itcs.gov.ua since 04.11.2021.',
  'Now The Eurasia Foundation, the executor of the Transparency and Accountability in Public Administration and Services/TAPAS project, donored by the US Government through the USAID and the UK government (UK Aid), is holding a competition to identify a potential executor/executors to develop e-services in the field of certification of seafarers on the Diia portal and the first stage of the certification module for seafarers':
    'Now The Eurasia Foundation, the executor of the Transparency and Accountability in Public Administration and Services/TAPAS project, donored by the US Government through the USAID and the UK government (UK Aid), is holding a competition to identify a potential executor/executors to develop e-services in the field of certification of seafarers on the Diia portal and the first stage of the certification module for seafarers.',
  'With the introduction of the new system by the Inspectorate and the Ministry of Infrastructure, the implemented software (hereinafter the Automated System), which is an integral part of the Register and does not allow stopping the process of certification of Ukrainian seafarers, does not allow officials or unauthorized persons to change any information about the Seafarer':
    'With the introduction of the new system by the Inspectorate and the Ministry of Infrastructure, the implemented software (hereinafter the Automated System), which is an integral part of the Register and does not allow stopping the process of certification of Ukrainian seafarers, does not allow officials or unauthorized persons to change any information about the Seafarer.',
  'Fulfilling the Decree of the Cabinet of Ministers of Ukraine dated June 30, 2021 No. 734 “On the implementation of a pilot project for conducting computer testing exam for confirmation the qualifications of seafarers”, the Crew Evaluation System (CES) 6.0 by Seagull Maritime AS was launched. According to Article 51 of the Merchant Shipping Code of Ukraine, it is possible to obtain documents based on experience':
    'Fulfilling the Decree of the Cabinet of Ministers of Ukraine dated June 30, 2021 No. 734 “On the implementation of a pilot project for conducting computer testing exam for confirmation the qualifications of seafarers”, the Crew Evaluation System (CES) 6.0 by Seagull Maritime AS was launched. According to Article 51 of the Merchant Shipping Code of Ukraine, it is possible to obtain documents based on experience.',
  '137,243 users were registered in the system on January 17, 2022, and a thousand documents are processed on average per day (digitized/verified). Since resumption of the system, which started at 04.11.2021, 4,616 people have taken exams at State Qualification Commissions, of which 3,786 people have successfully compiled and received working documents. Since November 24, 2021, 165 applications have been submitted for re-validation of the diploma based on the length of service, of which 120 people received confirmation. Since November 24, 2021, 165 applications have been submitted for recertification of the diploma based on experience, of which 120 people received endorsements':
    '137,243 users were registered in the system on January 17, 2022, and a thousand documents are processed on average per day (digitized/verified). Since resumption of the system, which started at 04.11.2021, 4,616 people have taken exams at State Qualification Commissions, of which 3,786 people have successfully compiled and received working documents. Since November 24, 2021, 165 applications have been submitted for re-validation of the diploma based on the length of service, of which 120 people received confirmation. Since November 24, 2021, 165 applications have been submitted for recertification of the diploma based on experience, of which 120 people received endorsements.',
  'During the system outage from July 2021 to November 2021 and paper filing, only 334 people got on the SQC, of which only 63 people received working documents':
    'During the system outage from July 2021 to November 2021 and paper filing, only 334 people got on the SQC, of which only 63 people received working documents.',

  'Season’s greetings, dear sailors!': 'Season’s greetings, dear sailors!',
  'This year was full of difficult challenges that led to positive and significant changes in the field of training and certification of seafarers':
    'This year was full of difficult challenges that led to positive and significant changes in the field of training and certification of seafarers.',
  'We responsibly declare that despite all the difficulties and challenges facing us, we will continue to confidently move towards overcoming corruption by introducing simple and transparent services that will fully satisfy your request for quality Inspection services':
    'We responsibly declare that despite all the difficulties and challenges facing us, we will continue to confidently move towards overcoming corruption by introducing simple and transparent services that will fully satisfy your request for quality Inspection services.',
  'At the same time, we would like to express our deep respect to all those who have connected their lives with the sea, who faithfully serve the maritime industry, demonstrating courage, endurance, dedication and unity':
    'At the same time, we would like to express our deep respect to all those who have connected their lives with the sea, who faithfully serve the maritime industry, demonstrating courage, endurance, dedication and unity.',
  'We wish that 2022 will certainly become a Year of good changes for you and your family! We wish you peace, harmony, kindness, warmth and comfort in your homes, mutual understanding and support in families, fulfillment of wishes, aspirations and achievements':
    'We wish that 2022 will certainly become a Year of good changes for you and your family! We wish you peace, harmony, kindness, warmth and comfort in your homes, mutual understanding and support in families, fulfillment of wishes, aspirations and achievements.',
  'Happy New Year and Merry Christmas!': 'Happy New Year and Merry Christmas!',
  'Best regards': 'Best regards',
  'Inspectorate for the Training and Certification of Seafarers':
    'Inspectorate for the Training and Certification of Seafarers',

  'The thorny path of overcoming corruption that the Inspectorate has traveled with seafarers in 2021':
    'The thorny path of overcoming corruption that the Inspectorate has traveled with seafarers in 2021',
  'Dear seafarers!': 'Dear seafarers!',
  'Today, there is a consistent and well-planned activity on the part of some non-state organizations aimed at destabilizing certain areas of the maritime industry, in particular the training and certification of seafarers':
    'Today, there is a consistent and well-planned activity on the part of some non-state organizations aimed at destabilizing certain areas of the maritime industry, in particular the training and certification of seafarers.',
  'At the same time, persons who for years have been engaged in intermediary services and received unjustified benefits from seafarers, publish dirty articles on numerous Internet resources, on behalf of thousands of seafarers write groundless complaints against the officials of the The Inspectorate for training and certification of seafarers (further ITCS), Ministry of Infrastructure of Ukraine (further MTU), is incited to participate in rallies with calls to return the old system of diplomas, namely the reception and issuance of documents of seafarers in paper form':
    'At the same time, persons who for years have been engaged in intermediary services and received unjustified benefits from seafarers, publish dirty articles on numerous Internet resources, on behalf of thousands of seafarers write groundless complaints against the officials of the The Inspectorate for training and certification of seafarers (further ITCS), Ministry of Infrastructure of Ukraine (further MTU), is incited to participate in rallies with calls to return the old system of diplomas, namely the reception and issuance of documents of seafarers in paper form.',
  "Maybe it was convenient for some seafarers to work with the agents, because the latter were able to help them for bribes to manually enter into the Registry of Seafarers' Documents the data of fake diplomas, certificates and add their seniority":
    "Maybe it was convenient for some seafarers to work with the agents, because the latter were able to help them for bribes to manually enter into the Registry of Seafarers' Documents the data of fake diplomas, certificates and add their seniority.",
  'However, this issue has been resolved by the software «Automated Seafarer Competence Assessment System» implemented by ITCS and MTU. The system is built in such a way that it does not allow officials or unauthorized persons to delete, add or change any information about seafarers at their discretion':
    'However, this issue has been resolved by the software «Automated Seafarer Competence Assessment System» implemented by ITCS and MTU. The system is built in such a way that it does not allow officials or unauthorized persons to delete, add or change any information about seafarers at their discretion.',
  'This software, located on a public resource, is under the full control of the IPDM and IIM':
    'This software, located on a public resource, is under the full control of the IPDM and IIM.',
  'Thanks to automated processes and a special protection system, the possibility of interference with the information base by unauthorized persons is eliminated':
    'Thanks to automated processes and a special protection system, the possibility of interference with the information base by unauthorized persons is eliminated.',
  "Mobile application «Seafarer's office» is a part of the automated system. Using the application a seafarer can upload his own documents or, alternatively, «Marine Service», which is an authorized company which enters the seafarer's documents into the unified State Register of seafarers' documents in a digital format":
    "Mobile application «Seafarer's office» is a part of the automated system. Using the application a seafarer can upload his own documents or, alternatively, «Marine Service», which is an authorized company which enters the seafarer's documents into the unified State Register of seafarers' documents in a digital format.",
  "Seafarers also receive all other documents electronically. Thus, after training at the training centers, the relevant certificates are registered in the system directly by the educational institution itself and appear in the Seafarer's Cabinet instantly. Having passed the exam, the seafarer receives working documents in the application without communication and queues. Crewing companies can independently, without bribes, obtain the seafarer's data necessary for employment only with the seafarer's consent":
    "Seafarers also receive all other documents electronically. Thus, after training at the training centers, the relevant certificates are registered in the system directly by the educational institution itself and appear in the Seafarer's Cabinet instantly. Having passed the exam, the seafarer receives working documents in the application without communication and queues. Crewing companies can independently, without bribes, obtain the seafarer's data necessary for employment only with the seafarer's consent.",
  'Please note that all actions in the system and display of information is possible only with the consent of the seafarer and can not be used by third parties without consent. That is, the whole process of training and qualification is automated and built in such a way as to exclude interference of any third parties. As a consequence, the institute of intermediary agents, who for many years benefited illegally from seafarers, and now are trying to destabilize the process of eliminating the corruption components, is completely eradicated':
    'Please note that all actions in the system and display of information is possible only with the consent of the seafarer and can not be used by third parties without consent. That is, the whole process of training and qualification is automated and built in such a way as to exclude interference of any third parties. As a consequence, the institute of intermediary agents, who for many years benefited illegally from seafarers, and now are trying to destabilize the process of eliminating the corruption components, is completely eradicated.',
  'Also as an alternative, the CES pilot project was introduced, which operates worldwide and has proven itself at a high level':
    'Also as an alternative, the CES pilot project was introduced, which operates worldwide and has proven itself at a high level.',
  'We note responsibly that unauthorized interference in the operation of CES and the automated seafarer competence evaluation system is impossible. Both systems are under state control, which rules out outside interference from any third party':
    'We note responsibly that unauthorized interference in the operation of CES and the automated seafarer competence evaluation system is impossible. Both systems are under state control, which rules out outside interference from any third party.',
  'We appeal to all conscientious seafarers and ask them not to use the services of intermediary agents. Given the system in place, they can neither personally nor through officials influence the situation and the results of training and graduation':
    'We appeal to all conscientious seafarers and ask them not to use the services of intermediary agents. Given the system in place, they can neither personally nor through officials influence the situation and the results of training and graduation.',
  'Despite numerous slanders and provocations, we hope for your, dear seafarers, conscious attitude to the modernization and digitalization of the process, ITCS and confident movement towards overcoming corruption':
    'Despite numerous slanders and provocations, we hope for your, dear seafarers, conscious attitude to the modernization and digitalization of the process, ITCS and confident movement towards overcoming corruption.',
  'With respect to every seafarer, The Inspectorate for training and certification of seafarers':
    'With respect to every seafarer, The Inspectorate for training and certification of seafarers',

  'Topical issues of interaction between the Inspectorate and representatives of crewing companies at the meeting in the Ministry of Infrastructure of Ukraine':
    'Topical issues of interaction between the Inspectorate and representatives of crewing companies at the meeting in the Ministry of Infrastructure of Ukraine',
  "On December 7, representatives of the Inspectorate for Training and Certification of Seafarers (hereinafter - the Inspectorate) took part in a meeting on topical issues of cooperation between state authorities and crewing companies, organized and held by the State Maritime and River Transport Service of Ukraine (hereinafter - Maritime Administration), chaired by Deputy Minister of Infrastructure of Ukraine Yuri Vaskov and Head of Maritime Administration Vitaly Kondrativ, and also involving representatives of SE «Morsuchservice», Association 'All-Ukrainian Association'":
    "On December 7, representatives of the Inspectorate for Training and Certification of Seafarers (hereinafter - the Inspectorate) took part in a meeting on topical issues of cooperation between state authorities and crewing companies, organized and held by the State Maritime and River Transport Service of Ukraine (hereinafter - Maritime Administration), chaired by Deputy Minister of Infrastructure of Ukraine Yuri Vaskov and Head of Maritime Administration Vitaly Kondrativ, and also involving representatives of SE «Morsuchservice», Association 'All-Ukrainian Association'",
  'During the meeting, the following issues were considered':
    'During the meeting, the following issues were considered',
  'reforming government services for seafarers with a focus on interaction with crewing companies;':
    'reforming government services for seafarers with a focus on interaction with crewing companies;',
  'integrated functioning of online services, taking into account the requirements of stakeholders;':
    'integrated functioning of online services, taking into account the requirements of stakeholders;',
  'the electronic documents comply with convention requirements and IMO norms;':
    'the electronic documents comply with convention requirements and IMO norms;',
  'introduction of amendments to some regulatory legal acts of the Ministry of Infrastructure of Ukraine, in terms of training and certification of seafarers':
    'introduction of amendments to some regulatory legal acts of the Ministry of Infrastructure of Ukraine, in terms of training and certification of seafarers.',
  'It should be noted that the Inspectorate has implemented the Module for crewing companies, which allows, upon agreement of the person to process personal data to use services related to the system of training and certification of seafarers online and provides access to the documents entered by the seafarer, pre-verified and digitized in the unified State Register of documents for seafarers':
    'It should be noted that the Inspectorate has implemented the Module for crewing companies, which allows, upon agreement of the person to process personal data to use services related to the system of training and certification of seafarers online and provides access to the documents entered by the seafarer, pre-verified and digitized in the unified State Register of documents for seafarers.',
  "Functioning of the Inspectorate's online services is a convenient and reliable tool for working with information in electronic form. Thanks to the implementation of these systems, Ukrainian seafarers will be provided with accessible and quality services online, and the crewing industry - optimization of processes":
    "Functioning of the Inspectorate's online services is a convenient and reliable tool for working with information in electronic form. Thanks to the implementation of these systems, Ukrainian seafarers will be provided with accessible and quality services online, and the crewing industry - optimization of processes.",

  'Starting a commercial API system for enterprises and organizations of the marine industry':
    'Starting a commercial API system for enterprises and organizations of the marine industry',
  'Dear representatives of commercial organizations of the maritime industry!':
    'Dear representatives of commercial organizations of the maritime industry!',
  'As a part of the program of digital transformation and building a digital state, the Inspectorate for Training and Certification of Seafarers developed and implemented on an industrial basis a new digital service - Commercial API System':
    'As a part of the program of digital transformation and building a digital state, the Inspectorate for Training and Certification of Seafarers developed and implemented on an industrial basis a new digital service - Commercial API System.',
  "This service is an application software interface of the unified State Register of Seafarers' Documents (the Register) which processes and transforms data provided from the Register and entered into it and provides their perception by automated and information systems of commercial organizations of the maritime industry, which needs automation of processes aimed at synchronization of data with the Register, which, as a result, will significantly improve the efficiency of services for seafarers":
    "This service is an application software interface of the unified State Register of Seafarers' Documents (the Register) which processes and transforms data provided from the Register and entered into it and provides their perception by automated and information systems of commercial organizations of the maritime industry, which needs automation of processes aimed at synchronization of data with the Register, which, as a result, will significantly improve the efficiency of services for seafarers.",
  'For all organizational and technical questions, please contact the Inspection for Training and Certification of Seafarers':
    'For all organizational and technical questions, please contact the Inspection for Training and Certification of Seafarers.',

  'The important steps of interaction between the state and business in the field of training and certification of seafarers':
    'The important steps of interaction between the state and business in the field of training and certification of seafarers',
  'Results of the meeting of the Deputy Minister for Digital Transformation, representatives of the Inspectorate and the State Service of Maritime and River Transport':
    'Results of the meeting of the Deputy Minister for Digital Transformation, representatives of the Inspectorate and the State Service of Maritime and River Transport.',
  'On November 30, representatives of the Inspectorate participated in a meeting on simplification of mechanisms and procedures for cooperation between the State and business in the field of training and certification of seafarers, organized and held by the State Service of Maritime and River Transport, chaired by the Deputy Minister of Infrastructure, Vaskov Y.Y':
    'On November 30, representatives of the Inspectorate participated in a meeting on simplification of mechanisms and procedures for cooperation between the State and business in the field of training and certification of seafarers, organized and held by the State Service of Maritime and River Transport, chaired by the Deputy Minister of Infrastructure, Vaskov Y.Y.',
  "Deputy Minister of Digital Development, Digital Transformation and Digitalization Komirnyi A.V., representatives of the Committee of work with employers in the field of maritime transport, educational and training institutions, State enterprise 'Marine Service', Association 'All-Ukrainian Association of Crewing Companies', public organization 'Ukrainian Association of Seafarers' and others also took part in the meeting":
    "Deputy Minister of Digital Development, Digital Transformation and Digitalization Komirnyi A.V., representatives of the Committee of work with employers in the field of maritime transport, educational and training institutions, State enterprise 'Marine Service', Association 'All-Ukrainian Association of Crewing Companies', public organization 'Ukrainian Association of Seafarers' and others also took part in the meeting.",
  'During the conference, the following issues related to the scope of training and qualification of seafarers were considered':
    'During the conference, the following issues related to the scope of training and qualification of seafarers were considered:',
  'improvement of the work of training institutions in terms of automating the process of creating and issuing training certificates':
    'improvement of the work of training institutions in terms of automating the process of creating and issuing training certificates;',
  'development of a fair and open cooperation, in terms of creating equal competitive conditions for doing business':
    'development of a fair and open cooperation, in terms of creating equal competitive conditions for doing business;',
  'ways of controlling the issuance of certificates by training centers':
    'ways of controlling the issuance of certificates by training centers;',
  'the issue of entering certificates into the unified state register of documents of seafarers, issued by training centers in the previous period':
    'the issue of entering certificates into the unified state register of documents of seafarers, issued by training centers in the previous period.',
  'The head of the legal support service, Parikozhka Alexei, separately noted that the offer agreement, which provides equal conditions for all participants of market relations in the field of training centers, was developed and placed on the official website in the section ‘registration of training centers’':
    'The head of the legal support service, Parikozhka Alexei, separately noted that the offer agreement, which provides equal conditions for all participants of market relations in the field of training centers, was developed and placed on the official website in the section ‘registration of training centers’.',
  "Director of the State Register of Seafarers' Documents of the Seafarers' Training and Certification Inspectorate, Shevchenko Konstantin, explained the future work process and answered more relevant questions on the functionality and ways of development of the unified state register of documents for seafarers in terms of interaction with training centers":
    "Director of the State Register of Seafarers' Documents of the Seafarers' Training and Certification Inspectorate, Shevchenko Konstantin, explained the future work process and answered more relevant questions on the functionality and ways of development of the unified state register of documents for seafarers in terms of interaction with training centers.",
  'Today, the Inspectorate for Training and Certification of Seafarers offers to use the service «NTZ online» — designed to optimize the process of issuing training certificates which means automated procedure for creating certificates, issuing online certificates, and implementation of the electronic verification of documents':
    'Today, the Inspectorate for Training and Certification of Seafarers offers to use the service «NTZ online» — designed to optimize the process of issuing training certificates which means automated procedure for creating certificates, issuing online certificates, and implementation of the electronic verification of documents.',
  'According to the results of the meeting, a number of decisions were made to improve the system, aimed at optimizing and simplifying the way to get relevant documents by the seafarers':
    'According to the results of the meeting, a number of decisions were made to improve the system, aimed at optimizing and simplifying the way to get relevant documents by the seafarers.',

  'Attention! New bank details for payment of inspection services are provided':
    'Attention! New bank details for payment of inspection services are provided',
  'We would like to inform you that starting from December 1, 2021, the Inspectorate for Training and Certification of Seafarers will switch payment methods to a new bank – PrivatBank CB JSC in order to improve the quality of services provided':
    'We would like to inform you that starting from December 1, 2021, the Inspectorate for Training and Certification of Seafarers will switch payment methods to a new bank – PrivatBank CB JSC in order to improve the quality of services provided.',
  "For the proper display in the accounting system and identification of the person-recipient it is necessary to comply with this format of filling in the purpose of payment. Besides, we are informing that it is possible to pay for services via mobile application 'Seaman's Cabinet' with the internet-acquiring (payment is made through Payment service provider Platon). To pay for services you can create a payment via Privat24 application or make a payment in the terminal of CB Privatbank JSC (not depending on the client's bank)":
    "For the proper display in the accounting system and identification of the person-recipient it is necessary to comply with this format of filling in the purpose of payment. Besides, we are informing that it is possible to pay for services via mobile application 'Seaman's Cabinet' with the internet-acquiring (payment is made through Payment service provider Platon). To pay for services you can create a payment via Privat24 application or make a payment in the terminal of CB Privatbank JSC (not depending on the client's bank).",
  "If the payer wishes to use online banking or another bank's terminal, he should follow the specific procedure for entering the details of the seafarer's proof of qualification:":
    "If the payer wishes to use online banking or another bank's terminal, he should follow the specific procedure for entering the details of the seafarer's proof of qualification:",
  "- the name of service which qualification seafarer need or service of seafarer's record book registration;":
    "- the name of service which qualification seafarer need or service of seafarer's record book registration;",
  '- full name of the recipient of the service - the seafarer;':
    '- full name of the recipient of the service - the seafarer;',
  '- seafarer ID;': '- seafarer ID;',
  '- tax identification number;': '- tax identification number;',
  '- first name, second name and surname of the service recipient;':
    '- first name, second name and surname of the service recipient;',
  '- TIN of the payer; VAT inclusive': '- TIN of the payer; VAT inclusive.',
  'For proper display in the accounting system and identification of the person-recipient of services it is necessary to observe this format of filling in the purpose of payment':
    'For proper display in the accounting system and identification of the person-recipient of services it is necessary to observe this format of filling in the purpose of payment.',
  "The requisites for payment of the Inspectorate's services at the":
    "The requisites for payment of the Inspectorate's services at the",
  link: 'link',

  'The list of documents for an application for passing exams in the SQC':
    'The list of documents for an application for passing exams in the SQC',
  'Dear sailors!': 'Dear sailors!',
  'To take the exams (CES tests) at the appropriate State Qualifications Commission (SQC), you must submit the following documents:':
    'To take the exams (CES tests) at the appropriate State Qualifications Commission (SQC), you must submit the following documents:',
  'a) an application for the SQC, which you submit in person to confirm your SQC qualifications;':
    'a) an application for the SQC, which you submit in person to confirm your SQC qualifications;',
  'b) a document which confirms graduation from an approved maritime educational institution in the relevant specialty and/or specialization (education document);':
    'b) a document which confirms graduation from an approved maritime educational institution in the relevant specialty and/or specialization (education document);',
  "The specified document is submitted in case of its absence in the Seafarer's Cabinet (System)":
    "The specified document is submitted in case of its absence in the Seafarer's Cabinet (System)",
  'c) document which confirms fitness to work on ships for health reasons (medical certificate);':
    'c) document which confirms fitness to work on ships for health reasons (medical certificate);',
  "d) a document confirming work time on ships, confirmed by the records in the seafarer's service record book, seafarer's identity card, and/or confirming work time in the relevant companies (if necessary)":
    "d) a document confirming work time on ships, confirmed by the records in the seafarer's service record book, seafarer's identity card, and/or confirming work time in the relevant companies (if necessary).",
  'Once the validity of the above documents, submitted to the SQC, is confirmed (points b - d) and the System confirms the compliance of these documents with the requirements established by applicable law, the System will automatically set a date for testing (in one business day)':
    'Once the validity of the above documents, submitted to the SQC, is confirmed (points b - d) and the System confirms the compliance of these documents with the requirements established by applicable law, the System will automatically set a date for testing (in one business day).',
  "Please send the appropriate documents using the Sailor's Cabinet for early verification and admission to the exams":
    "Please send the appropriate documents using the Sailor's Cabinet for early verification and admission to the exams.",
  'With concern for you, ITCS': 'With concern for you, ITCS',

  "The Inspectorate for the Training and Certification of Seafarers has introduced an 'Automated Seafarers' Competence Assessment System' (AS)":
    "The Inspectorate for the Training and Certification of Seafarers has introduced an 'Automated Seafarers' Competence Assessment System' (AS).",
  "As part of the implementation of the digital transformation program, the Inspectorate for the Training and Certification of Seafarers has introduced an 'Automated Seafarers' Competence Assessment System' (AS)":
    "As part of the implementation of the digital transformation program, the Inspectorate for the Training and Certification of Seafarers has introduced an 'Automated Seafarers' Competence Assessment System' (AS).",
  'This System includes a separate module for training facilities (NTZ), which is approved by the Ministry of Infrastructure. The NTZ, qualified by the Ministry, should register their issued certificates (certificates) in a single State Register of documents of seafarers automatically':
    'This System includes a separate module for training facilities (NTZ), which is approved by the Ministry of Infrastructure. The NTZ, qualified by the Ministry, should register their issued certificates (certificates) in a single State Register of documents of seafarers automatically.',
  'To work in the specified NTZ module, approved by the Ministry of Infrastructure, is necessary to:':
    'To work in the specified NTZ module, approved by the Ministry of Infrastructure, is necessary to:',
  'follow the link': 'follow the link',
  'fill the online application for registration in the AS module;':
    'fill the online application for registration in the AS module;',
  'consent to the processing of personal data and read the Privacy Policy for the processing and protection of personal data;':
    'consent to the processing of personal data and read the Privacy Policy for the processing and protection of personal data;',
  "to familiarize with the terms of the agreement 'On providing access to automated registration in the unified State register of documents of seamen of Ukraine' (Agreement) and accept its terms;":
    "to familiarize with the terms of the agreement 'On providing access to automated registration in the unified State register of documents of seamen of Ukraine' (Agreement) and accept its terms;",
  'provide the delivery of an electronic digital signature and an electronic digital seal to the director of NTZ, which are required for work in the system;':
    'provide the delivery of an electronic digital signature and an electronic digital seal to the director of NTZ, which are required for work in the system;',
  'after identification, consent to the processing of personal data and accept the terms of the Agreement. Obtain access to the AS module (Login and Password)':
    'after identification, consent to the processing of personal data and accept the terms of the Agreement. Obtain access to the AS module (Login and Password).',
  'The AS module for NTZ allows you to independently, automatically, register the issued certificates, provide their electronic signing, which eliminates any delays, queues, loss, damage, inaccuracy of data, etc':
    'The AS module for NTZ allows you to independently, automatically, register the issued certificates, provide their electronic signing, which eliminates any delays, queues, loss, damage, inaccuracy of data, etc.',

  'As part of the digital transformation program, a special module was introduced for the employment of seafarers':
    'As part of the digital transformation program, a special module was introduced for the employment of seafarers.',
  'The purpose of the implementation of the Module is to increase the efficiency of organizations in the field of services and employment of seafarers, as well as the introduction of convenient, fast and transparent public services for the maritime and river industry':
    'The purpose of the implementation of the Module is to increase the efficiency of organizations in the field of services and employment of seafarers, as well as the introduction of convenient, fast and transparent public services for the maritime and river industry.',
  'The module provides many advantages and capabilities for automating the registration and verification processes of documents, which is:':
    'The module provides many advantages and capabilities for automating the registration and verification processes of documents, which is:',
  '✓ reducing the time for requests application due to the data search function and quick selection of information from the available reference books, as well as automatic generation of data on previously registered seafarers;':
    '✓ reducing the time for requests application due to the data search function and quick selection of information from the available reference books, as well as automatic generation of data on previously registered seafarers;',
  '✓ expansion of the volume and completeness of information that must be provided for the crewing of seafarers, through the use of information from available databases and providing the opportunity to use centralized information resources;':
    '✓ expansion of the volume and completeness of information that must be provided for the crewing of seafarers, through the use of information from available databases and providing the opportunity to use centralized information resources;',
  "✓ ensuring the prompt provision of electronic copies of the seafarer's public documents to crewing, through e-mail and the Internet;":
    "✓ ensuring the prompt provision of electronic copies of the seafarer's public documents to crewing, through e-mail and the Internet;",
  '✓ Prompt provision of profile certificates to crewing by using a database of regulatory and reference resources;':
    '✓ Prompt provision of profile certificates to crewing by using a database of regulatory and reference resources;',
  "✓ improving the quality, reducing the time and the ability to provide a response to interested organizations on the availability and legitimacy of seafarers' documents;":
    "✓ improving the quality, reducing the time and the ability to provide a response to interested organizations on the availability and legitimacy of seafarers' documents;",
  '✓ ensuring the efficiency of training and registration of the seafarer in the appropriate crewing':
    '✓ ensuring the efficiency of training and registration of the seafarer in the appropriate crewing.',
  'As a result of the implementation of the Module, the Ukrainian seafarer receives affordable and high-quality services online, and crewing - optimized processes and time savings':
    'As a result of the implementation of the Module, the Ukrainian seafarer receives affordable and high-quality services online, and crewing - optimized processes and time savings.',

  'The Inspectorate provides an official answer to a comprehensive list of questions regarding the official mobile application "Mobile seafarer’s cabinet"':
    'The Inspectorate provides an official answer to a comprehensive list of questions regarding the official mobile application "Mobile seafarer’s cabinet".',
  "The Inspectorate for the Training and Certification of Seafarers has introduced electronic submission of seafarers' documents for confirmation of qualification in the State Qualification Commission using software":
    "The Inspectorate for the Training and Certification of Seafarers has introduced electronic submission of seafarers' documents for confirmation of qualification in the State Qualification Commission using software.",
  'One of the important steps in the implementation of the "Government in a smartphone" program is the introduction of a mobile sailor cabinet, as digitalization is becoming the most effective factor in economic growth and the struggle with corruption, which includes reducing the human factor in the process of provision of services':
    'One of the important steps in the implementation of the "Government in a smartphone" program is the introduction of a mobile sailor cabinet, as digitalization is becoming the most effective factor in economic growth and the struggle with corruption, which includes reducing the human factor in the process of provision of services.',
  'Given that the introduction of the official mobile application has aroused considerable public interest"':
    'Given that the introduction of the official mobile application has aroused considerable public interest,',
  'The Inspectorate provides an official answer to a comprehensive list of questions regarding the official mobile application "Mobile seafarer’s cabinet"':
    'The Inspectorate provides an official answer to a comprehensive list of questions regarding the official mobile application "Mobile seafarer’s cabinet"',

  'First three sailors successfully completed the CES test': 'First three sailors successfully completed the CES test',

  "The 11 of November at the service center of state enterprise 'Morrichservices' (Odessa city) three Ukrainian sailors, who have applied for submission of documents to participate in the computer CES-testing for the improvement of the quality, successfully passed exams and answered on more than 70% of the test questions. According to the exam results, the seafarers got a document, which confirms test passing. The results are also automatically saved in the information system, which is connected with the electronic services for the sailors":
    "The 11 of November at the service center of state enterprise 'Morrichservices' (Odessa city) three Ukrainian sailors, who have applied for submission of documents to participate in the computer CES-testing for the improvement of the quality, successfully passed exams and answered on more than 70% of the test questions. According to the exam results, the seafarers got a document, which confirms test passing. The results are also automatically saved in the information system, which is connected with the electronic services for the sailors.",
  'These results of the first exams confirm the success of technical measures aimed at implementing the CES-testing system and its actual launch':
    'These results of the first exams confirm the success of technical measures aimed at implementing the CES-testing system and its actual launch.',
  'Crew Evaluation System (CES) 6.0, developed by the Norwegian company Seagull Maritime AS, The CES test is one of the most common in the world, used for over 25 years in more than 350 companies worldwide. The system contains more than 6,500 multiple-choice questions and meets the standards of the 1978 International Convention on the Training and Certification of Seafarers and Watchkeeping':
    'Crew Evaluation System (CES) 6.0, developed by the Norwegian company Seagull Maritime AS, The CES test is one of the most common in the world, used for over 25 years in more than 350 companies worldwide. The system contains more than 6,500 multiple-choice questions and meets the standards of the 1978 International Convention on the Training and Certification of Seafarers and Watchkeeping.',

  "Mobile application 'Sailor's electronic office'": 'Mobile application "Sailor\'s electronic office"',
  "The Seafarer's Cabinet provides members of marine crews with easy access and the ability to remotely manage work documents":
    "The Seafarer's Cabinet provides members of marine crews with easy access and the ability to remotely manage work documents.",
  "Login to The Seafarer's Electronic Cabinet is available if you:":
    "Login to The Seafarer's Electronic Cabinet is available if you",
  'cadet or student of marine educational institutions;': 'cadet or student of marine educational institutions;',
  'certified SQC specialist who confirmed the qualification of a sailor;':
    'certified SQC specialist who confirmed the qualification of a sailor;',
  'official of the command staff and ship crew of sea vessels':
    'official of the command staff and ship crew of sea vessels.',
  'You can add or update existing documents in your Electronic Cabinet':
    'You can add or update existing documents in your Electronic Cabinet',
  'create a new Sailor profile or update the data of an existing one;':
    'create a new Sailor profile or update the data of an existing one;',
  'upload a photo or foreign passport and verify them;': 'upload a photo or foreign passport and verify them;',
  'download, digitize and verify scanned copies of educational documents;':
    'download, digitize and verify scanned copies of educational documents;',
  'download, digitize, and verify scanned copies of medical certificates':
    'download, digitize, and verify scanned copies of medical certificates.',
  'You can use electronic services in the app to submit an online application':
    'You can use electronic services in the app to submit an online application',
  'for passing exams in State Qualification Commissions (SQC);':
    'for passing exams in State Qualification Commissions (SQC);',
  'to take courses in educational and training institutions (ETI);':
    'to take courses in educational and training institutions (ETI);',
  "for obtaining the Seaman's Seagoing Service Record Book;":
    "for obtaining the Seaman's Seagoing Service Record Book;",
  "for obtaining the Seafarer's Identity Document;": "for obtaining the Seafarer's Identity Document;",
  "for obtaining Qualification documents in the Diploma and Passport departments of the Seaport captains' service":
    "for obtaining Qualification documents in the Diploma and Passport departments of the Seaport captains' service.",
  "In addition, in the Electronic cabinet, you can view information about all the sailor's documents that are in the unified state register, as well as identify documents that are missing there":
    "In addition, in the Electronic cabinet, you can view information about all the sailor's documents that are in the unified state register, as well as identify documents that are missing there.",
  "The Seafarer's Electronic Cabinet mobile application is already available on the platform":
    "The Seafarer's Electronic Cabinet mobile application is already available on the platform:",
  Android: 'Android',
  iOS: 'iOS',
  'Instructions for submitting an application to the SQC': 'Instructions for submitting an application to the SQC',

  'Electronic Services for Seafarers restored': 'Electronic Services for Seafarers restored',
  'Based on the results of joint work of specialists from the Ministry of Infrastructure and the Ministry of Digital Transformation, information systems related to Electronic Services for Seafarers have been launched':
    'Based on the results of joint work of specialists from the Ministry of Infrastructure and the Ministry of Digital Transformation, information systems related to Electronic Services for Seafarers have been launched.',
  '◼ How do online services work?': '◼ How do online services work?',
  'The official information resource of the Inspectorate for training and certification of seafarers, posted at the link, has been restored:':
    'The official information resource of the Inspectorate for training and certification of seafarers, posted at the link, has been restored:',
  'The official information resource of The Inspectorate for Training and Certification of Seafarers has been restored and available at the link: itcs.gov.ua The Ministry of Infrastructure recommends using the functionality of the official information resource':
    'The official information resource of The Inspectorate for Training and Certification of Seafarers has been restored and available at the link: itcs.gov.ua The Ministry of Infrastructure recommends using the functionality of the official information resource.',
  'The services will run in Test mode for a month. During this time, the necessary data on their improvement will be collected and analyzed. To make final decisions based on the results of the Test period, the Ministry of Infrastructure initiates a broad discussion to improve the system':
    'The services will run in Test mode for a month. During this time, the necessary data on their improvement will be collected and analyzed. To make final decisions based on the results of the Test period, the Ministry of Infrastructure initiates a broad discussion to improve the system.',
  '◼ Confirmation of Seafarers qualifications': '◼ Confirmation of Seafarers qualifications',
  'Now the option of submitting documents for participation in computer CES testing to confirm the qualification of seafarers has been introduced. The testing system is being implemented as part of a pilot project. Testing will be carried out in the Service Centers - the State Enterprise "Morrichservice"':
    'Now the option of submitting documents for participation in computer CES testing to confirm the qualification of seafarers has been introduced. The testing system is being implemented as part of a pilot project. Testing will be carried out in the Service Centers - the State Enterprise "Morrichservice".',
  'The license to use the CES electronic testing system was granted with the support of the USAID/UK aid Project "Transparency and Accountability in Public Administration and Services/TAPAS"':
    'The license to use the CES electronic testing system was granted with the support of the USAID/UK aid Project "Transparency and Accountability in Public Administration and Services/TAPAS".',
  'Seafarers who wish to confirm their qualifications without using the CES system will be given the opportunity to conduct computer testing using the technical capabilities of alternative systems':
    'Seafarers who wish to confirm their qualifications without using the CES system will be given the opportunity to conduct computer testing using the technical capabilities of alternative systems.',
  '◼ Assignment of certificate numbers issued by Educational and Training Institutions (ETI)':
    '◼ Assignment of certificate numbers issued by Educational and Training Institutions (ETI)',
  'The list of ETI where training encounter International and National Requirements was approved by the Order of the Ministry of Infrastructure No. 227 dated 04/20/2021':
    'The list of ETI where training encounter International and National Requirements was approved by the Order of the Ministry of Infrastructure No. 227 dated 04/20/2021.',
  'The assignment of Certificate numbers is carried out solely in Automatic mode and charge free, according to the Terms of Economic Contracts concluded by the ETI with The Inspectorate for the Training and Certification of Seafarers':
    'The assignment of Certificate numbers is carried out solely in Automatic mode and charge free, according to the Terms of Economic Contracts concluded by the ETI with The Inspectorate for the Training and Certification of Seafarers.',
  'Any offers of third parties (agents, intermediaries, etc.) regarding "assistance" in resolving issues of confirming the qualifications of Seafarers, the validity of documents certifying their qualifications and speeding up the provision of the above mentioned services on a paid basis are illegal. Please immediately inform Law Enforcement Agencies about such cases':
    'Any offers of third parties (agents, intermediaries, etc.) regarding "assistance" in resolving issues of confirming the qualifications of Seafarers, the validity of documents certifying their qualifications and speeding up the provision of the above mentioned services on a paid basis are illegal. Please immediately inform Law Enforcement Agencies about such cases.',
  "Recall that in July of this year, the Security Service of Ukraine (SSU) exposed an illegal scheme for processing Seafarers' documents. Server Capacities that served the Register of Seafarers were seized by SSU specialists from individuals and transferred to state control. The Ministry of Infrastructure together with the Ministry of Digital Transformation worked on the restoration of the register and related electronic services":
    "Recall that in July of this year, the Security Service of Ukraine (SSU) exposed an illegal scheme for processing Seafarers' documents. Server Capacities that served the Register of Seafarers were seized by SSU specialists from individuals and transferred to state control. The Ministry of Infrastructure together with the Ministry of Digital Transformation worked on the restoration of the register and related electronic services.",

  'Electronic Services. President of Ukraine Volodymyr Zelenskyy approved the transition to Paperless Mode':
    'Electronic Services. President of Ukraine Volodymyr Zelenskyy approved the transition to Paperless Mode',
  'Volodymyr Zelenskyy (Photo: Presidential press service)': 'Volodymyr Zelenskyy (Photo: Presidential press service)',
  'President Volodymyr Zelenskyy signed the Law No. 1689-IX "About the specifics of the provision of Public (Electronic Public) Services", adopted by Parliament on July 15, 2021':
    'President Volodymyr Zelenskyy signed the Law No. 1689-IX "About the specifics of the provision of Public (Electronic Public) Services", adopted by Parliament on July 15, 2021.',
  'The Law introduces new terms such as "Electronic public service", "Automatic mode of providing electronic public service", "Integrated electronic public service", "Interaction System of electronic resources", and other':
    'The Law introduces new terms such as "Electronic public service", "Automatic mode of providing electronic public service", "Integrated electronic public service", "Interaction System of electronic resources", and other.',
  'The document also defines the specifics of providing such services, the receiving procedure and the specifics of processing the results of their provision':
    'The document also defines the specifics of providing such services, the receiving procedure and the specifics of processing the results of their provision.',
  'Since 2020, more than 570,000 citizens of Ukraine have used Electronic Public Services through the Unified State Web Portal of Electronic Services. According to the Ministry of Digital Transformation, today more than 70 online services are available for Ukrainians on the Diia portal. With the introduction of "paperless" document management, the list of Public Services will increase significantly':
    'Since 2020, more than 570,000 citizens of Ukraine have used Electronic Public Services through the Unified State Web Portal of Electronic Services. According to the Ministry of Digital Transformation, today more than 70 online services are available for Ukrainians on the Diia portal. With the introduction of "paperless" document management, the list of Public Services will increase significantly.',
  '"If earlier we could digitize about 15 services in six months, now there will be several dozen. Government agencies will not be able to require a person to provide information that is already available about them in the Registers", commented Deputy Prime Minister-Minister of Digital Transformation Mykhailo Fedorov':
    '"If earlier we could digitize about 15 services in six months, now there will be several dozen. Government agencies will not be able to require a person to provide information that is already available about them in the Registers", commented Deputy Prime Minister-Minister of Digital Transformation Mykhailo Fedorov.',
  'According to the Minister, it will be easier to apply for marriage, sign up for a kindergarten queue, get a certificate of place of study, assign a retirement or subsidy, etc. It is planned to digitize 100% of services by 2024':
    'According to the Minister, it will be easier to apply for marriage, sign up for a kindergarten queue, get a certificate of place of study, assign a retirement or subsidy, etc. It is planned to digitize 100% of services by 2024.',
  'According to the draft law, Electronic Services will be able to be provided automatically, without the intervention of an official. In addition, you will be able to get several Services on a single application':
    'According to the draft law, Electronic Services will be able to be provided automatically, without the intervention of an official. In addition, you will be able to get several Services on a single application.',
  'The law comes into force three months after its publication. During this time, the Cabinet of Ministers must approve a plan to transfer Public Services into Electronic form':
    'The law comes into force three months after its publication. During this time, the Cabinet of Ministers must approve a plan to transfer Public Services into Electronic form.',
  'Source:': 'Source:',
  'Happy Day of the Navy and River Fleet of Ukraine!': 'Happy Day of the Navy and River Fleet of Ukraine!',

  'Please pay attention and take note of the following announcement!':
    'Please pay attention and take note of the following announcement!',
  'The Inspectorate for Training and Certification of Seafarers reports that from April 13, 2020, the requisites for payment of services:':
    'The Inspectorate for Training and Certification of Seafarers reports that from April 13, 2020, the requisites for payment of services:',
  'JSC “UKREXIMBANK”, Kyiv': 'JSC “UKREXIMBANK”, Kyiv',
  'Recipient: Inspectorate of Seafarers Training and Certification MFO 322313':
    'Recipient: Inspectorate of Seafarers Training and Certification MFO 322313',
  'Recipient’s EDRPOU: 25958804': 'Recipient’s EDRPOU: 25958804',
  UA263223130000026000000022450: 'UA263223130000026000000022450',
  'WILL BE INVALID, THIS CALCULATION BILL WILL BE CLOSED': 'WILL BE INVALID, THIS CALCULATION BILL WILL BE CLOSED.',
  'Details for payment of services from 04/13/2020:': 'Details for payment of services from 04/13/2020:',
  'Recipient: Inspectorate of Seafarers Training and Certification':
    'Recipient: Inspectorate of Seafarers Training and Certification',
  'v PJSC AB “UKRGASBANK”, Kyiv': 'v PJSC AB “UKRGASBANK”, Kyiv',
  'MFO 320478': 'MFO 320478',
  'Recipient’s EDRPOU: 25958804': 'Recipient’s EDRPOU: 25958804',
  UA863204780000026007924867108: 'UA863204780000026007924867108',
  'The receipts with valid payment details can be found on the ITCS website on the home page in the SQC – Payment Requirements section of the Inspectorate':
    'The receipts with valid payment details can be found on the ITCS website on the home page in the SQC – Payment Requirements section of the Inspectorate.',
  'The Inspectorate for Training and Certification of Seafarers reports that from April 13, 2020, the requisites for payment of services:':
    'The Inspectorate for Training and Certification of Seafarers reports that from April 13, 2020, the requisites for payment of services:',

  "Attention! Inspectorate for Training and Certification of Seafarers Introduces Electronic Filing of Seafarers' Documents":
    "Attention! Inspectorate for Training and Certification of Seafarers Introduces Electronic Filing of Seafarers' Documents",
  'The Seafarers Training and Inspection Inspectorate has introduced electronic filing of seafarers’ documents in order to confirm their qualification with the State Qualification Commission using software':
    'The Seafarers Training and Inspection Inspectorate has introduced electronic filing of seafarers’ documents in order to confirm their qualification with the State Qualification Commission using software.',
  'Due to the introduction of quarantine in the whole territory of Ukraine by the Cabinet of Ministers of Ukraine from March 12, 2020 to June 22, 2020, examinations of commanders and shipboard ships at the State Qualification Commissions will be held in videoconference mode':
    'Due to the introduction of quarantine in the whole territory of Ukraine by the Cabinet of Ministers of Ukraine from March 12, 2020 to June 22, 2020, examinations of commanders and shipboard ships at the State Qualification Commissions will be held in videoconference mode.',
  'Opening of a seafarers service center in Kherson': 'Opening of a seafarers service center in Kherson',
  'September 12, 2019 in Kherson with the participation of the Deputy Minister of Infrastructure of Ukraine Yuriy Lavrenyuk, the Head of the State Service of Maritime and River Transport of Ukraine Dmitry Petrenko and the Head of the Inspectorate for Seafarers Training and Diploma and river transport':
    'September 12, 2019 in Kherson with the participation of the Deputy Minister of Infrastructure of Ukraine Yuriy Lavrenyuk, the Head of the State Service of Maritime and River Transport of Ukraine Dmitry Petrenko and the Head of the Inspectorate for Seafarers Training and Diploma and river transport.',
  'From now on, seafarers’ document service can be obtained in Kherson at: Mira St., 45-A. In order to create a sailor-friendly environment, the Inspectorate provides computer equipment for sailors’ testing and exam facilities. Inspectorate employees are involved in receiving seafarers, reviewing their documents, forming personal cases, issuing a Seaman’s Service Book, conducting testing and ensuring the work of the SQC in the territory of SE “Maritime and River Transport Service Center”':
    'From now on, seafarers’ document service can be obtained in Kherson at: Mira St., 45-A. In order to create a sailor-friendly environment, the Inspectorate provides computer equipment for sailors’ testing and exam facilities. Inspectorate employees are involved in receiving seafarers, reviewing their documents, forming personal cases, issuing a Seaman’s Service Book, conducting testing and ensuring the work of the SQC in the territory of SE “Maritime and River Transport Service Center”.',
  'Opening of a seafarers service center in Izmail': 'Opening of a seafarers service center in Izmail',
  'On July 18, 2019, within the framework of the reform of the State Service for Maritime and River Transport of Ukraine, a service center for seafarers of SE “Service Center for Maritime and River Transport” was officially opened in Izmail':
    'On July 18, 2019, within the framework of the reform of the State Service for Maritime and River Transport of Ukraine, a service center for seafarers of SE “Service Center for Maritime and River Transport” was officially opened in Izmail.',
  'From now on, the service of registration of seafarers’ documents can be obtained in Izmail at: ul. St. Nicholas, 50':
    'From now on, the service of registration of seafarers’ documents can be obtained in Izmail at: ul. St. Nicholas, 50.',
  'The opening took place with the participation of the Head of the State Service for Maritime and River Transport of Ukraine Dmytro Petrenko, the Head of the Inspectorate for Training and Graduation of Seamen Yuri Polyanskiy, representatives of the regional and city authorities, specialists of the maritime industry of Ukraine':
    'The opening took place with the participation of the Head of the State Service for Maritime and River Transport of Ukraine Dmytro Petrenko, the Head of the Inspectorate for Training and Graduation of Seamen Yuri Polyanskiy, representatives of the regional and city authorities, specialists of the maritime industry of Ukraine.',
  'The main task of the newly created center is to organize and ensure the provision of public services for seafarers at a qualitatively new professional level, which will gradually replace the outdated seafarer’s document processing system with a convenient digital service in a modern and comfortable room with respect for everyone':
    'The main task of the newly created center is to organize and ensure the provision of public services for seafarers at a qualitatively new professional level, which will gradually replace the outdated seafarer’s document processing system with a convenient digital service in a modern and comfortable room with respect for everyone.',
  'In order to accomplish this task, the Inspectorate, in the framework of the implementation of the Agreement on Cooperation and Cooperation between SE “Maritime and River Transport Service Center” and the Inspection of Seafarers Training and Diploma, was provided with computer equipment for the seafarers’ testing and exam facilities':
    'In order to accomplish this task, the Inspectorate, in the framework of the implementation of the Agreement on Cooperation and Cooperation between SE “Maritime and River Transport Service Center” and the Inspection of Seafarers Training and Diploma, was provided with computer equipment for the seafarers’ testing and exam facilities.',
  'In addition, Inspectorate employees are involved in the work of the service center as testing secretaries and SQC secretaries in order to ensure the reception of seafarers, to review their documents, to form personal cases, to conduct testing and to ensure the operation of the SQC in the territory of the Maritime and River Transport Service Center':
    'In addition, Inspectorate employees are involved in the work of the service center as testing secretaries and SQC secretaries in order to ensure the reception of seafarers, to review their documents, to form personal cases, to conduct testing and to ensure the operation of the SQC in the territory of the Maritime and River Transport Service Center.',

  'Meeting of the 101st Session of the Maritime Safety Committee of the International Maritime Organization':
    'Meeting of the 101st Session of the Maritime Safety Committee of the International Maritime Organization',
  'The 101st Session of the Maritime Safety Committee (hereinafter referred to as the CBM) of the International Maritime Organization (hereinafter – IMO) was held in London (United Kingdom of Great Britain and Northern Ireland) on 05-14 June 2019. The Head of the Inspectorate for the Preparation and Certification of Seafarers Yuriy Polyanskiy and the Head of the International Relations Department Mariya Varfolomeyeva participated in the session as part of the Ukrainian delegation':
    'The 101st Session of the Maritime Safety Committee (hereinafter referred to as the CBM) of the International Maritime Organization (hereinafter – IMO) was held in London (United Kingdom of Great Britain and Northern Ireland) on 05-14 June 2019. The Head of the Inspectorate for the Preparation and Certification of Seafarers Yuriy Polyanskiy and the Head of the International Relations Department Mariya Varfolomeyeva participated in the session as part of the Ukrainian delegation.',
  'During that meeting, Inspectorate representatives joined the work of the editorial team to amend the mandatory IMO tools, where the following documents were processed':
    'During that meeting, Inspectorate representatives joined the work of the editorial team to amend the mandatory IMO tools, where the following documents were processed.',
  '– draft amendments to the annexes to the SOLAS-1974 Convention;':
    '– draft amendments to the annexes to the SOLAS-1974 Convention;',
  '– draft amendments to Chapter 15 of the International Fire Safety Code (FSS Code);':
    '– draft amendments to Chapter 15 of the International Fire Safety Code (FSS Code);',
  '– draft amendments to Parts A and A-1 of the International Safety Code for ships using gases or other low-flash fuel (IGF Code);':
    '– draft amendments to Parts A and A-1 of the International Safety Code for ships using gases or other low-flash fuel (IGF Code);',
  '– draft amendments to Chapters IV and VI of the International Rescue Code (LSA Code);':
    '– draft amendments to Chapters IV and VI of the International Rescue Code (LSA Code);',
  '– draft amendments to the International Code for the Construction and Equipment of Ships Carrying Dangerous Chemicals in Bulk (IBC Code);':
    '– draft amendments to the International Code for the Construction and Equipment of Ships Carrying Dangerous Chemicals in Bulk (IBC Code);',
  '– draft amendments to the International Code for the Enhanced Inspection Program for Surveillance of Intruder Ships and Oil Tankers 2011 (ESP Code);':
    '– draft amendments to the International Code for the Enhanced Inspection Program for Surveillance of Intruder Ships and Oil Tankers 2011 (ESP Code);',
  '– Draft Amendments to the International Bulk Shipping Code (IMSBC Code);':
    '– Draft Amendments to the International Bulk Shipping Code (IMSBC Code);',
  '– draft amendments to Chapters IV, V and VI of the Construction Code and Equipment of Ships Carrying Dangerous Chemicals in Bulk (BCH Code);':
    '– draft amendments to Chapters IV, V and VI of the Construction Code and Equipment of Ships Carrying Dangerous Chemicals in Bulk (BCH Code);',
  '– Draft Amendments to the Safety Certificate and Records of Special Purpose Vehicle Safety Certificate (SPS Code) Equipment;':
    '– Draft Amendments to the Safety Certificate and Records of Special Purpose Vehicle Safety Certificate (SPS Code) Equipment;',
  '– draft revised List of Solid Bulk Cargoes for which a fixed fire extinguishing system is not required or ineffective':
    '– draft revised List of Solid Bulk Cargoes for which a fixed fire extinguishing system is not required or ineffective..',
  'Also, during the meeting within agenda item 15, “Implementation of the STCW Convention”:\n– the report of the Secretary-General (pursuant to Rule I / 7, paragraph 2) was heard concerning those States Parties to the STCW which had been completed in accordance with the provisions of section A-I / 8 of the STCW Code and which confirmed the full and effective implementation of the provisions of the STCW Convention;':
    'Also, during the meeting within agenda item 15, “Implementation of the STCW Convention”:\n– the report of the Secretary-General (pursuant to Rule I / 7, paragraph 2) was heard concerning those States Parties to the STCW which had been completed in accordance with the provisions of section A-I / 8 of the STCW Code and which confirmed the full and effective implementation of the provisions of the STCW Convention;',
  '– involved in the consideration of competent persons designated by Governments retained by the Secretary-General in accordance with section A-I / 7 of the STCW Code (MSC.1 / Circ.797 / Rev.32)':
    '– involved in the consideration of competent persons designated by Governments retained by the Secretary-General in accordance with section A-I / 7 of the STCW Code (MSC.1 / Circ.797 / Rev.32).',
  'The most important item in the work of the Ukrainian delegation was agenda item 23, within which another report on Ukraine’s threat to shipping safety in the Black and Azov Seas in the Crimean Peninsula due to illegal actions of the Russian Federation was presented. The protest was backed by IMO member states, which called on the Russian Federation to immediately execute the order of the International Tribunal for the Law of the Sea and to release illegally seized naval vessels and their crews and respect Ukraine’s sovereignty along internationally recognized borders':
    'The most important item in the work of the Ukrainian delegation was agenda item 23, within which another report on Ukraine’s threat to shipping safety in the Black and Azov Seas in the Crimean Peninsula due to illegal actions of the Russian Federation was presented. The protest was backed by IMO member states, which called on the Russian Federation to immediately execute the order of the International Tribunal for the Law of the Sea and to release illegally seized naval vessels and their crews and respect Ukraine’s sovereignty along internationally recognized borders.',
  'Based on the results, a report will be drawn up on participation in the 101st session of the Maritime Safety Committee of the International Maritime Organization':
    'Based on the results, a report will be drawn up on participation in the 101st session of the Maritime Safety Committee of the International Maritime Organization.',

  'Regarding participation in an international event': 'Regarding participation in an international event',
  'From April 29 to May 3, 2019, the 6th Session of the Subcommittee on Human Factors, Training and Watchkeeping of the International Maritime Organization was held in London (United Kingdom) (HTW)':
    'From April 29 to May 3, 2019, the 6th Session of the Subcommittee on Human Factors, Training and Watchkeeping of the International Maritime Organization was held in London (United Kingdom) (HTW).',
  'Analyzing international experience related to the training and certification of seafarers is one of the main tasks assigned to the Inspectorate for the Training and Certification of Seafarers. Inspectorate staff participated in the meeting as part of the Ukrainian delegation':
    'Analyzing international experience related to the training and certification of seafarers is one of the main tasks assigned to the Inspectorate for the Training and Certification of Seafarers. Inspectorate staff participated in the meeting as part of the Ukrainian delegation.',
  'Issues such as reviewing draft new model courses and revision of existing ones, revision of the Guide for the STCW Code, comprehensive review of the International Convention on Standards of Training, Certification of Fishing Vessels and Watchkeeping (1995 VAT Amendment) were discussed. guides for developing, reviewing and approving modeling courses, reports on wrongdoing related to professional diplomas, and more':
    'Issues such as reviewing draft new model courses and revision of existing ones, revision of the Guide for the STCW Code, comprehensive review of the International Convention on Standards of Training, Certification of Fishing Vessels and Watchkeeping (1995 VAT Amendment) were discussed. guides for developing, reviewing and approving modeling courses, reports on wrongdoing related to professional diplomas, and more.',
  'The Subcommittee also considered the HTW document 6/4 “Illegal Actions Related to the Issuance of Diplomas in the Temporarily Occupied Autonomous Republic of Crimea and the City of Sevastopol, Ukraine':
    'The Subcommittee also considered the HTW document 6/4 “Illegal Actions Related to the Issuance of Diplomas in the Temporarily Occupied Autonomous Republic of Crimea and the City of Sevastopol, Ukraine.',
  'Members of the Ukrainian delegation actively participated in the work of the session of the Subcommittee, working groups and discussing the results of the session. The position of the Ukrainian delegation was taken into account when summarizing the Subcommittee':
    'Members of the Ukrainian delegation actively participated in the work of the session of the Subcommittee, working groups and discussing the results of the session. The position of the Ukrainian delegation was taken into account when summarizing the Subcommittee.',

  'Attention sailors!!!': 'Attention sailors!!!',
  'Dear sailors': 'Dear sailors.',
  'Please pay your attention to the information on changing the details for payment of services, in accordance with the resolution of the Board of the National Bank of Ukraine dated 28.12.2018 №162 “On the introduction of the International Bank Account Number (IBAN) in Ukraine” now the bank account number will be 29 alphanumeric symbols, as well as changes to the bank, the service provider, on JSB “UKRGASBANK”':
    'Please pay your attention to the information on changing the details for payment of services, in accordance with the resolution of the Board of the National Bank of Ukraine dated 28.12.2018 №162 “On the introduction of the International Bank Account Number (IBAN) in Ukraine” now the bank account number will be 29 alphanumeric symbols, as well as changes to the bank, the service provider, on JSB “UKRGASBANK”.',
  'These changes take effect from 01.01.2020': 'These changes take effect from 01.01.2020.',

  'New address': 'New address',
  'We inform you that since October 01, 2019, the Kherson Regional Branch is located at: 4600 Yaroslav Mudriy (Soviet) Str. 73003, Kherson':
    'We inform you that since October 01, 2019, the Kherson Regional Branch is located at: 4600 Yaroslav Mudriy (Soviet) Str. 73003, Kherson.',

  "Issuance of Seaman's Service Book resumed": "Issuance of Seaman's Service Book resumed",
  'The Inspectorate for Training and Certification of Seafarers reports that the issue of the Seafarer’s Service Book has been resumed since 10.09.2019':
    'The Inspectorate for Training and Certification of Seafarers reports that the issue of the Seafarer’s Service Book has been resumed since 10.09.2019',
  'The Inspectorate is currently fully equipped with the seafarer’s Service Booklet form':
    'The Inspectorate is currently fully equipped with the seafarer’s Service Booklet form',
  'To obtain this document, you must contact the regional branches of the Inspectorate':
    'To obtain this document, you must contact the regional branches of the Inspectorate',

  'The Inspectorate for the Training and Certification of Seafarers reports that the State Qualification Commissions at the Izmail Regional Branch of the Inspectorate have resumed their work since 04.03.2019':
    'The Inspectorate for the Training and Certification of Seafarers reports that the State Qualification Commissions at the Izmail Regional Branch of the Inspectorate have resumed their work since 04.03.2019.',
  'Residents of the cities of Mariupol, Kiev and other regions can optionally hold commissions in the cities of Izmail, Odessa and Kherson':
    'Residents of the cities of Mariupol, Kiev and other regions can optionally hold commissions in the cities of Izmail, Odessa and Kherson.',
  'For current information on the forthcoming dates of the meetings of the State Qualification Commissions, see HERE':
    'For current information on the forthcoming dates of the meetings of the State Qualification Commissions, see HERE.',
  'SQC meeting at the Izmail Regional Branch': 'SQC meeting at the Izmail Regional Branch',
  'In order to exchange experience and improve the professional level of the SQC Secretaries on March 04, 2019, meetings will be held at the Izmail Regional Branch of the Inspectorate in the following areas:':
    'In order to exchange experience and improve the professional level of the SQC Secretaries on March 04, 2019, meetings will be held at the Izmail Regional Branch of the Inspectorate in the following areas:.',
  '– confirmation of the navigators’ qualification – 04.03.2019 at 10:00':
    '– confirmation of the navigators’ qualification – 04.03.2019 at 10:00.',
  '– confirmation of qualification of ship mechanics, ship electromechanics and ship refrigeration mechanics – 04.03.2019 at 10:00':
    '– confirmation of qualification of ship mechanics, ship electromechanics and ship refrigeration mechanics – 04.03.2019 at 10:00.',
  '– confirmation of qualification for work on tankers – 04.03.2019 at 10:00':
    '– confirmation of qualification for work on tankers – 04.03.2019 at 10:00.',

  'The Kherson Regional Branch (Kherson, 17 Bogoroditskaya St.) issues books of service to persons who have previously submitted documents and paid the cost of a seaman’s service book to the Nikolaev Regional Branch':
    'The Kherson Regional Branch (Kherson, 17 Bogoroditskaya St.) issues books of service to persons who have previously submitted documents and paid the cost of a seaman’s service book to the Nikolaev Regional Branch.',
  'Phone for information (0552) 42-52-01': 'Phone for information (0552) 42-52-01.',

  'We inform you that from January 2, 2019, the Izmail Regional Branch will be located at the new address: Izmail, ul. Admiral Holostyakov, 32':
    'We inform you that from January 2, 2019, the Izmail Regional Branch will be located at the new address: Izmail, ul. Admiral Holostyakov, 32.',

  'The Ukrainian delegation participated in the 31st session of the Assembly of the International Maritime Organization':
    'The Ukrainian delegation participated in the 31st session of the Assembly of the International Maritime Organization',
  'In accordance with the terms of reference agreed by the Ministry of Foreign Affairs of Ukraine and approved by the Ministry of Infrastructure of Ukraine, the Ukrainian delegation participated in the 31st session of the Assembly of the International Maritime Organization (hereinafter referred to as the Assembly), held in London (United Kingdom of Great Britain and Northern Ireland) from November 25 to December 4, 2019':
    'In accordance with the terms of reference agreed by the Ministry of Foreign Affairs of Ukraine and approved by the Ministry of Infrastructure of Ukraine, the Ukrainian delegation participated in the 31st session of the Assembly of the International Maritime Organization (hereinafter referred to as the Assembly), held in London (United Kingdom of Great Britain and Northern Ireland) from November 25 to December 4, 2019.',
  'The Ukrainian delegation includes representatives of the Inspectorate for the Training and Certification of Seafarers':
    'The Ukrainian delegation includes representatives of the Inspectorate for the Training and Certification of Seafarers',
  'Yurii Polyansky, Head of the Inspectorate': 'Yurii Polyansky, Head of the Inspectorate',
  'Varfolomeeva Maria, Head of the Inspectorate’s International Cooperation Division':
    'Varfolomeeva Maria, Head of the Inspectorate’s International Cooperation Division',
  'Inspection representatives actively participated in the Assembly and Technical Committee 2':
    'Inspection representatives actively participated in the Assembly and Technical Committee 2',
  'Committee 2, in accordance with its agenda, considered the following items from the agenda of the Assembly:':
    'Committee 2, in accordance with its agenda, considered the following items from the agenda of the Assembly:',
  'Issues of strategy, planning, and organization reform': 'Issues of strategy, planning, and organization reform',
  'IMO Member State Audit Scheme': 'IMO Member State Audit Scheme',
  'Consideration of reports and recommendations of the Maritime Safety Committee':
    'Consideration of reports and recommendations of the Maritime Safety Committee',
  'Committee for the Protection of the Marine Environment': 'Committee for the Protection of the Marine Environment',
  'Committee on Formalities': 'Committee on Formalities',
  'Issues of the Convention for the Prevention of Pollution from Waste Disposal and Other Materials 1972 year and its 1996 Protocol and the like':
    'Issues of the Convention for the Prevention of Pollution from Waste Disposal and Other Materials 1972 year and its 1996 Protocol and the like',
  'Overall, after considering all the issues, it was suggested:':
    'Overall, after considering all the issues, it was suggested:',
  '1. To adopt draft resolution A31 / 8 (a) /Add.1 on IMO strategic planning for 2020-2021':
    '1. To adopt draft resolution A31 / 8 (a) /Add.1 on IMO strategic planning for 2020-2021',
  '2. To take the measures recommended by the Committee on the Audit Scheme of the IMO Member States':
    '2. To take the measures recommended by the Committee on the Audit Scheme of the IMO Member States',
  '3. To adopt a resolution “Temporary security measures for unconventional vessels operating in polar waters”':
    '3. To adopt a resolution “Temporary security measures for unconventional vessels operating in polar waters”',
  '4. To submit matters relating to the use and installation of reflective materials on life-saving appliances (resolution A.658 (16)) to the 102nd session of the Maritime Safety Committee for review and action':
    '4. To submit matters relating to the use and installation of reflective materials on life-saving appliances (resolution A.658 (16)) to the 102nd session of the Maritime Safety Committee for review and action',
  '5. To adopt several resolutions concerning both maritime safety and the environment':
    '5. To adopt several resolutions concerning both maritime safety and the environment',
  '6. To respond at a forthcoming Assembly session to resolutions A.739 (18) and A.789 (19)':
    '6. To respond at a forthcoming Assembly session to resolutions A.739 (18) and A.789 (19)',
  '7. To approve the reports of the 99th, 100th, and 101st sessions of the Committee on Safe Seas':
    '7. To approve the reports of the 99th, 100th, and 101st sessions of the Committee on Safe Seas',
  '8. To approve the reports of the 72nd, 73rd, and 74th sessions of the Marine Environmental Protection Committee':
    '8. To approve the reports of the 72nd, 73rd, and 74th sessions of the Marine Environmental Protection Committee',
  '9. To approve the reports of the 42nd and 43rd sessions of the Committee on Formalities':
    '9. To approve the reports of the 42nd and 43rd sessions of the Committee on Formalities',
  '10. To approve the reports of the 40th and 41st Consultative Meetings of the Contracting Parties, 13th and 14th meetings of the Contracting Parties to the London Protocol':
    '10. To approve the reports of the 40th and 41st Consultative Meetings of the Contracting Parties, 13th and 14th meetings of the Contracting Parties to the London Protocol',
  'In addition to their work on the Committee, they also participated in the consideration of other issues on the agenda of the 101st session of the Committee':
    'In addition to their work on the Committee, they also participated in the consideration of other issues on the agenda of the 101st session of the Committee.',
  'Listed on the activities of the World Maritime University (Malmo, Sweden) and the Institute of International Maritime Law (Malta), the status of IMO Conventions and Membership, the progress report on the adoption, adoption, ratification or accession of conventions and IMO codes and protocols and amendments thereto':
    'Listed on the activities of the World Maritime University (Malmo, Sweden) and the Institute of International Maritime Law (Malta), the status of IMO Conventions and Membership, the progress report on the adoption, adoption, ratification or accession of conventions and IMO codes and protocols and amendments thereto.',
  'Also heard information regarding:': 'Also heard information regarding:',
  '– Relations with the UN and special agencies': '– Relations with the UN and special agencies',
  '– IMO’s relations with intergovernmental organizations': '– IMO’s relations with intergovernmental organizations',
  '– IMO’s relations with international non-governmental organizations':
    '– IMO’s relations with international non-governmental organizations',
  '– World Sea Day 2018 and 2019 Celebration Report': '– World Sea Day 2018 and 2019 Celebration Report',
  '– Schemes for the appointment and operation of IMO Maritime Ambassadors':
    '– Schemes for the appointment and operation of IMO Maritime Ambassadors',
  'The next 32nd session of the IMO Assembly is scheduled for autumn 2021 at the Organization’s headquarters':
    'The next 32nd session of the IMO Assembly is scheduled for autumn 2021 at the Organization’s headquarters.',

  'On topical issues!': 'On topical issues!',
  'Last week, namely on February 7, in Odessa, at the initiative of the Professional Union of Maritime Transport Workers of Ukraine (PRMTU) and the Association “All-Ukrainian Association of Crewing Companies” (VOCC), events were held with the participation of representatives of maritime higher educational establishments, maritime educational and training facilities NTZ), crewing companies, Maritime Administration (IA) and Inspectorate for Seafarers Training and Certification (ITCS)':
    'Last week, namely on February 7, in Odessa, at the initiative of the Professional Union of Maritime Transport Workers of Ukraine (PRMTU) and the Association “All-Ukrainian Association of Crewing Companies” (VOCC), events were held with the participation of representatives of maritime higher educational establishments, maritime educational and training facilities NTZ), crewing companies, Maritime Administration (IA) and Inspectorate for Seafarers Training and Certification (ITCS).',
  'During the meetings, which were held separately from each other, issues were raised regarding the significant increase in the prices for training in the NTZ with the subsequent receipt of the relevant certificates, without cause accusing the MA and IAPM. In order to understand this issue, it is necessary to take into account the fact that ITCS is not a body that oversees the activities of training institutions, and thus cannot in any way be a factor in the pricing of services provided by the NTP itself for seafarers. Prices for services in passing sailors of various training courses are made by the NTPs themselves, taking into account the costs for profit. And if, in the opinion of the Inspectorate, the cost of the training certificate is lower than the cost of the equipment, of the teacher, of the economic costs, then there is a clear question: “Was the proper training of the seafarer? Was the certificate just sold instead of training the seafarer? ” Only the seafarers can answer these clear questions':
    'During the meetings, which were held separately from each other, issues were raised regarding the significant increase in the prices for training in the NTZ with the subsequent receipt of the relevant certificates, without cause accusing the MA and IAPM. In order to understand this issue, it is necessary to take into account the fact that ITCS is not a body that oversees the activities of training institutions, and thus cannot in any way be a factor in the pricing of services provided by the NTP itself for seafarers. Prices for services in passing sailors of various training courses are made by the NTPs themselves, taking into account the costs for profit. And if, in the opinion of the Inspectorate, the cost of the training certificate is lower than the cost of the equipment, of the teacher, of the economic costs, then there is a clear question: “Was the proper training of the seafarer? Was the certificate just sold instead of training the seafarer? ” Only the seafarers can answer these clear questions.',
  'In addition, during the meeting, representatives of the NTP raised the issue of changing the Inspection procedure for providing information from the NTP to the Unified Register of Seafarers of Ukraine (Register) of certificates issued to seafarers':
    'In addition, during the meeting, representatives of the NTP raised the issue of changing the Inspection procedure for providing information from the NTP to the Unified Register of Seafarers of Ukraine (Register) of certificates issued to seafarers.',
  'First of all, it must be understood that every seafarer who has received a Certificate of Competency with a Certificate of Endorsement attesting the issue for work on vessels flying the flags of other countries must be required to obtain this certificate. Of the State whose flag the vessel is registered, endorsement attesting the recognition in accordance with Regulation I / 10 of the STCW Convention':
    'First of all, it must be understood that every seafarer who has received a Certificate of Competency with a Certificate of Endorsement attesting the issue for work on vessels flying the flags of other countries must be required to obtain this certificate. Of the State whose flag the vessel is registered, endorsement attesting the recognition in accordance with Regulation I / 10 of the STCW Convention.',
  'It should be noted that without confirmation of diploma recognition, seafarers have no right to work on ships under the flag of other states, and to obtain this confirmation, other states apply to the Registry for confirmation of the legitimacy of not only professional diplomas, but also certificates undergoing appropriate training':
    'It should be noted that without confirmation of diploma recognition, seafarers have no right to work on ships under the flag of other states, and to obtain this confirmation, other states apply to the Registry for confirmation of the legitimacy of not only professional diplomas, but also certificates undergoing appropriate training.',
  'It is worth noting that recently there is a significant amount of receipt in the Register of false information from the NTZ on issued certificates, which makes it impossible to provide the Register with the confirmation of the legitimacy of the relevant documents at the request of foreign countries, and in turn leads to a significant delay in issuing Ukrainian seafarers. Endorsement attesting the recognition':
    'It is worth noting that recently there is a significant amount of receipt in the Register of false information from the NTZ on issued certificates, which makes it impossible to provide the Register with the confirmation of the legitimacy of the relevant documents at the request of foreign countries, and in turn leads to a significant delay in issuing Ukrainian seafarers. Endorsement attesting the recognition.',
  'Thus, in order to avoid the submission of inaccurate information from the NTZ to the Register, the Inspectorate is taking all possible measures aimed at a clearer and more transparent procedure for interaction between the NTZ and the Register':
    'Thus, in order to avoid the submission of inaccurate information from the NTZ to the Register, the Inspectorate is taking all possible measures aimed at a clearer and more transparent procedure for interaction between the NTZ and the Register.',

  'Phone number': 'Phone number',
  'Inspectorate hotline': 'Inspectorate hotline',
  'Technical support': 'Technical support',

  'ATTENTION!': 'ATTENTION!!!',
  'The Inspectorate for the Training and Certification of Seafarers has postponed citizens appointment for the quarantine period which established by the Cabinet of Ministers of Ukraine in order to prevent the spread of acute respiratory disease COVID-19 caused by the SARS-CoV-2 coronavirus in Ukraine':
    'The Inspectorate for the Training and Certification of Seafarers has postponed citizens appointment for the quarantine period which established by the Cabinet of Ministers of Ukraine in order to prevent the spread of acute respiratory disease COVID-19 caused by the SARS-CoV-2 coronavirus in Ukraine.',
  'Citizens who wish to reach out the ITCS, please send a written request:':
    'Citizens who wish to reach out the ITCS, please send a written request:',
  'postal address: 3 Proviantska Street, Kyiv, 04116;': 'postal address: 3 Proviantska Street, Kyiv, 04116;',
  email: 'email',
  'leave in ITCS mailbox': 'leave in ITCS mailbox.',

  'To all specialists in the field of maritime merchant shipping':
    'To all specialists in the field of maritime merchant shipping',
  "In this section you will be able to apply to the candidates for chairmen and members of the State Qualification Commission. Assessment of the suitability of the qualifications and experience of the candidates who will assess the competence of seafarers will be carried out by attestation by the relevant commission. The attestation commission, based on the results of the candidate's computer testing and other materials of the specified list, decides on the attestation of the candidate for members or chairman of the DCC":
    "In this section you will be able to apply to the candidates for chairmen and members of the State Qualification Commission. Assessment of the suitability of the qualifications and experience of the candidates who will assess the competence of seafarers will be carried out by attestation by the relevant commission. The attestation commission, based on the results of the candidate's computer testing and other materials of the specified list, decides on the attestation of the candidate for members or chairman of the DCC.",

  'List of documents on the SQC': 'List of documents on the SQC',
  NAVIGATORS: 'NAVIGATORS',
  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators ASSIGNMENT OF THE NAVIGATOR TITLE':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators ASSIGNMENT OF THE NAVIGATOR TITLE',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Book registration training': '3. Book registration training.',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages confirming sailing experience (if any)':
    '5. Seafarer’s identity card with all pages confirming sailing experience (if any).',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of GMES Operator’s Certificate and confirmation':
    '8. Certificate of GMES Operator’s Certificate and confirmation.',
  '9. Certificate of completion of advanced training courses (if more than 12 months have passed since the completion of education)':
    '9. Certificate of completion of advanced training courses (if more than 12 months have passed since the completion of education).',
  '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p.1, A-VI / 2 p.1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p.1, A-VI / 2 p.1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35):':
    '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35):',
  '– Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4)':
    '– Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4).',
  '11. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '11. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators ASSIGNMENT OF THE NAVIGATOR TITLE':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators ASSIGNMENT OF THE NAVIGATOR TITLE',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І/2, ІІ/1)':
    '3. Diploma of title and confirmation of title (І/2, ІІ/1).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of GMES Operator’s Certificate and confirmation':
    '8. Certificate of GMES Operator’s Certificate and confirmation.',
  '9. Certificate of completion of advanced training courses':
    '9. Certificate of completion of advanced training courses.',
  '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Introduction, initial training and safety briefing for all seafarers (VI/1, A-VI/1, p.1,2);':
    '– Introduction, initial training and safety briefing for all seafarers (VI/1, A-VI/1, p.1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p.1, A-VI / 2 p.1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p.1, A-VI / 2 p.1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35):':
    '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35):',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',
  '11. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '11. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators Assigning a title':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) navigators Assigning a title',
  'STEERING STAIRMAN': 'STEERING STAIRMAN',
  'BATTLE CAPTAIN': 'BATTLE CAPTAIN',
  'CAPTAIN OF SMALL SWIMMING': 'CAPTAIN OF SMALL SWIMMING',
  'COAST CAPTAIN': 'COAST CAPTAIN',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І / 2, ІІ / 1, ІІ / 2, ІІ / 3)':
    '3. Diploma of title and confirmation of title (І / 2, ІІ / 1, ІІ / 2, ІІ / 3)',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of GMES Operator’s Certificate and confirmation':
    '8. Certificate of GMES Operator’s Certificate and confirmation.',
  '9. Certificate of completion of advanced training courses':
    '9. Certificate of completion of advanced training courses.',
  '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Medical care aboard the ship (VI / 4, item 2, A-VI / 4, items 4-6);':
    '– Medical care aboard the ship (VI / 4, item 2, A-VI / 4, items 4-6);',
  '– Preparation for managing the composition of the navigational watch on the bridge (A-II / 1, A-II / 2, A-VIII / 2, Part 3, Part 4, Part 4-1, B-I / 12, paragraphs 68, 69, B-VIII / 2, pp. 4-1);':
    '– Preparation for managing the composition of the navigational watch on the bridge (A-II / 1, A-II / 2, A-VIII / 2, Part 3, Part 4, Part 4-1, B-I / 12, paragraphs 68, 69, B-VIII / 2, pp. 4-1);',
  '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35);':
    '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35);',
  '– Preparation for the use of electronic mapping and navigation information systems (A-II / 1, A-II / 2, A-II / 3, A-I / 12, B-I / 12, paras. 36-66);':
    '– Preparation for the use of electronic mapping and navigation information systems (A-II / 1, A-II / 2, A-II / 3, A-I / 12, B-I / 12, paras. 36-66);',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',
  '11. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '11. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) of navigators CONFIRMATION of the title':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) of navigators CONFIRMATION of the title',
  'STEERING STAIRMAN': 'STEERING STAIRMAN',
  'BATTLE CAPTAIN': 'BATTLE CAPTAIN',
  'CAPTAIN OF SMALL SWIMMING': 'CAPTAIN OF SMALL SWIMMING',
  'COAST CAPTAIN': 'COAST CAPTAIN',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (I / 2, II / 1, II / 2)':
    '3. Diploma of title and confirmation of title (I / 2, II / 1, II / 2).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of GMES Operator’s Certificate and confirmation':
    '8. Certificate of GMES Operator’s Certificate and confirmation.',
  '9. Certificate of completion of advanced training courses':
    '9. Certificate of completion of advanced training courses.',
  '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Medical care aboard the ship (VI / 4, item 2, A-VI / 4, items 4-6);':
    '– Medical care aboard the ship (VI / 4, item 2, A-VI / 4, items 4-6);',
  '– Preparation for managing the composition of the navigational watch on the bridge (A-II / 1, A-II / 2, A-VIII / 2, Part 3, Part 4, Part 4-1, B-I / 12, paragraphs 68, 69, B-VIII / 2, pp. 4-1);':
    '– Preparation for managing the composition of the navigational watch on the bridge (A-II / 1, A-II / 2, A-VIII / 2, Part 3, Part 4, Part 4-1, B-I / 12, paragraphs 68, 69, B-VIII / 2, pp. 4-1);',
  '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35);':
    '– Navigation training using radar, radar pad and automatic radar padding (A-II / 1, A-1/12, items 4,5, B-1/12, items 2-35);',
  '– Preparation for the use of electronic mapping and navigation information systems (A-II / 1, A-II / 2, A-II / 3, A-I / 12, B-I / 12, paras. 36-66);':
    '– Preparation for the use of electronic mapping and navigation information systems (A-II / 1, A-II / 2, A-II / 3, A-I / 12, B-I / 12, paras. 36-66);',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',
  '11. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '11. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  MECHANICS: 'MECHANICS',

  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship mechanics, ship electromechanics Assigning a title THIRD-CLASS MECHANIC THIRD-CLASS ELECTROMECHANICS':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship mechanics, ship electromechanics Assigning a title THIRD-CLASS MECHANIC THIRD-CLASS ELECTROMECHANICS',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Book registration training': '3. Book registration training.',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of completion of advanced training courses (if more than 12 months have passed since the completion of education)':
    '8. Certificate of completion of advanced training courses (if more than 12 months have passed since the completion of education).',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',

  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4)':
    '– Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4).',

  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable).',
  'THIRD-CLASS MECHANIC': 'THIRD-CLASS MECHANIC',
  'THIRD-CLASS ELECTROMECHANICS': 'THIRD-CLASS ELECTROMECHANICS',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І / 2, ІІІ / 1, ІІІ / 6)':
    '3. Diploma of title and confirmation of title (І / 2, ІІІ / 1, ІІІ / 6).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of completion of advanced training courses':
    '8. Certificate of completion of advanced training courses.',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',

  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p. 1, A-VI / 2 p. 1-4) – except for ship refrigerator mechanics;':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 p. 1, A-VI / 2 p. 1-4) – except for ship refrigerator mechanics;',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4) – except ship refrigerator mechanics;':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4) – except ship refrigerator mechanics;',
  '– Provision of first aid on board ships (VI / 4, item 1, A-VI / 4 items 1-3) – except for ship refrigeration mechanics;':
    '– Provision of first aid on board ships (VI / 4, item 1, A-VI / 4 items 1-3) – except for ship refrigeration mechanics;',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',

  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable).',
  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship mechanics Assigning a title SECOND-CLASS MECHANIC FIRST-RELEASE MECHANIC':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship mechanics Assigning a title SECOND-CLASS MECHANIC FIRST-RELEASE MECHANIC',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І / 2, ІІІ / 1, ІІІ / 2)':
    '3. Diploma of title and confirmation of title (І / 2, ІІІ / 1, ІІІ / 2).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of completion of advanced training courses':
    '8. Certificate of completion of advanced training courses.',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',

  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2)':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2)',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4)':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4)',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4)':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4)',
  '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3)':
    '– First aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3)',
  '– Preparation for managing the composition of the watch of the engine room (A-III / 2, A-VIII / 2, parts 3, 4, 4-2, B-I / 12, item 73, B-VIII / 2, parts 4-2 – for management level)':
    '– Preparation for managing the composition of the watch of the engine room (A-III / 2, A-VIII / 2, parts 3, 4, 4-2, B-I / 12, item 73, B-VIII / 2, parts 4-2 – for management level)',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) / Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)',

  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)',
  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship electromechanics CONFIRMATION of the title SECOND-CLASS ELECTROMECHANICS FIRST-CLASS ELECTROMECHANICS':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship electromechanics CONFIRMATION of the title SECOND-CLASS ELECTROMECHANICS FIRST-CLASS ELECTROMECHANICS',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І / 2, ІІІ / 6)':
    '3. Diploma of title and confirmation of title (І / 2, ІІІ / 6)',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of completion of advanced training courses':
    '8. Certificate of completion of advanced training courses.',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',

  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2);':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2);',
  '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);':
    '– Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)',
  'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',

  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable).',
  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship electromechanics Assigning a title SECOND-CLASS ELECTROMECHANICS FIRST-CLASS ELECTROMECHANICS':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship electromechanics Assigning a title SECOND-CLASS ELECTROMECHANICS FIRST-CLASS ELECTROMECHANICS',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (І / 2, ІІІ / 6)':
    '3. Diploma of title and confirmation of title (І / 2, ІІІ / 6).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages certifying sailing experience':
    '5. Seafarer’s identity card with all pages certifying sailing experience.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '8. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);',
  '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);':
    '– Familiarization, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, item 1,2);',
  '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);':
    '– Extended Program Fire Fighting (VI / 3, A-VI / 3, pp.1-4);',
  '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);':
    '– Provision of first aid on board ship (VI / 4, item 1, A-VI / 4 items 1-3);',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)',
  'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',

  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable).',
  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship refrigerator mechanics ASSIGNMENT / CONFIRMATION THIRD-CLASS REFRIGERATOR MECHANIC SECOND-CLASS REFRIGERATOR MECHANIC FIRST CLASS REFRIGERATOR MECHANIC':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) ship refrigerator mechanics ASSIGNMENT / CONFIRMATION THIRD-CLASS REFRIGERATOR MECHANIC SECOND-CLASS REFRIGERATOR MECHANIC FIRST CLASS REFRIGERATOR MECHANIC',
  '1. Document on education and qualification': '1. Document on education and qualification.',
  '2. Supplement to the education document': '2. Supplement to the education document.',
  '3. Diploma of title and confirmation of title (I / 2, UKR – if available)':
    '3. Diploma of title and confirmation of title (I / 2, UKR – if available).',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country.',
  '5. Seafarer’s identity card with all pages confirming sailing experience (if any)':
    '5. Seafarer’s identity card with all pages confirming sailing experience (if any).',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service.',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current).',
  '8. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '8. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2);':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, paragraph 1,2);',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)/':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5)/',
  'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    'Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4).',
  '9. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '9. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  'RADIO SPECIALISTS': 'RADIO SPECIALISTS',

  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) GMES operators with general / limited type of diploma':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) GMES operators with general / limited type of diploma',
  '1. Passport of a citizen of Ukraine or a citizen of another country':
    '1. Passport of a citizen of Ukraine or a citizen of another country.',
  '2. Seafarer ID (if any)': '2. Seafarer ID (if any)',
  '3. Certificate of completion of knowledge training / refresher course on the GMES simulator':
    '3. Certificate of completion of knowledge training / refresher course on the GMES simulator',
  '4. GMES Operator’s Diploma is limited / general (if any)':
    '4. GMES Operator’s Diploma is limited / general (if any)',
  '5. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '5. Certificate of fitness to work on seagoing vessels for health reasons (current)',
  'GMES radio electronics / radiotelephony operators / radio operators':
    'GMES radio electronics / radiotelephony operators / radio operators',
  '1. Document on education and qualification': '1. Document on education and qualification',
  '2. Supplement to the education document': '2. Supplement to the education document',
  '3. Diploma of title and confirmation of title': '3. Diploma of title and confirmation of title',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country',
  '5. Seafarer ID (if any)': '5. Seafarer ID (if any)',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service',
  '7. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '7. Certificate of fitness to work on seagoing vessels for health reasons (current)',
  '8. Certificate of training (if necessary)': '8. Certificate of training (if necessary)',
  '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '9. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention',
  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)',
  '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) /':
    '– Training of commanders responsible for the security of the ship (Vessel Safety Officer) (VI / 5, paragraph 1, A-VI / 5) /',
  '– Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)':
    '– Training and briefing on the safety of all seafarers (VI / 6, paragraphs 1, 2, A -VI / 6, paras 1-4)',
  '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '10. A document recognizing the educational document issued by a foreign educational institution (if applicable)',

  'PORT FLEET': 'PORT FLEET',

  'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) Specialists of the port, auxiliary and technical fleet and small-tonnage vessels':
    'LIST OF DOCUMENTS FOR CANDIDATE PERSONAL CASE for consideration by the State Qualification Commission (originals copies) Specialists of the port, auxiliary and technical fleet and small-tonnage vessels',
  '1. Document on education and qualification': '1. Document on education and qualification',
  '2. Supplement to the diploma of education': '2. Supplement to the diploma of education.',
  '3. Supplement to the diploma of education': '3. Supplement to the diploma of education.',
  '4. Passport of a citizen of Ukraine or a citizen of another country':
    '4. Passport of a citizen of Ukraine or a citizen of another country',
  '5. Seafarer’s Identity Certificate (if any) with all pages confirming the voyage':
    '5. Seafarer’s Identity Certificate (if any) with all pages confirming the voyage.',
  '6. Seafarer’s record of records showing the ship’s length of service':
    '6. Seafarer’s record of records showing the ship’s length of service',
  '7. Certificate of fitness to work on board vessels for health reasons':
    '7. Certificate of fitness to work on board vessels for health reasons.',
  '8. GMES Operator’s Diploma and Certification / Qualification Certificate and Radio Certification Operator (if applicable)':
    '8. GMES Operator’s Diploma and Certification / Qualification Certificate and Radio Certification Operator (if applicable)',
  '9. Certificate of completion of advanced training courses (in the case of title)':
    '9. Certificate of completion of advanced training courses (in the case of title)',
  '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '10. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)':
    '– Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)',
  '– Training of lifeboats, life rafts and regular lifeboats other than high-speed lifeboats (VI / 2, item 1, A-VI / 2, items 1-4)':
    '– Training of lifeboats, life rafts and regular lifeboats other than high-speed lifeboats (VI / 2, item 1, A-VI / 2, items 1-4)',
  '– Extended Program Fire Fighting Preparation (VI / 3, A-VI / 3, pp.1-4)':
    '– Extended Program Fire Fighting Preparation (VI / 3, A-VI / 3, pp.1-4)',
  '– First Aid Training (VI / 4, Item 1, A-VI / 4, Items 1-3)':
    '– First Aid Training (VI / 4, Item 1, A-VI / 4, Items 1-3)',
  '– Navigation training using radar, radar pad and automatic radar pad (A-II / 1, A-1/12, pp 4.5, B-1/12, pp 2-35 – for navigators)':
    '– Navigation training using radar, radar pad and automatic radar pad (A-II / 1, A-1/12, pp 4.5, B-1/12, pp 2-35 – for navigators)',
  '– Training of command and control personnel (Ship Security Officer) (VI / 5, Item 1, A-VI / 5) /':
    '– Training of command and control personnel (Ship Security Officer) (VI / 5, Item 1, A-VI / 5) /',
  '– Security Training and Instruction for All Sailors (VI / 6, Items 1, 2, A-VI / 6, Items 1-4 )':
    '– Security Training and Instruction for All Sailors (VI / 6, Items 1, 2, A-VI / 6, Items 1-4 ).',
  '11. Document recognizing a foreign education certificate educational institution (if necessary)':
    '11. Document recognizing a foreign education certificate educational institution (if necessary).',

  TANKERS: 'TANKERS',
  '1. Passport of a citizen of Ukraine or citizen of another country – 1.2 pages':
    '1. Passport of a citizen of Ukraine or citizen of another country',
  '2. Seafarer’s identity card with all pages certifying sailing experience':
    '2. Seafarer’s identity card with all pages certifying sailing experience',
  '3. Diploma or certificate of a specialist certifying the qualification of the seafarer':
    '3. Diploma or certificate of a specialist certifying the qualification of the seafarer',
  '4. Certificate of specialist in initial or advanced preparation for cargo operations on the appropriate type of tanker (if any)':
    '4. Certificate of specialist in initial or advanced preparation for cargo operations on the appropriate type of tanker (if any)',
  '5. Seafarer’s record book or other document with records confirming the length of service of the relevant type of tanker (not less than 3 months or one month of service if the tanker is employed in a freelance position, involving at least three loading operations and three unloading operations for the relevant type of cargo, which must be confirmed by the entries in the approved preparation registration book – to obtain a certificate of advanced preparation)':
    '5. Seafarer’s record book or other document with records confirming the length of service of the relevant type of tanker',
  '6. Certificate of fitness to work on seagoing vessels for health reasons (current)':
    '6. Certificate of fitness to work on seagoing vessels for health reasons (current)',
  '7. Documentary proof of training in accordance with the requirements of the STCW Convention at the rate of:':
    '7. Documentary proof of training in accordance with the requirements of the STCW Convention at the rate of:',
  'Initial preparation for cargo operations on oil and chemical tankers (V / 1-1, items 1,2, A-V / 1-1, item 1, V-V / 1-1)':
    'Initial preparation for cargo operations on oil and chemical tankers (V / 1-1, items 1,2, A-V / 1-1, item 1, V-V / 1-1)',
  'Initial preparation for cargo operations on tanker vessels (V / 1-2, items 1,2, A-V / 1-2, item 1, V-V / 1-2)':
    'Initial preparation for cargo operations on tanker vessels (V / 1-2, items 1,2, A-V / 1-2, item 1, V-V / 1-2)',
  'Preparation for cargo operations on advanced oil tankers (V / 1-1, para. 3.4, A-V / 1-1, para. 2)':
    'Preparation for cargo operations on advanced oil tankers (V / 1-1, para. 3.4, A-V / 1-1, para. 2)',
  'Preparation for carrying out cargo operations on chemical tankers under the extended program (V / 1-1, items 5.6, A-V / 1-1, item 3)':
    'Preparation for carrying out cargo operations on chemical tankers under the extended program (V / 1-1, items 5.6, A-V / 1-1, item 3)',
  'Preparation for carrying out cargo operations on tankers-gas carriers under the extended program (V / 1-2, items 3,4, A-V / 1-2, item 2)':
    'Preparation for carrying out cargo operations on tankers-gas carriers under the extended program (V / 1-2, items 3,4, A-V / 1-2, item 2)',

  RANKS: 'RANKS',
  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) the rank of the ship’s team CONFIRMATION OF QUALIFICATION FIRST CLASS SAILOR BOATSWAIN DONKERMAN':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) the rank of the ship’s team CONFIRMATION OF QUALIFICATION FIRST CLASS SAILOR BOATSWAIN DONKERMAN',
  '1. Document for the acquisition of vocational technical education and assignment of work qualifications and annex thereto':
    '1. Document for the acquisition of vocational technical education and assignment of work qualifications and annex thereto.',
  '2. Specialist Certificate / Qualification Certificate and Certificate (II / V, III / V – if previously issued by the master of the seaport)':
    '2. Specialist Certificate / Qualification Certificate and Certificate (II / V, III / V – if previously issued by the master of the seaport).',
  '3. Passport of a citizen of Ukraine or a citizen of another country':
    '3. Passport of a citizen of Ukraine or a citizen of another country.',
  '4. Seafarer’s identity card with all pages certifying the voyage (except for port naval specialists)':
    '4. Seafarer’s identity card with all pages certifying the voyage (except for port naval specialists).',
  '5. A seafarer’s record book of record of work experience':
    '5. A seafarer’s record book of record of work experience.',
  '6. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '6. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '- Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)':
    '- Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)',
  '- Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);':
    '- Specialist in lifeboats, life rafts and dinghies other than high-speed dinghies (VI / 2 item 1, A-VI / 2 items 1-4);',
  '- Extended Program Fire Fighting (VI / 3, A-VI / 3);': '- Extended Program Fire Fighting (VI / 3, A-VI / 3);',
  '- Initial training to operate on the appropriate types of tankers (clauses 1, 2 of Regulation V / 1-1 or clauses 1, 2 of Regulation V / 1-2, paragraph 1 of section AV / 1-1 or paragraph 1 of section A-V / 1-2 ) (for Donkerman only);':
    '- Initial training to operate on the appropriate types of tankers (clauses 1, 2 of Regulation V / 1-1 or clauses 1, 2 of Regulation V / 1-2, paragraph 1 of section AV / 1-1 or paragraph 1 of section A-V / 1-2 ) (for Donkerman only);',
  '- Preparation for an Advanced Oil Tanker Program (paragraphs 3, 4 of Regulation V / 1-1 or paragraphs 5, 6 of Regulation V / 1-1, or paragraphs 3, 4 of Regulation V / 1-2, paragraph 2 of section AV / 1 -1 or AV / 1-1 section 3, or AV / 1-2 section 3 (3) (for Dockerman only)':
    '- Preparation for an Advanced Oil Tanker Program (paragraphs 3, 4 of Regulation V / 1-1 or paragraphs 5, 6 of Regulation V / 1-1, or paragraphs 3, 4 of Regulation V / 1-2, paragraph 2 of section AV / 1 -1 or AV / 1-1 section 3, or AV / 1-2 section 3 (3) (for Dockerman only).',
  '- Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4)':
    '- Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4).',
  '7. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '7. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) the rank of the ship’s team CONFIRMATION OF QUALIFICATION FIRST CLASS MOTORIST SHIPPING ELECTRIC':
    'LIST of documents for registration of the candidate’s personal file for consideration by the State Qualification Commission (originals copies) the rank of the ship’s team CONFIRMATION OF QUALIFICATION FIRST CLASS MOTORIST SHIPPING ELECTRIC',
  '1. Document for the acquisition of vocational technical education and assignment of work qualifications and annex thereto':
    '1. Document for the acquisition of vocational technical education and assignment of work qualifications and annex thereto.',
  '2. Specialist Certificate / Qualification Certificate and Confirmation (I / 2, III / V, III / 7- if previously issued by the master of the seaport)':
    '2. Specialist Certificate / Qualification Certificate and Confirmation (I / 2, III / V, III / 7- if previously issued by the master of the seaport).',
  '3. Passport of a citizen of Ukraine or a citizen of another country':
    '3. Passport of a citizen of Ukraine or a citizen of another country.',
  '4. Seafarer’s identity card with all pages certifying the voyage (except for port naval specialists)':
    '4. Seafarer’s identity card with all pages certifying the voyage (except for port naval specialists).',
  '5. A seafarer’s record of record of work experience': '5. A seafarer’s record of record of work experience.',
  '6. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:':
    '6. Professional certificate or documentary proof of completion of training in accordance with the requirements of the STCW Convention:',
  '- Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)':
    '- Introduction, initial training and safety briefing for all seafarers (VI / 1, A-VI / 1, pp.1,2)',
  '- Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4)':
    '- Safety training and instruction for all seafarers (VI / 6, items 1, 2, A-VI / 6, items 1-4).',
  '7. A document recognizing the educational document issued by a foreign educational institution (if applicable)':
    '7. A document recognizing the educational document issued by a foreign educational institution (if applicable).',

  OTHER: 'OTHER',
  ADVERTISEMENT: 'ADVERTISEMENT',

  'Attention, to all specialists in the field of maritime trade shipping!':
    'Attention, to all specialists in the field of maritime trade shipping!',
  'We inform about the recruitment of candidates for the heads and members of the State Qualification Commissions (hereinafter - candidates). The assessment of the qualifications and experience of the candidates who will evaluate the competence of seafarers will be carried out by the relevant commission':
    'We inform about the recruitment of candidates for the heads and members of the State Qualification Commissions (hereinafter - candidates). The assessment of the qualifications and experience of the candidates who will evaluate the competence of seafarers will be carried out by the relevant commission.',
  "To participate in the certification, each of the candidates must submit statements of the established sample in the name of the head of the Inspection in electronic form through the Inspection's website (section 'CQC'), using a qualified electronic signature from December 15 to December 17, 2022. Also, for participation in the certification, each candidate must have verified copies of seafarers' documents in the State Register:":
    "To participate in the certification, each of the candidates must submit statements of the established sample in the name of the head of the Inspection in electronic form through the Inspection's website (section 'CQC'), using a qualified electronic signature from December 15 to December 17, 2022. Also, for participation in the certification, each candidate must have verified copies of seafarers' documents in the State Register:",
  '- passport of a citizen of Ukraine (pages containing data on the surname, first name, and date of birth of the owner, passport series, number, who and when issued, as well as the place of residence according to the state registration);':
    '- passport of a citizen of Ukraine (pages containing data on the surname, first name, and date of birth of the owner, passport series, number, who and when issued, as well as the place of residence according to the state registration);',
  "- certificate of a seafarer's identity (if available) - all filled pages;":
    "- certificate of a seafarer's identity (if available) - all filled pages;",
  "- seafarer's service record (COC) with entries. In the absence of a COC or relevant entries, provide evidence of sea service or other documentary evidence;":
    "- seafarer's service record (COC) with entries. In the absence of a COC or relevant entries, provide evidence of sea service or other documentary evidence;",
  '- diploma/certificate of education;': '- diploma/certificate of education;',
  '- diploma certifying the rank of a member of the command staff;':
    '- diploma certifying the rank of a member of the command staff;',
  '- confirmation of the diploma certifying the rank of a member of the command staff (if available);':
    '- confirmation of the diploma certifying the rank of a member of the command staff (if available);',
  '- diploma of GMDSS radio operator or diploma of GMDSS radio electronics of the first or second class (for candidates in the CQC for confirmation of the qualifications of radio specialists);':
    '- diploma of GMDSS radio operator or diploma of GMDSS radio electronics of the first or second class (for candidates in the CQC for confirmation of the qualifications of radio specialists);',
  '- confirmation of the diploma of a GMDSS radio operator or a diploma of a GMDSS radio electronics of the first or second class (for candidates in the CQC for confirmation of the qualifications of radio specialists) - if available;':
    '- confirmation of the diploma of a GMDSS radio operator or a diploma of a GMDSS radio electronics of the first or second class (for candidates in the CQC for confirmation of the qualifications of radio specialists) - if available;',
  '- specialist certificate (if available) or documentary evidence of completing the approved training program;':
    '- specialist certificate (if available) or documentary evidence of completing the approved training program;',
  '- professional qualification documents issued by the International Maritime Organization (IMO), other international maritime institutions, or authorized maritime administrations (if available)':
    '- professional qualification documents issued by the International Maritime Organization (IMO), other international maritime institutions, or authorized maritime administrations (if available).',
  'In case of the availability of the required documents, the candidate is allowed to undergo computer testing, the time, date, and place of which will be communicated by electronic means of communication':
    'In case of the availability of the required documents, the candidate is allowed to undergo computer testing, the time, date, and place of which will be communicated by electronic means of communication.',
  "Based on the results of the candidate's computer testing and other materials from the specified list, the Certification Commission makes a decision on the certification of the candidate as a member or head of the CQC":
    "Based on the results of the candidate's computer testing and other materials from the specified list, the Certification Commission makes a decision on the certification of the candidate as a member or head of the CQC.",

  'Location of the State Qualification Commission': 'Location of the State Qualification Commission',
  'SQC in the city of Izmail': 'SQC in the city of Izmail',
  'Svyato-Nikolskaya Street, 50, Izmail, Odessa region, premises of SQC Morrichservice':
    'Svyato-Nikolskaya Street, 50, Izmail, Odessa region, premises of SQC Morrichservice',
  'SQC in the city of Odessa': 'SQC in the city of Odessa',
  'Mykhailo Hrushevskoho Street, 39e, Odesa, Odesa Region, Morrichservice SQC premises':
    'Mykhailo Hrushevskoho Street, 39e, Odesa, Odesa Region, Morrichservice SQC premises',
  'SQC in the city of Kherson': 'SQC in the city of Kherson',
  'Mira Street, 45a, Kherson, Kherson region, premises of SQC Morrichservice':
    'Mira Street, 45a, Kherson, Kherson region, premises of SQC Morrichservice',
  'SQC in the city of Mariupol': 'SQC in the city of Mariupol',
  'Gagarina Street, 4, Mariupol, Donetsk region, SQC Morrichservice premises':
    'Gagarina Street, 4, Mariupol, Donetsk region, SQC Morrichservice premises',

  'Information about seamans testing for confirmation of qualifications using the Crew Evaluation System 6.0':
    'Information about seamans testing for confirmation of qualifications using the Crew Evaluation System 6.0',
  Statements: 'Statements',
  'Filed as of': 'Filed as of ',
  'In order to avoid external interference in the process and to prevent the graduate or seafarer from accessing the information resources of the Internet, testing is carried out at special automated workplaces':
    'In order to avoid external interference in the process and to prevent the graduate or seafarer from accessing the information resources of the Internet, testing is carried out at special automated workplaces.',
  Exams: 'Exams',
  'Held as of': 'Held as of ',
  'The pass level for successful passing the test in any direction is 70%. Technical means of control, including audio and video recording are used during the exam':
    'The pass level for successful passing the test in any direction is 70%. Technical means of control, including audio and video recording are used during the exam.',

  Sailors: 'Sailors',
  'Admitted to the exam, as of': 'Admitted to the exam, as of ',
  'The CES electronic testing system was licensed with support from the USAID / UK aid Transparency and Accountability in Public Administration and Services / TAPAS project':
    'The CES electronic testing system was licensed with support from the USAID / UK aid Transparency and Accountability in Public Administration and Services / TAPAS project.',

  'Reconfirmation of the diploma without passing the exam in the State Qualification Commission, provided by presence of proven length of service on vessels while performing the functions corresponding to the confirmed diploma':
    'Reconfirmation of the diploma without passing the exam in the State Qualification Commission, provided by presence of proven length of service on vessels while performing the functions corresponding to the confirmed diploma.',
  'Received diploma confirmations based on the length of service for the period':
    'Received diploma confirmations based on the length of service for the period ',
  from: ' from ',
  to: ' to ',

  'Meetings schedule of the State Qualification Commissions for the assignment and confirmation of qualifications of officers and ratings of the ship for the first quarter of 2022':
    'Meetings schedule of the State Qualification Commissions for the assignment and confirmation of qualifications of officers and ratings of the ship for the first quarter of 2022',
  Venue: 'Venue',
  'of work of the State Qualification Commissions': 'of work of the State Qualification Commissions *',
  'Working days of State Qualification Commissions': 'Working days of State Qualification Commissions',
  'Opening hours': 'Opening hours',
  'of State Qualification Commissions': 'of State Qualification Commissions',
  'State qualification commissions in Odessa': 'State qualification commissions in Odessa',
  'from Monday to Friday': 'from Monday to Friday',
  hours: 'hours',
  'State qualification commissions in Kherson': 'State qualification commissions in Kherson',
  'State qualification commissions in Mariupol': 'State qualification commissions in Mariupol',
  'State qualification commissions in Izmail': 'State qualification commissions in Izmail',
  '*The work schedule (meetings) of the SQC may be changed due to the Government\'s tasks to overcome the negative consequences caused by restrictive measures to prevent the occurrence and spread of acute respiratory disease COVID-19 caused by the coronavirus SARS-CoV2; due to the peculiarities of the organization of work in regions or administrative division where the "green", "yellow", "orange" or "red" level of epidemic danger is established; due to the mode of operation of the Service Center for Maritime and River Transport':
    '*The work schedule (meetings) of the SQC may be changed due to the Government\'s tasks to overcome the negative consequences caused by restrictive measures to prevent the occurrence and spread of acute respiratory disease COVID-19 caused by the coronavirus SARS-CoV2; due to the peculiarities of the organization of work in regions or administrative division where the "green", "yellow", "orange" or "red" level of epidemic danger is established; due to the mode of operation of the Service Center for Maritime and River Transport.',
  '**Taking into account the orders of the Cabinet of Ministers of Ukraine from 30.09.2020 № 1191-r "On the transfer of working days in 2021"':
    '**Taking into account the orders of the Cabinet of Ministers of Ukraine from 30.09.2020 № 1191-r "On the transfer of working days in 2021".',

  'Areas of compulsory training and graduation of commanders of naval vessels':
    'Areas of compulsory training and graduation of commanders of naval vessels',
  'LIST OF REQUIREMENTS OF COMPULSORY TRAINING FOR GRADUATION OF PERSONNEL OF COMMAND OF SHIPS AND RECOMMENDED AREAS OF TRAINING':
    'LIST OF REQUIREMENTS OF COMPULSORY TRAINING FOR GRADUATION OF PERSONNEL OF COMMAND OF SHIPS AND RECOMMENDED AREAS OF TRAINING',
  '№ s/n': '№ s/n',
  'Rules of the STCW Convention and sections of the STCW Code':
    'Rules of the STCW Convention and sections of the STCW Code',
  'Direction of preparation, brief description': 'Direction of preparation, brief description',
  'Required / Recommended': 'Required / Recommended',
  Trainees: 'Trainees',
  'Re-training': 'Re-training',
  'VI/1, A-VI/1, items 1, 2': 'VI/1, A-VI/1, items 1, 2',
  'Familiarization, initial training and safety briefing': 'Familiarization, initial training and safety briefing',
  "All members of the ship's crew": "All members of the ship's crew",
  'VI/2, item 1, A-VI/2, items 1-4': 'VI/2, item 1, A-VI/2, items 1-4',
  'Training of specialists in lifeboats, life rafts and duty boats that are not high-speed duty boats':
    'Training of specialists in lifeboats, life rafts and duty boats that are not high-speed duty boats',
  'Captains, senior assistant captains, senior mechanics, second mechanics, watchkeeping assistant captains, shift mechanics, electromechanics, persons assigned to command these life-saving appliances':
    'Captains, senior assistant captains, senior mechanics, second mechanics, watchkeeping assistant captains, shift mechanics, electromechanics, persons assigned to command these life-saving appliances',
  'VI/2, item 2, A-VI/2, items 7-10': 'VI/2, item 2, A-VI/2, items 7-10',
  'Training of specialists on high-speed duty boats': 'Training of specialists on high-speed duty boats',
  'Persons assigned to command high-speed duty boats': 'Persons assigned to command high-speed duty boats',
  'VI/3, A-VI/3, items 1-4': 'VI/3, A-VI/3, items 1-4',
  'Preparation for firefighting according to the extended program':
    'Preparation for firefighting according to the extended program',
  'Captains, senior assistant captains, senior mechanics, second mechanics, watchkeeping assistant captains, shift mechanics, electromechanics, persons assigned to manage fire-fighting operations':
    'Captains, senior assistant captains, senior mechanics, second mechanics, watchkeeping assistant captains, shift mechanics, electromechanics, persons assigned to manage fire-fighting operations',
  'Yes*': 'Yes*',
  'VI/4, item 1, A-VI/4, items 1-3': 'VI/4, item 1, A-VI/4, items 1-3',
  'First aid training': 'First aid training',
  "Captains, senior mate assistants, senior mechanics, second mechanics, captain's assistant mates, shift mechanics, electromechanics, persons assigned to provide first aid on board":
    "Captains, senior mate assistants, senior mechanics, second mechanics, captain's assistant mates, shift mechanics, electromechanics, persons assigned to provide first aid on board",
  'VI/4, item 2, A-VI/4, items 4-6': 'VI/4, item 2, A-VI/4, items 4-6',
  'Training in medical care on board': 'Training in medical care on board',
  'Captains, senior mates, persons assigned to perform medical duties on board ships':
    'Captains, senior mates, persons assigned to perform medical duties on board ships',
  'VI/5, item 1, A-VI/5': 'VI/5, item 1, A-VI/5',
  'Training of crew members responsible for ship security (ship security officer)':
    'Training of crew members responsible for ship security (ship security officer)',
  'Persons appointed to be responsible for the protection of the vessel':
    'Persons appointed to be responsible for the protection of the vessel',
  Yes: 'Yes',
  'VI/6, items 1, 2, A-VI/6, items 1-4': 'VI/6, items 1, 2, A-VI/6, items 1-4',
  'Security training and instruction for all seafarers': 'Security training and instruction for all seafarers',
  "All members of the ship's crew": "All members of the ship's crew",
  'VI/6, item 4, A-VI/6, items 6-8': 'VI/6, item 4, A-VI/6, items 6-8',
  'Training of seafarers assigned to perform ship security duties':
    'Training of seafarers assigned to perform ship security duties',
  'Persons assigned to perform special duties for the protection of the vessel':
    'Persons assigned to perform special duties for the protection of the vessel',
  'A-II/1, А-1/12, items 4, 5, В-1/12, items 2-35': 'A-II/1, А-1/12, items 4, 5, В-1/12, items 2-35',
  'Navigation training using radar, radar gasket and automatic radar gasket means (hereinafter - ARGM)':
    'Navigation training using radar, radar gasket and automatic radar gasket means (hereinafter - ARGM)',
  'Navigators whose diplomas are endorsed with the restriction "Except for ships equipped with ARGM equipment"':
    'Navigators whose diplomas are endorsed with the restriction "Except for ships equipped with ARGM equipment"',
  'А-II/1, A- II/2, A-II/3, А-I/12, В-I/12, items 36-66': 'А-II/1, A- II/2, A-II/3, А-I/12, В-I/12, items 36-66',
  'Preparation for the use of electronic cartographic and navigation information systems (hereinafter - ECNIS) at the management level':
    'Preparation for the use of electronic cartographic and navigation information systems (hereinafter - ECNIS) at the management level',
  'Navigators of the management level': 'Navigators of the management level',
  'A-II/2, А-VIII/2, parts 3, 4 та 4-1, В-I/12, items 68, 69, В-VIII/2, parts 4-1':
    'A-II/2, А-VIII/2, parts 3, 4 та 4-1, В-I/12, items 68, 69, В-VIII/2, parts 4-1',
  'Preparation for the management of the composition of the navigation watch on the bridge at the management level':
    'Preparation for the management of the composition of the navigation watch on the bridge at the management level',
  'Drivers of the management level': 'Drivers of the management level',
  'А-III/2, А-VIII/2, parts 3, 4, 4-2, В-I/12, item 73, В-VIII/2, parts 4-2':
    'А-III/2, А-VIII/2, parts 3, 4, 4-2, В-I/12, item 73, В-VIII/2, parts 4-2',
  'Training on the management of the engine room watch at the management level':
    'Training on the management of the engine room watch at the management level',
  'Ship mechanics of management level': 'Ship mechanics of management level',
  'V/1-1, items 1,2, A-V/1-1, item 1, В-V/1-1': 'V/1-1, items 1,2, A-V/1-1, item 1, В-V/1-1',
  'Initial preparation for cargo operations on oil tankers and chemical tankers':
    'Initial preparation for cargo operations on oil tankers and chemical tankers',
  'Persons involved in cargo operations on oil tankers and chemical tankers':
    'Persons involved in cargo operations on oil tankers and chemical tankers',
  'V/1-2, items 1,2, A-V/1-2, item 1, В-V/1-2': 'V/1-2, items 1,2, A-V/1-2, item 1, В-V/1-2',
  'Initial preparation for cargo operations on gas tankers': 'Initial preparation for cargo operations on gas tankers',
  'Persons involved in cargo operations on gas tankers': 'Persons involved in cargo operations on gas tankers',
  'V/1-1, items 3, 4, A-V/1-1, item 2': 'V/1-1, items 3, 4, A-V/1-1, item 2',
  'Preparation for cargo operations on oil tankers under the expanded program':
    'Preparation for cargo operations on oil tankers under the expanded program',
  'Captains, senior mates, senior mechanics, other mechanics, and other persons directly responsible for cargo operations and cargo, as well as precautions during the transportation or handling of cargo on oil tankers':
    'Captains, senior mates, senior mechanics, other mechanics, and other persons directly responsible for cargo operations and cargo, as well as precautions during the transportation or handling of cargo on oil tankers',
  'V/1-1, items 5, 6, A-V/1-1, item 3': 'V/1-1, items 5, 6, A-V/1-1, item 3',
  'Preparation for cargo operations on chemical tankers under the expanded program':
    'Preparation for cargo operations on chemical tankers under the expanded program',
  'Captains, senior mate assistants, senior mechanics, second mechanics, and other persons directly responsible for cargo operations and cargo, as well as precautions during the transportation or handling of cargo on chemical tankers':
    'Captains, senior mate assistants, senior mechanics, second mechanics, and other persons directly responsible for cargo operations and cargo, as well as precautions during the transportation or handling of cargo on chemical tankers',
  'V/1-2, items 3, 4, A-V/1-2, item 2': 'V/1-2, items 3, 4, A-V/1-2, item 2',
  'Preparation for cargo operations on gas tankers under the expanded program':
    'Preparation for cargo operations on gas tankers under the expanded program',
  'Captains, senior assistant captains, senior mechanics, second mechanics, as well as other persons directly responsible for cargo operations and cargo on gas tankers':
    'Captains, senior assistant captains, senior mechanics, second mechanics, as well as other persons directly responsible for cargo operations and cargo on gas tankers',
  'V/2, item 4, А-V/2, item 1': 'V/2, item 4, А-V/2, item 1',
  'Training in the management of unorganized masses of people':
    'Training in the management of unorganized masses of people',
  'Captains, crew members and other crew members of passenger ships assigned to assist passengers in the event of an emergency':
    'Captains, crew members and other crew members of passenger ships assigned to assist passengers in the event of an emergency',

  'V/2, item 5, А-V/2, item 2': 'V/2, item 5, А-V/2, item 2',
  'Safety training for staff providing direct passenger service in the passenger compartment':
    'Safety training for staff providing direct passenger service in the passenger compartment',
  'Passenger ship personnel who directly serve passengers in the passenger compartments':
    'Passenger ship personnel who directly serve passengers in the passenger compartments',
  'V/2, item 6, А-V/2, item 3': 'V/2, item 6, А-V/2, item 3',
  'Training on management and behavior of people in crisis situations':
    'Training on management and behavior of people in crisis situations',
  'Captains, senior mate assistants, senior mechanics, second engineers and other crew members of passenger ships responsible for the safety of passengers in the event of an emergency':
    'Captains, senior mate assistants, senior mechanics, second engineers and other crew members of passenger ships responsible for the safety of passengers in the event of an emergency',
  'V/2, item 7, А-V/2, item 4': 'V/2, item 7, А-V/2, item 4',
  'Training on passenger safety, cargo and hull waterproofing':
    'Training on passenger safety, cargo and hull waterproofing',
  Required: 'Required',
  'Captains, senior mate assistants, senior mechanics, second engineers and other crew members of passenger ships who have direct responsibility for the embarkation and disembarkation of passengers, loading, unloading or securing cargo or closing holes in the hull of ro-ro passenger ships':
    'Captains, senior mate assistants, senior mechanics, second engineers and other crew members of passenger ships who have direct responsibility for the embarkation and disembarkation of passengers, loading, unloading or securing cargo or closing holes in the hull of ro-ro passenger ships',
  'Yes**': 'Yes**',
  'B-V/a': 'B-V/a',
  'Additional training of captains and senior assistant captains of large vessels and vessels with unusual shunting characteristics':
    'Additional training of captains and senior assistant captains of large vessels and vessels with unusual shunting characteristics',
  'Captains, senior assistant captains': 'Captains, senior assistant captains',
  'B-V/b, B-V/c': 'B-V/b, B-V/c',
  'Training of commanders and privates responsible for cargo operations on ships carrying hazardous substances in bulk and in packaging':
    'Training of commanders and privates responsible for cargo operations on ships carrying hazardous substances in bulk and in packaging',
  'Persons of command and rank and file': 'Persons of command and rank and file',
  'B-V/d': 'B-V/d',
  'Training of personnel of marine mobile installations': 'Training of personnel of marine mobile installations',
  'Persons of command and rank and file of marine mobile installations':
    'Persons of command and rank and file of marine mobile installations',
  'В-V/е': 'В-V/е',
  'Additional training of captains and shift assistants of supply ship captains':
    'Additional training of captains and shift assistants of supply ship captains',
  'Captains and watchkeeping assistants of watchmen carrying a watch on ships supplying marine installations':
    'Captains and watchkeeping assistants of watchmen carrying a watch on ships supplying marine installations',
  27: '27',
  'В-V/f': 'В-V/f',
  'Operation of dynamic positioning systems for ships': 'Operation of dynamic positioning systems for ships',
  'Persons operating dynamic positioning systems for ships': 'Persons operating dynamic positioning systems for ships',
  'В-V/g': 'В-V/g',
  'Sailing in ice conditions - for captains, watchkeeping assistants, senior and watch mechanics of vessels operating in ice conditions and in polar waters':
    'Sailing in ice conditions - for captains, watchkeeping assistants, senior and watch mechanics of vessels operating in ice conditions and in polar waters',
  Recommended: 'Recommended',
  'Captains, watchkeeping assistants, senior and watch mechanics of ships operated in ice conditions and in polar waters':
    'Captains, watchkeeping assistants, senior and watch mechanics of ships operated in ice conditions and in polar waters',
  No: 'No',

  'If the period of work of a person on seagoing vessels in the position (positions) in accordance with the diploma (certificate of specialist) is generally not less than 12 months during the last 5 years preceding the day of application, retraining and proficiency testing are carried out under an abbreviated program. listed in item 4 of section A-VI / 1, items 6 and 12 of section A-VI / 2, as well as in item 6 of section A-VI / 3 of the STCW Code)':
    'If the period of work of a person on seagoing vessels in the position (positions) in accordance with the diploma (certificate of specialist) is generally not less than 12 months during the last 5 years preceding the day of application, retraining and proficiency testing are carried out under an abbreviated program. listed in item 4 of section A-VI / 1, items 6 and 12 of section A-VI / 2, as well as in item 6 of section A-VI / 3 of the STCW Code).',
  'If the period of work of a person on seagoing vessels in the position (positions) according to the diploma (certificate of specialist) is generally not less than 12 months during the last 5 years preceding the day of application, retraining is not required':
    'If the period of work of a person on seagoing vessels in the position (positions) according to the diploma (certificate of specialist) is generally not less than 12 months during the last 5 years preceding the day of application, retraining is not required.',

  'LIST OF REQUIREMENTS OF COMPULSORY TRAINING FOR GRADUATES OF COMMAND WARRANTY TO WORK IN THE APPROPRIATE POSITIONS ON VESSELS CARRYING ONLY ON PORT AND PROTECTED WATERS':
    'LIST OF REQUIREMENTS OF COMPULSORY TRAINING FOR GRADUATES OF COMMAND WARRANTY TO WORK IN THE APPROPRIATE POSITIONS ON VESSELS CARRYING ONLY ON PORT AND PROTECTED WATERS',

  'VI/1, A-VI/1, items 1, 2': 'VI/1, A-VI/1, items 1, 2',
  'Familiarization, initial training and safety briefing': 'Familiarization, initial training and safety briefing',
  "All members of the ship's crew": "All members of the ship's crew",

  'VI/2, item 1, A-VI/2, items 1-4': 'VI/2, item 1, A-VI/2, items 1-4',
  'Training of specialists in lifeboats, life rafts and duty boats that are not high-speed duty boats':
    'Training of specialists in lifeboats, life rafts and duty boats that are not high-speed duty boats',
  "Variable Captain's Assistants, Variable Assistant Captains-Mechanics, Variable Mechanics, Persons Appointed to Command These Rescue Means":
    "Variable Captain's Assistants, Variable Assistant Captains-Mechanics, Variable Mechanics, Persons Appointed to Command These Rescue Means",

  'VI/3, A-VI/3, items 1-4': 'VI/3, A-VI/3, items 1-4',
  'Preparation for firefighting according to the extended program':
    'Preparation for firefighting according to the extended program',
  "Variable Captain's Assistants, Variable Assistant Captains-Mechanics, Variable Mechanics, Persons Assigned to Manage Fire Fighting Operations":
    "Variable Captain's Assistants, Variable Assistant Captains-Mechanics, Variable Mechanics, Persons Assigned to Manage Fire Fighting Operations",

  'VI/4, item 1, A-VI/4, items 1-3': 'VI/4, item 1, A-VI/4, items 1-3',
  'First aid training': 'First aid training',
  'Captains, mechanic captains, shift assistants, shift assistants, senior mechanics, shift mechanics, persons assigned to provide first aid on board':
    'Captains, mechanic captains, shift assistants, shift assistants, senior mechanics, shift mechanics, persons assigned to provide first aid on board',

  'A-II/1, А-1/12, items 4, 5, В-1/12, items 2-35': 'A-II/1, А-1/12, items 4, 5, В-1/12, items 2-35',
  'Navigation training using radar, radar gasket and automatic radar gasket means (hereinafter - ARGM)':
    'Navigation training using radar, radar gasket and automatic radar gasket means (hereinafter - ARGM)',
  'Navigators whose diplomas are endorsed with the restriction "Except for ships equipped with ARGM equipment"':
    'Navigators whose diplomas are endorsed with the restriction "Except for ships equipped with ARGM equipment"',

  'V/1-1, items 1, 2, A-V/1-1, item 1, В-V/1-1': 'V/1-1, items 1, 2, A-V/1-1, item 1, В-V/1-1',
  'Initial preparation for cargo operations on oil tankers': 'Initial preparation for cargo operations on oil tankers',
  'Persons involved in cargo operations on oil tankers': 'Persons involved in cargo operations on oil tankers',

  'V/1-1, items 3, 4, A-V/1-1, item 2': 'V/1-1, items 3, 4, A-V/1-1, item 2',
  'Preparation for cargo operations on oil tankers under the expanded program':
    'Preparation for cargo operations on oil tankers under the expanded program',
  'Captains, mechanical captains, senior mechanics, shift mechanics, oil tanker dockermans':
    'Captains, mechanical captains, senior mechanics, shift mechanics, oil tanker dockermans',

  'Refund application': 'Refund application',

  "State Register of Seafarers' Documents of Ukraine": "State Register of Seafarers' Documents of Ukraine",
  'Tel./fax:': 'Tel./fax:',
  'E-mail:': 'E-mail:',
  'The state register is constantly developing and qualitatively improving. In 2002, an automated system of a single state register of seafarers of Ukraine was established and is now functioning. This system is aimed at ensuring clear interaction of the registry, state qualification commissions and services of port captains and allows electronic records to be kept up-to-date of all documents issued to seafarers, to check the documents for legitimacy and to virtually eliminate the possibility of the appearance of illegitimate documents. Such measures meet both national and international modern requirements in the fight against counterfeit documents. Non-legitimate documents may theoretically appear. Counterfeiters have always been and will be. However, sooner or later, as a result of document verification by the National Qualifications Commission, or at the request of a shipping or crewing company or port authority of any country, this document will be required. Currently, virtually all organizations, government agencies in the world are interested in the fact that only seafarers with the lawfully obtained documents work at sea. This is especially true now, with the grave danger of terrorism and the introduction of the OSCE Code':
    'The state register is constantly developing and qualitatively improving. In 2002, an automated system of a single state register of seafarers of Ukraine was established and is now functioning. This system is aimed at ensuring clear interaction of the registry, state qualification commissions and services of port captains and allows electronic records to be kept up-to-date of all documents issued to seafarers, to check the documents for legitimacy and to virtually eliminate the possibility of the appearance of illegitimate documents. Such measures meet both national and international modern requirements in the fight against counterfeit documents. Non-legitimate documents may theoretically appear. Counterfeiters have always been and will be. However, sooner or later, as a result of document verification by the National Qualifications Commission, or at the request of a shipping or crewing company or port authority of any country, this document will be required. Currently, virtually all organizations, government agencies in the world are interested in the fact that only seafarers with the lawfully obtained documents work at sea. This is especially true now, with the grave danger of terrorism and the introduction of the OSCE Code.',

  'Computer testing of seafarers': 'Computer testing of seafarers',
  'From 01.03.2018 the Inspectorate has introduced a new PROCEDURE for conducting examinations of commanders and shipboard teams of ships at state qualification commissions. According to this Procedure, the examination consists of the following stages:':
    'From 01.03.2018 the Inspectorate has introduced a new PROCEDURE for conducting examinations of commanders and shipboard teams of ships at state qualification commissions. According to this Procedure, the examination consists of the following stages:',
  'computer-based testing using the MORTEST Automated Testing System (hereinafter referred to as test) for individual categories of command and seagoing ships;':
    'computer-based testing using the MORTEST Automated Testing System (hereinafter referred to as test) for individual categories of command and seagoing ships;',
  'a written survey (where appropriate);': 'a written survey (where appropriate);',
  'verbal examination;': 'verbal examination;',
  'adjudication by the SQC of a seafarer’s competence in accordance with established standards of office and / or responsibility':
    'adjudication by the SQC of a seafarer’s competence in accordance with established standards of office and / or responsibility.',

  'LIST OF POSITIONS OF INDIVIDUAL CATEGORIES OF COMMAND AND VESSEL TEAMS FOR COMPUTER TESTS':
    'LIST OF POSITIONS OF INDIVIDUAL CATEGORIES OF COMMAND AND VESSEL TEAMS FOR COMPUTER TESTS',

  '1. Watchkeeping assistants for ships with a gross capacity of 500 or more':
    '1. Watchkeeping assistants for ships with a gross capacity of 500 or more.',
  '2. Captains and senior assistants of the captain of vessels with a gross capacity of 500 or more':
    '2. Captains and senior assistants of the captain of vessels with a gross capacity of 500 or more.',
  '3. Watchkeeping assistants and captains of vessels of less than 500 engaged in coastal navigation':
    '3. Watchkeeping assistants and captains of vessels of less than 500 engaged in coastal navigation.',
  '4. Watchmaking mechanics of ships with serviced or intermittent machinery':
    '4. Watchmaking mechanics of ships with serviced or intermittent machinery.',
  '5. Senior and second mechanics of vessels with main propulsion of 3000 kW or more':
    '5. Senior and second mechanics of vessels with main propulsion of 3000 kW or more.',
  '6. Electromechanics': '6. Electromechanics.',
  '7. GMES radio operators (general diploma)': '7. GMES radio operators (general diploma).',
  '8. GMES radio operators (limited diploma)': '8. GMES radio operators (limited diploma).',
  '9. Initial Tanker Training (All Positions)': '9. Initial Tanker Training (All Positions).',
  '10. Advanced training in tankers (all positions)': '10. Advanced training in tankers (all positions).',
  'Computer testing is performed under the supervision of the test Secretary. During testing, the test Secretary is required: – provide explanations to test takers regarding the conditions and procedures for testing; – ensure that the test procedures are followed by the test takers; – take measures to prevent communication between test takers, the use of their mobile phones, tablets, any technical equipment and other sources of information. If such facts are found, remove persons from further testing':
    'Computer testing is performed under the supervision of the test Secretary. During testing, the test Secretary is required: – provide explanations to test takers regarding the conditions and procedures for testing; – ensure that the test procedures are followed by the test takers; – take measures to prevent communication between test takers, the use of their mobile phones, tablets, any technical equipment and other sources of information. If such facts are found, remove persons from further testing.',
  'Computer testing time is set within 60 minutes, and for positions involving the use of video and audio files (GMES Radio Operator (General Diploma) and GMES Radio Operator (Limited Diploma)) – 65 minutes. The number of attempts is one':
    'Computer testing time is set within 60 minutes, and for positions involving the use of video and audio files (GMES Radio Operator (General Diploma) and GMES Radio Operator (Limited Diploma)) – 65 minutes. The number of attempts is one.',
  'If the number of correct answers of the Seafarer during computer testing is 80 percent or more in each section, the test result is considered excellent, from 60 to 79 percent – satisfactory, less than 60 percent – unsatisfactory':
    'If the number of correct answers of the Seafarer during computer testing is 80 percent or more in each section, the test result is considered excellent, from 60 to 79 percent – satisfactory, less than 60 percent – unsatisfactory.',
  'Each tested, after passing the test, is acquainted with the number of points scored by him and receives a protocol signed by the secretary of the test':
    'Each tested, after passing the test, is acquainted with the number of points scored by him and receives a protocol signed by the secretary of the test.',
  'A sailor who has received an excellent or satisfactory test result shall be exempted from the written survey and his / her further competence shall be verified by oral examination at the SQC':
    'A sailor who has received an excellent or satisfactory test result shall be exempted from the written survey and his / her further competence shall be verified by oral examination at the SQC.',
  'The written examination is conducted under the supervision of the head or one of the SQC members for 20 minutes. At the end of the set time, the exam papers are handed over to the SQC':
    'The written examination is conducted under the supervision of the head or one of the SQC members for 20 minutes. At the end of the set time, the exam papers are handed over to the SQC.',
  'Oral interviewing takes the form of a dialogue between the SQC and the examiner and involves:':
    'Oral interviewing takes the form of a dialogue between the SQC and the examiner and involves:',
  '– answers to the written questions on the examination sheet (for persons who have passed the written survey);':
    '– answers to the written questions on the examination sheet (for persons who have passed the written survey);',
  '– answers to questions on topics recorded in the SQC Competence Assessment Record, selected in accordance with the functions and areas of competence defined in the VAT Code':
    '– answers to questions on topics recorded in the SQC Competence Assessment Record, selected in accordance with the functions and areas of competence defined in the VAT Code.',
  'The number of questions should not exceed 3 units for each function. The total number of questions should not exceed 12 units':
    'The number of questions should not exceed 3 units for each function. The total number of questions should not exceed 12 units.',
  'If the person who took the written survey provided incorrect answers to two or more questions from the exam sheet, he / she is considered to have failed the exam':
    'If the person who took the written survey provided incorrect answers to two or more questions from the exam sheet, he / she is considered to have failed the exam.',
  'After hearing the answers of the examiner, the SQC has the right to ask them additional questions in accordance with the functions and areas of competence set out in the VAT Code':
    'After hearing the answers of the examiner, the SQC has the right to ask them additional questions in accordance with the functions and areas of competence set out in the VAT Code.',
  'If the examiner disagrees with his or her competence in the oral examination, the SQC offers a written answer to the committee’s oral questions (no more than three)':
    'If the examiner disagrees with his or her competence in the oral examination, the SQC offers a written answer to the committee’s oral questions (no more than three).',
  'The decisions of the SQC are formulated in the minutes of the SQC meeting, which consists of two original copies. One copy of the SQC meeting minutes shall be issued to the seafarer':
    'The decisions of the SQC are formulated in the minutes of the SQC meeting, which consists of two original copies. One copy of the SQC meeting minutes shall be issued to the seafarer.',

  "Seaman's record book of service": "Seaman's record book of service",
  'For those who have maritime working papers:': 'For those who have maritime working papers:',
  'Passport of a citizen of Ukraine (or passport of a citizen of another country);':
    'Passport of a citizen of Ukraine (or passport of a citizen of another country);',
  'Seafarer ID (if any);': 'Seafarer ID (if any);',
  'Diploma or Certificate of Qualification (working papers);':
    'Diploma or Certificate of Qualification (working papers);',
  'Two matt photos 3х4': 'Two matt photos 3х4.',
  'For marine cadets and students:': 'For marine cadets and students:',
  'Passport of a citizen of Ukraine (or passport of a citizen of another country);':
    'Passport of a citizen of Ukraine (or passport of a citizen of another country);',
  'Seafarer ID (if any);': 'Seafarer ID (if any);',
  'Certificate on Safety Acquaintance, Initial Training and Instruction;':
    'Certificate on Safety Acquaintance, Initial Training and Instruction;',
  'The student’s record book or a valid student flower, or a certificate from an educational institution certifying the completion of studies at that institution;':
    'The student’s record book or a valid student flower, or a certificate from an educational institution certifying the completion of studies at that institution;',
  'Two matt photos 3х4': 'Two matt photos 3х4.',

  'Certification of employees of the subjects of dangerous goods transportation':
    'Certification of employees of the subjects of dangerous goods transportation',
  'According to Order of the Ministry of Infrastructure of Ukraine № 288 dated 21.08.2017 “On the determination of the Inspectorate for the Preparation and Certification of Seafarers by the body authorized to test the knowledge of employees of the subjects of carriage of dangerous goods of all forms of ownership of maritime and inland waterway transport and to issue a Certificate” The Inspectorate of Training and Certification of Seafarers is the body authorized to carry out the knowledge testing of employees of dangerous goods transporters of all qualification levels, who carry out all types of work with dangerous cargoes 1, 2, 3, 4, 5, 6, 8 and 9 classes at sea. and river transport':
    'According to Order of the Ministry of Infrastructure of Ukraine № 288 dated 21.08.2017 “On the determination of the Inspectorate for the Preparation and Certification of Seafarers by the body authorized to test the knowledge of employees of the subjects of carriage of dangerous goods of all forms of ownership of maritime and inland waterway transport and to issue a Certificate” The Inspectorate of Training and Certification of Seafarers is the body authorized to carry out the knowledge testing of employees of dangerous goods transporters of all qualification levels, who carry out all types of work with dangerous cargoes 1, 2, 3, 4, 5, 6, 8 and 9 classes at sea. and river transport.',
  'Examination of knowledge (in the form of examinations) is carried out by employees who have completed training in the center of special training of workers of the subjects of transportation of dangerous goods, determined by the Ministry of Infrastructure of Ukraine in accordance with the Procedure for determining the center of special training of employees of the subjects of transportation of dangerous goods, approved by the order of the Ministry of Transport and Communications dated July 8, 2008 No. 867 and registered with the Ministry of Justice of Ukraine on August 14, 2008 under No. 755/15447, and received a certificate of training':
    'Examination of knowledge (in the form of examinations) is carried out by employees who have completed training in the center of special training of workers of the subjects of transportation of dangerous goods, determined by the Ministry of Infrastructure of Ukraine in accordance with the Procedure for determining the center of special training of employees of the subjects of transportation of dangerous goods, approved by the order of the Ministry of Transport and Communications dated July 8, 2008 No. 867 and registered with the Ministry of Justice of Ukraine on August 14, 2008 under No. 755/15447, and received a certificate of training.',
  'The Inspectorate conducts proficiency testing at the following qualification levels:':
    'The Inspectorate conducts proficiency testing at the following qualification levels:',
  'worker;': 'worker;',
  'specialist;': 'specialist;',
  head: 'head.',
  'Examinations are allowed for employees in the presence of a passport or a document certifying their identity and certificates of completion of training in the transport of dangerous goods':
    'Examinations are allowed for employees in the presence of a passport or a document certifying their identity and certificates of completion of training in the transport of dangerous goods.',
  'The following are added to the inspection of the Inspectorate:':
    'The following are added to the inspection of the Inspectorate:',
  'examinations are allowed for employees in the presence of a passport or a document certifying their identity and certificates of completion of training in the transport of dangerous goods':
    'examinations are allowed for employees in the presence of a passport or a document certifying their identity and certificates of completion of training in the transport of dangerous goods.',
  'the following are added to the inspection of the Inspectorate: a statement on the verification of knowledge of employees of the subject of transportation of dangerous goods and obtaining a certificate;':
    'the following are added to the inspection of the Inspectorate: a statement on the verification of knowledge of employees of the subject of transportation of dangerous goods and obtaining a certificate;',
  'certificate of completion of training on the transport of dangerous goods at a designated center;':
    'certificate of completion of training on the transport of dangerous goods at a designated center;',
  'information about an individual’s identification number;':
    'information about an individual’s identification number;',
  'information on the position of the employee;': 'information on the position of the employee;',
  'two 3×4 matte photo cards': 'two 3×4 matte photo cards.',
  'In case of successful passing of examinations by employees, the Inspectorate draws up and issues corresponding certificates confirming the level of knowledge of employees of the subjects of transportation of dangerous goods':
    'In case of successful passing of examinations by employees, the Inspectorate draws up and issues corresponding certificates confirming the level of knowledge of employees of the subjects of transportation of dangerous goods.',

  'Approved training centers': 'Approved training centers',
  'You can download the List of approved training institutions, enterprises, organizations and institutions approved':
    'You can download the List of approved training institutions, enterprises, organizations and institutions approved',
  'Order of the Ministry of Infrastructure of Ukraine of November 19, 2021 № 628 "On Amendments to the List of Approved Training Institutions"':
    'Order of the Ministry of Infrastructure of Ukraine of November 19, 2021 № 628 "On Amendments to the List of Approved Training Institutions"',
  'training in which meets the requirements of the International Convention on Training and Certification of Seafarers and Watchkeeping of 1978, as amended, and national requirements':
    'training in which meets the requirements of the International Convention on Training and Certification of Seafarers and Watchkeeping of 1978, as amended, and national requirements.',

  'Approved health care facilities': 'Approved health care facilities',
  'Order of the Ministry of Health of Ukraine dated 28.09.2021 № 2096':
    'Order of the Ministry of Health of Ukraine dated 28.09.2021 № 2096',
  '"On approval of the List of health care institutions, chairmen of commissions and deputy chairmen commissions entitled to conduct medical examinations of seafarers ", that ':
    '"On approval of the List of health care institutions, chairmen of commissions and deputy chairmen commissions entitled to conduct medical examinations of seafarers ", that ',
  attached: 'attached',

  'Approved Institutions of Advanced Training Courses': 'Approved Institutions of Advanced Training Courses',
  'Educational and Scientific Kyiv Training Center for training, retraining and advanced training of Water Transport Specialists of the State University of Infrastructure and Technologies.':
    'Educational and Scientific Kyiv Training Center for training, retraining and advanced training of Water Transport Specialists of the State University of Infrastructure and Technologies.',
  '9 Kyrylivska Street, Kyiv, 04071': '9 Kyrylivska Street, Kyiv, 04071',
  '25 Olenivska Street, Kyiv, 04080': '25 Olenivska Street, Kyiv, 04080',
  'National University "Odessa Maritime Academy" (Institute of Postgraduate Education "Center for Training and Certification of Seafarers")':
    'National University "Odessa Maritime Academy" (Institute of Postgraduate Education "Center for Training and Certification of Seafarers")',
  '8 Didrikhsona Street, Odesa, 65029': '8 Didrikhsona Street, Odesa, 65029',
  '16 Pastera Street, Odesa, 65026': '16 Pastera Street, Odesa, 65026',
  'National University "Odessa Maritime Academy" (Danube Institute of National University "Odessa Maritime Academy")':
    'National University "Odessa Maritime Academy" (Danube Institute of National University "Odessa Maritime Academy")',
  '9 Fanahoriiska Street, Izmail, Odesa region, 68607': '9 Fanahoriiska Street, Izmail, Odesa region, 68607',
  'Institute of the Naval Forces of the National University "Odessa Maritime Academy"':
    'Institute of the Naval Forces of the National University "Odessa Maritime Academy"',
  '8 Didrikhsona Street, Odesa, 65029': '8 Didrikhsona Street, Odesa, 65029',
  'Private Higher Education Institutions "Odessa Maritime Institute in the form of a LLC"':
    'Private Higher Education Institutions "Odessa Maritime Institute in the form of a LLC"',
  '62 Yashy Hordiienko Street, Odesa, 65006': '62 Yashy Hordiienko Street, Odesa, 65006',
  'Private organization (institution, bureau) "Ukrainian Institute of Water Transport Professionals"':
    'Private organization (institution, bureau) "Ukrainian Institute of Water Transport Professionals"',
  '7 Prymorska Street, Burlacha Balka village, Сhornomorsk, Odesa region, 68094':
    '7 Prymorska Street, Burlacha Balka village, Сhornomorsk, Odesa region, 68094',
  'Kherson State Maritime Academy (Center for Training, Postgraduate Education and Advanced Training)':
    'Kherson State Maritime Academy (Center for Training, Postgraduate Education and Advanced Training)',
  '20 Ushakova Avenue, Kherson, 73000': '20 Ushakova Avenue, Kherson, 73000',

  'Details for payment of Inspectorate services': 'Details for payment of Inspectorate services',
  Recipient: 'Recipient',
  'The Inspectorate for the Training and Certification of Seafarers, 3 Proviantska Street, Kyiv, 04116':
    'The Inspectorate for the Training and Certification of Seafarers, 3 Proviantska Street, Kyiv, 04116',
  'EDRPOU code': 'EDRPOU code',
  '25958804;': '25958804;',
  IBAN: 'IBAN',
  '№ UA673052990000026000035022885': '№ UA673052990000026000035022885',
  "Recipient's bank": "Recipient's bank",
  'PJSC CB «Privatbank»': 'PJSC CB «Privatbank»',

  'For example:': 'For example:',
  "Name of the service for confirming the Qualification of a seafarer/registration Seaman's Seagoing Service Record Book; full name of the service recipient – seafarer; seafarer'S ID; Individual seaman's Tax Number; payer's full name; Individual payer's Tax Number; including VAT":
    "Name of the service for confirming the Qualification of a seafarer/registration Seaman's Seagoing Service Record Book; full name of the service recipient – seafarer; seafarer'S ID; Individual seaman's Tax Number; payer's full name; Individual payer's Tax Number; including VAT.",
  "Seafarers ' Qualification Confirmation services:": "Seafarers ' Qualification Confirmation services:",
  Captain: 'Captain',
  'Full name of the service recipient – Seafarer;': 'Full name of the service recipient – Seafarer;',
  "Seafarer'S ID;": "Seafarer'S ID;",
  "Individual Seaman's Tax Number;": "Individual Seaman's Tax Number;",
  "Payer's full name;": "Payer's full name;",
  "Individual Payer's Tax Number": "Individual Payer's Tax Number",
  '(All payment information should be specify in Ukrainian)':
    '(All payment information should be specify in Ukrainian)',
  'Payment amount': 'Payment amount',
  UAH: 'UAH',
  '(including VAT)': '(including VAT)',
  'Chief Engineer': 'Chief Engineer',
  'Payment amount - 1318,8 UAH (including VAT)': 'Payment amount - 1318,8 UAH (including VAT)',
  Navigator: 'Navigator',
  'Navigators: Chief Engineer, 2nd Engineer, 3rd Engineer': 'Navigators: Chief Engineer, 2nd Engineer, 3rd Engineer',
  'Navigator and Engineer of a low-tonnage vessel, Captain of coastal navigation, Captain of a pleasure vessel':
    'Navigator and Engineer of a low-tonnage vessel, Captain of coastal navigation, Captain of a pleasure vessel',
  '2nd Engineer, 3nd Engineer': '2nd Engineer, 3nd Engineer',
  'Low-tonnage vessel engineer': 'Low-tonnage vessel engineer',
  'Electrical Engineer': 'Electrical Engineer',
  'Electromechanic of the 2nd, 3rd degrees': 'Electromechanic of the 2nd, 3rd degrees',
  '2nd, 3rd Refrigerator Engineer': '2nd, 3rd Refrigerator Engineer',
  'Сhef Radio operator, 2nd Radio operator': 'Сhef Radio operator, 2nd Radio operator',
  'Cargo officer': 'Cargo officer',
  "Ship's security officer and other command staff members": "Ship's security officer and other command staff members",
  "Seaman and other persons of the ship's crew": "Seaman and other persons of the ship's crew",
  "Services for registration of the Seaman's Seagoing Service Record Book":
    "Services for registration of the Seaman's Seagoing Service Record Book",
  "To pay for a book with records of the seafarer's work, follow the format of filling in:":
    "To pay for a book with records of the seafarer's work, follow the format of filling in:",
  'For proper display in the accounting system and identification of the person receiving the services, it is necessary to adhere to this format of filling out the purpose of payment':
    'For proper display in the accounting system and identification of the person receiving the services, it is necessary to adhere to this format of filling out the purpose of payment.',
  'Chief refrigerator engineer': 'Chief refrigerator engineer',
  'GMDSS Radio operator': 'GMDSS Radio operator',

  'The inspection for the preparation and graduation of sailors has introduced electronic document submission for sailors to confirm their qualifications in the State Qualification Commission using software':
    'The inspection for the preparation and graduation of sailors has introduced electronic document submission for sailors to confirm their qualifications in the State Qualification Commission using software.',
  'The Inspection of the Preparation and Graduation of Sailors (IPDM) is involved in matters related to the preparation, graduation, obtaining necessary certificates, and the service record book for Ukrainian sailors':
    'The Inspection of the Preparation and Graduation of Sailors (IPDM) is involved in matters related to the preparation, graduation, obtaining necessary certificates, and the service record book for Ukrainian sailors.',
  'The Inspection of the Preparation and Graduation of Sailors (IPDM) deals with issues concerning the preparation, graduation, obtaining necessary certificates, and the service record book for Ukrainian sailors':
    'The Inspection of the Preparation and Graduation of Sailors (IPDM) deals with issues concerning the preparation, graduation, obtaining necessary certificates, and the service record book for Ukrainian sailors.',
  "News from the unified transport portal of digital services includes announcements of updates to the sailor's electronic cabinet, reports on financial results, and other announcements from Ukraine regarding the preparation and graduation of Ukrainian sailors":
    "News from the unified transport portal of digital services includes announcements of updates to the sailor's electronic cabinet, reports on financial results, and other announcements from Ukraine regarding the preparation and graduation of Ukrainian sailors.",
  'NOTE on the progress of preparation and signing of Memoranda on the recognition of seafarers diplomas in accordance with Rule I/10 of the PDNV Convention':
    "NOTE on the progress of preparation and signing of Memoranda on the recognition of seafarers's diplomas in accordance with Rule I/10 of the PDNV Convention",
  beginning: 'Beginning',
  next: 'Next',
  prev: 'Prev',
  end: 'End',
  'The new personal cabinet is accessible on all devices that support web browsers and includes all the features of the mobile app "Seafarer\'s Electronic Cabinet":':
    'The new personal cabinet is accessible on all devices that support web browsers and includes all the features of the mobile app "Seafarer\'s Electronic Cabinet":'
}
