<template lang="pug">
v-app
  v-app-bar(heigth="72" app)
    div(@click="handleClickCloseMenu").overlay
    MobileMenu
    Header
    //- BviPanel
  v-main.pa-0
    router-view
  v-footer.pa-0
    Footer.w-100
</template>

<script>
// import Menu from '@/components/Menu'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Loader from '@/views/Loader'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    // Menu,
    Header,
    Loader,
    Footer,
    MobileMenu: () => import('@/components/MobileMenu')
  },
  data () {
    return {
      drawer: null,
      isContentBoxed: false,
      isGuestRoute: true
    }
  },
  computed: {
    ...mapState({
      isLoadApp: state => state.app.isLoad
    }),
    theme () {
      return 'light'
    }
  },
  mounted () {
    document.addEventListener('click', event => {
      if (this.$route.name === 'about-inspection') return 
      let classList = new Array([...event?.target?.parentNode?.classList]) || []
      if (!classList[0]?.includes('active')) {
        event.target.parentNode.classList.add("active");
      } else event.target.parentNode.classList.remove("active");
    })
  },
  destroyed () {
    document.removeEventListener('click', () => {})
  },
  methods: {
    handleClickCloseMenu () {
      let navxs = document.getElementsByClassName('navxs')
      navxs[0]?.classList?.remove('active')
      let overlay = document?.getElementsByClassName('overlay')
      overlay[0]?.classList?.remove('active')
    }
  }
};
</script>
