<template>
  <header class="header">
    <div class="container">
      <div class="header-logo">
        <router-link to="/">
          <img
            v-if="$i18n.locale === 'uk'"
            src="@/assets/images/logo.svg"
            alt="logo.svg"
            width="96"
            height="72"
          >
          <img
            v-else
            src="@/assets/images/logo-eng.svg"
            alt="logo.svg"
            width="96"
            height="72"
          >
        </router-link>
      </div>
      <div class="header-nav">
        <ul>	
          <li>
            <router-link
              :to="{ name: 'main' }"
              :active-class="$route.name === 'main' ? 'item-selected': ''"
            >
              {{ $t('Home') }}
            </router-link>
          </li>
          <li class="submenu">
            <router-link
              :to="{ name: 'about-inspection'}"
              class="submenu-link"
              :active-class="[
                'about-inspection',
                'about-inspection-leadership',
                'about-inspection-missions',
                'about-inspection-diploma-path-in-ukraine',
                'about-inspection-regulatory-base'].includes($route.name) ? 'item-selected': ''"
            >
              {{ $t('About inspection') }}
            </router-link>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'about-inspection' }"
                >
                  {{ $t('About inspection') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'about-inspection-leadership' }"
                >
                  {{ $t('Leadership') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'about-inspection-missions' }"
                >
                  {{ $t('Mission') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'about-inspection-diploma-path-in-ukraine' }"
                >
                  {{ $t('The path of certification') }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'about-inspection-regulatory-base' }"
                >
                  {{ $t('Normative documents') }}
                </router-link>
              </li>
            </ul>
          </li>	
          <li class="submenu">
            <router-link
              :to="{ name: 'for-sailor-information' }"
              class="submenu-link"
              :active-class="[
                'for-sailor-information',
                'for-sailor-services',
                'for-sailor-compleate-branch-offices',
                'for-sailor-compleate-medical-institution',
                'for-sailor-compleate-institution',
                'for-sailor-payment-details-services'
              ].includes($route.name) ? 'item-selected': ''"
            >
              {{ $t('For seafarers') }}
            </router-link>
            <ul>
              <li>
                <router-link :to="{ name: 'for-sailor-information' }">
                  {{ $t('Information') }}
                </router-link>
              </li>
              <li>
                <a
                  :href="require('@/assets/pdf/algoritm-doc.pdf').default"
                  download="algoritm-doc.pdf"
                >{{ $t("Algorithm for obtaining seafarers' working documents") }}</a>
              </li>
              <li>
                <router-link :to="{ name: 'for-sailor-services' }">
                  {{ $t('Services') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'for-sailor-compleate-branch-offices' }">
                  {{ $t('Approved training centers') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'for-sailor-compleate-medical-institution' }">
                  {{ $t('Approved health care facilities') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'for-sailor-payment-details-services' }">
                  {{ $t('Details for payment of inspectorate services') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'for-sailor-compleate-institution' }">
                  {{ $t("Approved Institutions of ATC") }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="submenu">
            <router-link
              :to="{ name: 'for-the-public' }"
              class="submenu-link"
              :active-class="$route.name === 'for-the-public' ? 'item-selected': ''"
            >
              {{ $t('For the public') }}
            </router-link>
            <ul>
              <li>
                <router-link :to="{ name: 'for-the-public' }">
                  {{ $t('Citizens appointment schedule') }}
                </router-link>
              </li>
            </ul>
          </li>	
          <li class="submenu">
            <router-link 
              :to="{ name: 'sqc-cabinet' }"
              class="submenu-link"
              :active-class="[
                'sqc-cabinet',
                'sqc-list-of-documents',
                'sqc-address-branch-offices',
                'sqc-ces-statistycs',
                'sqc-expirience-statistycs',
                'sqc-shedule'
              ].includes($route.name) ? 'item-selected': ''"
            >
              {{ $t('SQC') }}
            </router-link>
            <ul>
              <li>
                <router-link :to="{ name: 'sqc-cabinet' }">
                  {{ $t("SQC member's cabinet") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sqc-list-of-documents' }">
                  {{ $t('List of documents for SQC') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sqc-address-branch-offices' }">
                  {{ $t('SQC address') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sqc-ces-statistycs' }">
                  {{ $t('CES 6.0 statistics') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sqc-expirience-statistycs' }">
                  {{ $t('Statistics of getting endorsement by experience confirmation') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sqc-shedule' }">
                  {{ $t('SQC work schedule') }}
                </router-link>
              </li>
            </ul>
          </li>	
          <li>
            <router-link
              :to="{ name: 'verification' }"
              :active-class="$route.name === 'verification' ? 'item-selected': ''"
            >
              {{ $t('Verification') }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'news', query: { page: 1 } }"
              :active-class="[
                'news',
                'news-details'
              ].includes($route.name) ? 'item-selected': ''"
            >
              {{ $t('News') }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'contacts' }"
              :active-class="$route.name === 'contacts' ? 'item-selected': ''"
            >
              {{ $t('Contacts') }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header-action">
        <div class="bvi-open bvi-hide bvi-setup cursor-pointer">
          <img
            src="@/assets/images/glasses.svg"
            alt="glasses"
          >
        </div>
        <div class="header-lang">
          <div class="header-lang__active">
            <div
              v-if="$i18n.locale === 'uk'"
              class="d-flex align-center"
            >
              <i class="icon-flagUA" /> Укр <i class="icon-path" />
            </div>
            <div
              v-else
              class="d-flex align-center"
            >
              <i class="icon-flagEN" /> Eng <i class="icon-path" />
            </div>
          </div>
          <ul>
            <li
              @click="changedLang('uk')"
            >
              <span
                class="d-flex align-center"
              >
                <i class="icon-flagUA" /> Українська</span>
            </li>
            <li @click="changedLang('en')">
              <span
                class="d-flex align-center"
              >
                <i class="icon-flagEN" /> English</span>
            </li>
          </ul>
        </div>
        <div
          class="header-burger"
          @click="handleClickOpenMenu"
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import i18n from '@/plugins/vue-i18n'

export default {
  name: 'Header',
  data () {
    return {
      isToolbarDetached: '',
      toolbarTheme: 'light',
      showSearch: false
    }
  },
  computed: {
    ...mapState({
      bviTheme: state => state.app.bviTheme
    }),
    title () {
      switch (this.$route.name) {
        case 'main': return 'menu.main'
        default: return 'menu.notFound'
      }
    }
    
  },
  mounted() {
    this.setupBvi()
  },
  methods: {
    out () {
      location = '/login'
    },
    changedLang (lang) {
      // this.SET_STATE_DATA_MAIN({ lang: lang })
      lang === 'bo' ? (this.isLang = false) : (this.isLang = true)
      i18n.locale = lang
      sessionStorage.setItem('sessionLang', lang)
      document.title = this.$route?.meta?.title || ''
      const descEl = document.querySelector('meta[name="description"]')
      descEl?.setAttribute('content', i18n.t(this.$route?.meta?.description))
    },
    handleClickOpenMenu () {
      let navxs = document.getElementsByClassName('navxs')
      navxs[0].classList.add('active')
      let overlay = document.getElementsByClassName('overlay')
      overlay[0].classList.add('active')
    },
    async setupBvi () { // Initializing a Module with a Theme for Visually Impaired People
      await new window.isvek.Bvi({ speech: true, builtElements: true, lang: 'uk-UA', fontFamily: 'times' })
    },
    setNoStyleClasses () {
      if (this.bviTheme) {
        const noStyleClasses = '.v-dialog__content, .v-input--switch__track, .v-input--switch__thumb, .v-btn__content'
        for (const el of document.querySelectorAll(noStyleClasses)) {
          if (!el.classList.contains('bvi-no-styles')) el.classList.add('bvi-no-styles')
        }
      }
    }
    
  }
}
</script>
<style>
html body .bvi-panel {
    /* font-family: Arial,serif!important; */
    top: 75px !important
}
</style>