/* eslint-disable vue/max-len */
import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/vue-i18n'

Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    meta: {
      title: 'THE INSPECTORATE FOR TRAINING AND CERTIFICATION OF SEAFARERS',
      description: `The inspectorate for the training and certification of seafarers implemented electronic submission of seafarers' documents for the purpose of confirming qualifications at the State Qualification Commission using software`
    },
    component: () => import('@/views/NotFound')
  },
  {
    path: '/',
    name: 'main',
    meta: {
      title: 'THE INSPECTORATE FOR TRAINING AND CERTIFICATION OF SEAFARERS',
      description: `The inspectorate for the training and certification of seafarers implemented electronic submission of seafarers' documents for the purpose of confirming qualifications at the State Qualification Commission using software`
    },
    component: () => import('@/views/Main')
  },
  // {
  //   path: '/crew',
  //   name: 'crew',
  //   meta: {
  //     title: 'hello'
  //   },
  //   component: () => import('@/views/Main')
  // },
  // {
  //   path: '/eti',
  //   name: 'eti',
  //   meta: {
  //     title: 'hello'
  //   },
  //   component: () => import('@/views/Main/SectionRegistrationEti')
  // },
  {
    path: '/pro-inspektsiyu',
    name: 'about-inspection',
    meta: {
      title: 'About Inspectorate for the Training and Certification of Seafarers',
      description: 'Over the past years, since its inception, the Inspectorate for the Training and Certification of Seafarers (ITCS) has done a lot of work to improve the national system of training and certification of seafarers, to create a regulatory framework consistent with international requirements'
    },
    component: () => import('@/views/About')
  },
  {
    path: '/pro-inspektsiyu/kerivnitstvo',
    name: 'about-inspection-leadership',
    meta: {
      title: 'Leadership ITCS',
      description: 'Director of the State Register of Seafarers’ Documents of Ukraine, Deputy Chief of Inspectorate, Regional Branch Director'
    },
    component: () => import('@/views/Leadership')
  },
  {
    path: '/pro-inspektsiyu/misiya',
    name: 'about-inspection-missions',
    meta: {
      title: 'Inspection mission for seafarers training and certification',
      description: 'The mission of the Inspectorate for the Preparation and Certification of Seafarers is to accomplish the tasks assigned to represent and protect the interests of Ukraine in the issues of training, assessment and certification of seafarers'
    },
    component: () => import('@/views/Missions')
  },
  {
    path: '/pro-inspektsiyu/shlyakh-diplomuvannya-v-ukraini',
    name: 'about-inspection-diploma-path-in-ukraine',
    meta: {
      title: 'The path of diploma in Ukraine',
      description: 'Ukraine became a Member State of the International Maritime Organization (IMO) and a country that signed the main international regulations on safety of maritime navigation adopted within the framework of the IMO'
    },
    component: () => import('@/views/DiplomInUkraine')
  },
  {
    path: '/pro-inspektsiyu/diyalnist',
    name: 'about-inspection-regulatory-base',
    meta: {
      title: 'Activities Inspectorate for the Training and Certification of Seafarers',
      description: 'Legislation acts, public procurement, anti-corruption activities Inspectorate for the Training and Certification of Seafarers (ITCS)'
    },
    component: () => import('@/views/RegulatoryBase')
  },
  {
    path: '/dlya-moryakiv/informatsiya',
    name: 'for-sailor-information',
    meta: {
      title: 'Composition of state qualification commissions',
      description: 'Inform us about all known the facts of bribery, corruption and other unlawful actions of the employees of the Inspection'
    },
    component: () => import('@/views/Sailor/Information')
  },
  {
    path: '/dlya-moryakiv/poslugi',
    name: 'for-sailor-services',
    meta: {
      title: 'Unified State Register of Documents of Seafarers of Ukraine',
      description: 'The state register is constantly developing and qualitatively improving. In 2002, an automated system of a single state register of seafarers of Ukraine was established and is now functioning'
    },
    component: () => import('@/views/Sailor/Services')
  },
  {
    path: '/dlya-moryakiv/skhvaleni-tsentri-pidgotovki',
    name: 'for-sailor-compleate-branch-offices',
    meta: {
      title: 'Approved training centers',
      description: 'The list of approved education and training institutions, which are registered as economic entities in Ukraine and have a quality management system in the field of seafarers training in accordance with section A-I / 8 of the Annex to the Convention on STCWS'
    },
    component: () => import('@/views/Sailor/BranchOffices')
  },
  {
    path: '/dlya-moryakiv/zdorovia',
    name: 'for-sailor-compleate-medical-institution',
    meta: {
      title: 'Approved health care facilities',
      description: 'On approval of the List of health care institutions, chairmen of commissions and deputy chairmen commissions entitled to conduct medical examinations of seafarers'
    },
    component: () => import('@/views/Sailor/MedicalInstitutions')
  },
  {
    path: '/dlya-moryakiv/institutions',
    name: 'for-sailor-compleate-institution',
    meta: {
      title: 'Approved Institutions of ATC',
      description: 'Approved Institutions of Advanced Training Courses'
    },
    component: () => import('@/views/Sailor/Institution')
  },
  {
    path: '/dlya-moryakiv/rekviziti-dlya-splati-poslug-inspektsii',
    name: 'for-sailor-payment-details-services',
    meta: {
      title: 'Bank details and payment methods for services - ITCS',
      description: "Details of payment for the services of the Inspectorate for confirmation of qualification and registration of the seafarer's record book"
    },
    component: () => import('@/views/Sailor/PaymentDetails')
  },
  {
    path: '/dlya-gromady/grafic-of-visits',
    name: 'for-the-public',
    meta: {
      title: 'Schedule',
      description: 'The Inspectorate for the Training and Certification of Seafarers has postponed citizens appointment for the quarantine period which established by the Cabinet of Ministers of Ukraine in order to prevent the spread of acute respiratory disease COVID-19 caused by the SARS-CoV-2 coronavirus in Ukraine'
    },
    component: () => import('@/views/ForThePublic')
  },
  {
    path: '/verification',
    name: 'verification',
    meta: {
      title: 'Verification',
      description: 'Verification'
    },
    component: () => import('@/views/Verification')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {
      title: 'Contacts and phone number of hot information Inspections',
      description: 'Contacts and telephone number of the hotline of regional branches of Inspections on training and certification of seafarers of Ukraine in Odessa, Kiev, Kherson, Izmail, Kharkiv'
    },
    component: () => import('@/views/Contacts')
  },
  {
    meta: {
      title: "SQC member's office",
      description: "SQC member's office"
    },
    path: '/dkk/cabinet',
    name: 'sqc-cabinet',
    
    component: () => import('@/views/SQC/CabinetSQC')
  },
  {
    path: '/dkk/perelik-dokumentiv-na-dkk',
    name: 'sqc-list-of-documents',
    meta: {
      title: 'List of documents on the SQC',
      description: 'List of all necessary documents for consideration by the State Qualification Commission (SCC) for pilots, radio specialists, mechanics and engineers, specialists of the port, service and support and technical fleet'
    },
    component: () => import('@/views/SQC/ListOfDocuments')
  },
  {
    path: '/dkk/adresi-dkk',
    name: 'sqc-address-branch-offices',
    meta: {
      title: 'SQC address',
      description: 'SQC address'
    },
    component: () => import('@/views/SQC/AddressBranchOffice')
  },
  {
    path: '/dkk/testing',
    name: 'sqc-ces-statistycs',
    meta: {
      title: 'CES 6.0',
      description: 'CES 6.0 statistics'
    },
    component: () => import('@/views/SQC/StatistycCES')
  },
  {
    path: '/dkk/testing-exp',
    name: 'sqc-expirience-statistycs',
    meta: {
      title: 'Proof of experience',
      description: "Statistics 'Proof of experience'"
    },
    component: () => import('@/views/SQC/ExperienceStatistyc')
  },
  {
    path: '/dkk/shedule',
    name: 'sqc-shedule',
    meta: {
      title: 'Work schedule SQC',
      description: 'Work schedule SQC'
    },
    component: () => import('@/views/SQC/Shedule')
  },
  {
    path: '/news',
    query: { page: 1 },
    name: 'news',
    meta: {
      title: 'News',
      description: 'News'
    },
    component: () => import('@/views/News/News')
  },
  {
    path: '/news/detail/:newsId',
    query: { page: 1 },
    name: 'news-details',
    meta: {
      title: 'News',
      description: 'News'
    },
    component: () => import('@/views/News/Detail')
  },
  {
    path: '/server-error',
    name: 'server-error',
    meta: {
      title: 'server-error',
      description: '500'
    },
    component: () => import('@/views/Error')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  document.title = i18n.t(to?.meta?.title) || ''
  const descEl = document.querySelector('meta[name="description"]')
  descEl?.setAttribute('content', i18n.t(to?.meta?.description))
  next()
})

export default router
